import React from 'react';
import Kagaz from "./assets/images/Kagaz.png"

const Modal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed top-4 left-4 w-[20vw] h-[60vh] bg-transparent rounded-lg p-6 z-50 flex flex-col justify-start gap-3 items-center"  style={{ backgroundImage: `url(${Kagaz})` }}>
      
      <div className="text-lg  font-bold ml-6 mt-5">नियम 1: पश्य/print- <span className='bg-[#fff698] px-1 rounded-2'>print</span> शब्द में print हमेशा स्माल लेटर्स में लिखे जाते हैं।</div>
      <div className="text-lg  font-bold ml-6">नियम 2: पश्य(______) - आप जब भी पश्य/print शब्द लिखे तो ये दो ब्रैकेट <span className='bg-[#fff698] px-1 rounded-2'>( )</span> का इस्तेमाल करें।</div>
      <div className="text-lg  font-bold ml-6">नियम 3: पश्य(“हेलो”) -  पश्य/print स्टेटमेंट के अंदर जब भी आप कोई सन्देश लिखे तो इस उद्धरण चिह्न <span className='bg-[#fff698] px-1 rounded-2'>“ “</span> (quotation mark) का ज़रूर उपयोग करे।  हर एक सन्देश इस उद्धरण चिह्न (quotation mark) के अंदर ही लिखें। </div>

      <button
        onClick={onClose}
        className=" px-4 py-2 hover:text-gray-800 bg-red-500 text-xl rounded-xl font-semibold text-white"
      >
        Back
      </button>

    </div>

    
  );
};

export default Modal;
  