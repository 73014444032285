import React, { useState, useRef, useEffect } from "react";
import { Nav, NavDropdown } from "react-bootstrap"; 
import LanguageSelector from "./LanguageSelector";
import logo from "./logo.svg";
import RunButton from "./RunButton.svg";
import AskLali from "./AskLali.svg";
import columnsgap from './columnsgap.svg';
import "./codeEditorPhone.css";
import { CODE_SNIPPETS } from "../utils/constants";
import EditorFooter from "./EditorFooter";
import { Editor, useMonaco } from "@monaco-editor/react";
import pauseIcon from "./pausesvg.svg";
import "react-simple-keyboard/build/css/index.css";
import sendbutton from "./sendButton.svg";
import microphone from "./microphone.svg";
import crossIcon from "./crossIcon.svg";
import backcode from "./backcode.svg";
import backfont from "./backfont.svg";
import "./CircularCheckbox.css";

const CodeEditorPhone = () => {
    const monaco = useMonaco();
    const [value, setValue] = useState("");
    const [language, setLanguage] = useState("javascript");
    const [fontSize, setFontSize] = useState(14); 
    const editorRef = useRef(null);
    const [mode, setMode] = useState(true);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [askLaliDropdownVisible, setAskLaliDropdownVisible] = useState(false);
    const dropdownRef = useRef(null);
    const columnsgapRef = useRef(null);
    const askLaliRef = useRef(null);
    const [isRecording, setIsRecording] = useState(false);
    const [recording, setRecording] = useState(null);
    const [recordings, setRecordings] = useState([]);
    const [apiResponses, setApiResponses] = useState([]);
    const [outputReceived, setOutputReceived] = useState(false);
    const [audioUrl, setAudioUrl] = useState('');
    const bottomRef = useRef(null);
    const [askLaliOutput, setAskLaliOutput] = useState("");
    const [fileDropdownVisible, setFileDropdownVisible] = useState(false);
    const fileDropdownRef = useRef(null);

    const toggleFileDropdown = () => {
        setFileDropdownVisible(!fileDropdownVisible);
        if (dropdownVisible) setDropdownVisible(false); 
    };
    
    const closeDropdown = () => setDropdownVisible(false);
    const closeAskLaliDropdown = () => setAskLaliDropdownVisible(false);

    const toggleDropdownAskLali = () => {
        setAskLaliDropdownVisible(!askLaliDropdownVisible);
    };

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
        if (askLaliDropdownVisible) {
            setAskLaliDropdownVisible(false);
        }
    };

    const handleClickOutside = (event) => {
        if (
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target) &&
            columnsgapRef.current &&
            !columnsgapRef.current.contains(event.target)
        ) {
            setDropdownVisible(false);
        }
    
         if (fileDropdownRef.current && !fileDropdownRef.current.contains(event.target)) {
            setFileDropdownVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownVisible, fileDropdownVisible]);

    const startRecording = () => {
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            setIsRecording(true);
            navigator.mediaDevices
                .getUserMedia({ audio: true })
                .then((stream) => {
                const mediaRecorder = new MediaRecorder(stream);
                setRecording(mediaRecorder);

                let chunks = [];
                mediaRecorder.ondataavailable = (e) => chunks.push(e.data);

                mediaRecorder.onstop = () => {
                    const blob = new Blob(chunks, { type: 'audio/wav' });
                    const url = URL.createObjectURL(blob);

                    setRecordings((prevRecordings) => [
                    ...prevRecordings,
                    { blob, url },
                    ]);

                    setAudioUrl(url);
                    setIsRecording(false);
                };

                mediaRecorder.start();
                })
                .catch((err) => {
                console.error('Recording failed:', err);
                setIsRecording(false);
                });
        } else {
            console.error('MediaDevices not supported.');
        }
    };

    const stopRecording = () => {
        if (recording) {
            recording.stop();
        }
    };

    const sendRecordingToBackend = async (blob) => {
        const formData = new FormData();
        formData.append('file', blob, 'recording.wav');
        formData.append('code', 'cvds df df');
        formData.append('cursor po', 'cddfdffd');

        try {
            const response = await fetch(
                'https://कोड.com/api/v1/lali_speech_to_text/',
                {
                method: 'POST',
                body: formData,
                }
            );

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Network response was not ok: ${errorText}`);
            }

            const result = await response.json();
            console.log('Backend Response:', result);

            if (result.predicted_text && result.code) {
                setApiResponses((prevResponses) => [
                ...prevResponses,
                {
                    id: Date.now(),
                    predictedText: result.predicted_text,
                    code: result.code,
                },
                ]);
                setOutputReceived(true);
            } else {
                console.error('Response does not contain the expected fields');
                setAskLaliOutput('Unexpected response format');
                setOutputReceived(true);
            }
        } catch (error) {
            console.error('Error sending recording to backend:', error);
            setAskLaliOutput('Error occurred while sending recording');
            setOutputReceived(true);
        }
    };

    const handleSend = () => {
        if (!isRecording && recordings.length > 0) {
            const latestRecording = recordings[recordings.length - 1];
            sendRecordingToBackend(latestRecording.blob);
        }
        if (bottomRef.current) {
            bottomRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const onSelect = (language) => {
        setLanguage(language);
        setValue(CODE_SNIPPETS[language] || '');
    };

    const handleEditorChange = (newValue) => {
        setValue(newValue);
    };

    const themeColor = () => {
        setMode(!mode);
        if (monaco) {
            monaco.editor.setTheme("customDarkTheme");
        }
    };

    return (
        <>
            <nav className="navbar">
                <div className="navbar-logo">
                    <img
                        src={logo}
                        alt="Brand Logo"
                        style={{ width: '30px', height: 'auto' }}
                    />
                </div>
                <div className="navbar-end">
                    <Nav className="me-auto">
                        <NavDropdown
                            title={<span style={{ color: 'white', fontSize: '20px', paddingRight: '5px' }}>File</span>}
                            id="basic-nav-dropdown" onClick={toggleFileDropdown} ref={fileDropdownRef}
                        >
                            <NavDropdown.Item className="navbar-dropdown-item" style={{ color: 'white'}}>Save</NavDropdown.Item>
                            <div
                                style={{
                                    height: '0.5px',
                                    backgroundColor: 'grey',
                                    marginTop: '3px',
                                    marginBottom: '3px',
                                    marginLeft: '7px',
                                    marginRight: '7px',
                                }}
                            ></div>
                            <NavDropdown.Item className="navbar-dropdown-item" style={{ color: 'white' }}>Download</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                    <div className="language-selector-container">
                        <LanguageSelector language={language} onSelect={onSelect} />
                    </div>
                </div>
            </nav>
            <div className="editor-container">
                <Editor
                    width="100%"
                    height="100%"
                    theme="themeColor"
                    fontSize={fontSize}
                    language={language}
                    value={value}
                    onChange={handleEditorChange}
                    options={{
                        scrollBeyondLastLine: false,
                        minimap: { enabled: false },
                        fontSize: fontSize, 
                    }}
                    onMount={(editor) => (editorRef.current = editor)}
                />
                <div className="svg-container">
                    <div className="d-flex align-items-center" style={{ borderRadius: '20%' }}>
                        <img
                            src={AskLali}
                            alt="AskLali"
                            onClick={toggleDropdownAskLali}
                            className="svg-icon-asklali"
                            ref={askLaliRef}
                        />
                        {askLaliDropdownVisible && (
                            <div className={`asklali-dropdown ${askLaliDropdownVisible ? 'visible' : ''}`}>
                                <button className="asklali-close-button" onClick={closeAskLaliDropdown}>
                                    <img src={crossIcon} alt="Close" />
                                </button>
                                <div className="asklali-content-container">
                                    <div className="asklali-center-content">
                                        {!outputReceived && (
                                            <div className="asklali-center-content">
                                                <img
                                                    src={backcode}
                                                    alt="Back Code"
                                                    className="backcode-img"
                                                />
                                                <img
                                                    src={backfont}
                                                    alt="Back Font"
                                                    className="backfont-img"
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <div className="asklali-input-container">
                                        <input
                                            type="text"
                                            placeholder="Type your question..."
                                            className="asklali-input"
                                        />
                                         <div className="svg-icons">
                                            <img src={microphone} alt="Microphone" />
                                            <img src={sendbutton} alt="Send" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <img src={RunButton} alt="Run Button" className="svg-icon-run" />
                    <div className="columnsgap" ref={columnsgapRef} onClick={toggleDropdown}>
                        <img
                            src={columnsgap}
                            alt="Dropdown"
                            className="svg-icon-columnsgap"
                        />
                        {dropdownVisible && (
                            <div className={`dropdown-menu ${dropdownVisible ? 'visible' : ''}`} ref={dropdownRef}>
                                <div className="dropdown-content">
                                    <button className="dropdown-item" onClick={() => setFontSize(fontSize + 2)}>
                                        Font Size
                                    </button>
                                    <button className="dropdown-item" onClick={() => setFontSize(fontSize - 2)}>
                                        Transliteration
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <EditorFooter langpass={language} />
        </>
    );
};

export default CodeEditorPhone;

