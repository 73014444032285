import React, { useState } from 'react';
import logo from "./appLogo.png";
import CheckTick from './assets/images/check_payment.png';

const Payment = () => {
  const [selectedPlan, setSelectedPlan] = useState('1 month');
  const [isComparisonMode, setIsComparisonMode] = useState(false); // State for comparison mode

  const handlePlanChange = (plan) => {
    setSelectedPlan(plan);
  };

  const toggleComparisonMode = () => {
    setIsComparisonMode(!isComparisonMode);
  };

  const pricing = {
    '1 month': {
      Free: 0,
      Basic: 100,
      Silver: 1500,
      Gold: 2000
    },
    '3 months': {
      Free: 0,
      Basic: 300,
      Silver: 2500,
      Gold: 4000
    },
    '6 months': {
      Free: 0,
      Basic: 500,
      Silver: 4000,
      Gold: 6000
    },
    '12 months': {
      Free: 0,
      Basic: 1000,
      Silver: 7000,
      Gold: 10000
    }
  };

  // Define feature sets for each plan
  const features = {
    Free: ['Code Editor', 'Code Game', 'Coding competition'],
    Basic: ['Recorded Lectures', 'Gdevelop game engine', 'Certificate', '1GB Cloud Storage'],
    Silver: ['Vanrakas Game Engine', 'Advance 2D & 3D games', '5GB Storage', 'World Class game instructor'],
    Gold: ['Live Portfolio', '24X7 Team Support', '10GB Storage', 'Get 5 assets free', 'Build Resume']
  };

  // Extended feature sets for comparison mode
  const extendedFeatures = {
    Free: ['Code Editor', 'Code Game', 'Coding competition'],
    Basic: [
      'Recorded Lectures', 'Gdevelop game engine', 'Certificate', '1GB Cloud Storage', 'Access to community forums',
      'Monthly Webinars', 'Exclusive Discounts', 'Priority Email Support'
    ],
    Silver: [
      'Vanrakas Game Engine', 'Advance 2D & 3D games', '5GB Storage', 'World Class game instructor', 'Weekly Challenges',
      'Access to beta features', 'Customizable UI', 'Team Collaboration Tools', 'Extended Support Hours',
      'Dedicated Account Manager', 'Free E-Books'
    ],
    Gold: [
      'Live Portfolio', '24X7 Team Support', '10GB Storage', 'Get 5 assets free', 'Build Resume', 'One-on-One Mentorship',
      'Exclusive Access to New Content', 'Advanced Reporting Tools', 'Unlimited Team Members', 'Custom Domains',
      'SEO Optimization Tools', 'Advanced Security Features', 'Early Access to Updates', 'Priority Phone Support',
      'Free Conference Tickets'
    ]
  };

  return (
    <div className=' w-[100%] flex'>
      <div className=' w-[100%] mx-32 my-8 flex justify-between flex-col items-center'>

        {/* Top section */}
        <div className='w-[100%] flex flex-col justify-evenly gap-2 items-center'>
          <div className='w-[40%] flex items-center justify-center gap-2 mr-10'>
            <img src={logo} alt="" className='h-11 w-11 rounded-full border-1 border-black'/>
            <p className='text-2xl my-2 font-bold'>Shuniyavigyan</p>
          </div>
          <h2 className='w-[15%]  flex items-center justify-center border-b-2'>Pricing Plan</h2>
          <div className='w-[40%] flex justify-center items-center text-xl'>
            Select your preference and start your journey with us.
          </div>
          <div className='w-[60%] border-5 rounded-2xl gap-5 flex justify-evenly items-center px-2 py-1'>
            {['1 month', '3 months', '6 months', '12 months'].map(plan => (
              <div 
                key={plan}
                className={`w-[24%] ${selectedPlan === plan ? 'bg-[#7E22CE] text-white' : 'hover:bg-[#7E22CE17] hover:text-black'} border-1 py-2 border-[#7E22CE] cursor-pointer rounded-xl flex justify-center items-center`}
                onClick={() => handlePlanChange(plan)}
              >
                <p className='my-2 text-xl font-semibold'>{plan}</p>
              </div>
            ))}
          </div>
        </div>

        {/* Mid section */}
        <div className='w-[100%] flex justify-between items-start my-8'>
          {Object.keys(pricing[selectedPlan]).map(planType => (
            <div key={planType} className='w-[22%] border-[1px] border-t-[10px] border-t-[#7E22CE] relative hover:bg-[#7E22CE17] border-[#B670F3] rounded-2xl flex flex-col items-center'>
              <h4 className='pt-2'>{planType}</h4>
              <h1>₹{pricing[selectedPlan][planType]}</h1>

              {/* Show features based on comparison mode */}
              <div className='ml-8 w-[70%] flex flex-col justify-start items-center'>
                {(isComparisonMode ? extendedFeatures[planType] || [] : features[planType] || []).map((feature, index) => (
                  <div key={index} className='flex justify-start items-center gap-4 w-[100%]'>
                    <img src={CheckTick} alt="" />
                    <p className='my-2'>{feature}</p>
                  </div>
                ))}
              </div>

              <button className='border-1 text-xl font-bold my-4 border-black rounded-xl py-2 px-12 hover:bg-black hover:text-white duration-300'>Get Started</button>
            </div>
          ))}
        </div>

        {/* Bottom button */}
        <button 
          className='px-5 py-3 text-2xl font-bold bg-[#7E22CE] text-white rounded-xl'
          onClick={toggleComparisonMode}
        >
          {isComparisonMode ? 'Hide Comparison' : 'Compare Features'}
        </button>
      </div>
    </div>
  )
}

export default Payment;
