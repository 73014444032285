import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import loginBg from '../images/loginBg.jpg';
import Applogo from './assets/images/appLogo.png';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import SuccessModal from './RegisteredSuccessfully';
import { useAuth } from './AuthContext';
import User from "./assets/images/User_login_input.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import Lock from "./assets/images/lock_register.png";
import Google from '../images/Google.png';
import Cancel from './assets/images/cancel_register.png'
import { useTranslation } from 'react-i18next';

const ModelRegister = ({ modelRegister, handleClose,openLogin }) => {
  // React hooks should always be called at the top of the component
  const navigate = useNavigate();
  const { login } = useAuth();
  const [showModal, setShowModal] = useState(false); 
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation();
  const { register, handleSubmit, watch, formState: { errors }, reset } = useForm();

  // Watching the password input field for validation
  const password = watch("password");

  useEffect(() => {
    if (modelRegister) {
      reset(); // Clear input data
    }
  }, [modelRegister, reset]);

  // Return null if modelRegister is false
  if (!modelRegister) return null;

  const onSubmit = async (data) => {
    console.log("Submitting data:", data);
    
    const formData = new FormData();
    formData.append('username', data.username);
    formData.append('password', data.password);
  
    console.log("FormData content:", Array.from(formData.entries()));
    
    const apiUrl = 'https://xn--11by0j.com/api/v1/register_or_login/';
    
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          // No 'Content-Type' header needed when using FormData
        },
        body: formData,
      });
  
      console.log("Response status:", response.status);
      console.log("Response headers:", response.headers);
  
      const responseData = await response.json();
  
      if (response.ok) {
        console.log('Success response data:', responseData);
        toast.success('Registration successful!');
        localStorage.setItem('user', JSON.stringify(responseData.user_id));
        setShowModal(true);
        setTimeout(() => {
          setShowModal(false);
          login();
          handleClose();
          navigate('/');
        }, 2000);
      } else {
        console.error('Response error data:', responseData);
        toast.error(`Registration failed: ${responseData.message || 'Please try again.'}`);
      }
    } catch (error) {
      console.error('Error during fetch:', error);
      toast.error('An error occurred. Please try again.');
    }
  };

  return (
    <div className='relative'>
      <div className="absolute top-72 inset-0 bg-opacity-50  flex justify-end items-center z-20">
      <form
          onSubmit={handleSubmit(onSubmit)}
          className="registration-form p-6 w-[400px] bg-white rounded-xl shadow-lg flex flex-col items-center justify-evenly "
        >
         <div className='relative h-full w-full'> <img src={Cancel} onClick={handleClose} alt="" className='absolute hover:cursor-pointer top-0 right-0 '/></div>
          <h2 className="text-2xl font-bold text-purple-700 mt-6">{t('register.title')}</h2>
          <div className='mt-6 flex flex-col items-center text-black'><span>{t('register.title1')} </span><span className='ml-4'>{t('register.title2')}</span></div>

          {/* Input Fields */}
          <div className="w-full flex flex-col">
            <div className={`flex items-center mt-6 border-2 rounded-lg px-3 py-2 transition-all duration-300 ease-in-out 
              ${errors.username ? 'border-red-500' : 'border-[#564787]'}`}>
              <img src={User} alt="" className="w-5 h-5"/>
              <input
                type="text"
                placeholder={t('register.name')}
                {...register("username", {
                  required: "Username is required",
                })}
                className="w-full ml-4 text-gray-700 focus:outline-none bg-white"
              />
              {errors.username && (
                <FontAwesomeIcon icon={faExclamationCircle} className="text-red-500 ml-2" />
              )}
            </div>
            {errors.username && (
              <span className="text-xs text-red-500 transition-opacity duration-300 mt-1 ease-in">
                {errors.username.message}
              </span>
            )}

            <div className={`flex items-center mt-6 border-2 rounded-lg px-3 py-2 transition-all duration-300 ease-in-out 
              ${errors.password ? 'border-red-500' : 'border-[#564787]'}`}>
              <img src={Lock} alt="" className="w-5 h-5"/>
              <input
                type={showPassword ? "text" : "password"}
                placeholder={t('register.password')}
                {...register("password", {
                  required: "Password is required",
                })}
                className="w-full ml-4 text-gray-700 focus:outline-none bg-white"
              />
              {errors.password && (
                <FontAwesomeIcon icon={faExclamationCircle} className="text-red-500 ml-2" />
              )}
            </div>
            {errors.password && (
              <span className="text-xs text-red-500 transition-opacity duration-300 mt-1 ease-in">
                {errors.password.message}
              </span>
            )}

            <div className={`flex items-center border-2 mt-6 rounded-lg px-3 py-2 transition-all duration-300 ease-in-out 
              ${errors.confirmPassword ? 'border-red-500' : 'border-[#564787]'}`}>
              <img src={Lock} alt="" className="w-5 h-5"/>
              <input
                type={showPassword ? "text" : "password"}
                placeholder={t('register.confirm')}
                {...register("confirmPassword", {
                  required: "Confirm Password is required",
                  validate: value => value === password || "Passwords do not match",
                })}
                className="w-full ml-4 text-gray-700 focus:outline-none bg-white"
              />
              {errors.confirmPassword && (
                <FontAwesomeIcon icon={faExclamationCircle} className="text-red-500 ml-2" />
              )}
            </div>
            {errors.confirmPassword && (
              <span className="text-xs text-red-500 transition-opacity mt-1 duration-300 ease-in">
                {errors.confirmPassword.message}
              </span>
            )}
          </div>

          {/* Accept Terms Checkbox */}
          <div className="w-full mt-4">
            <label className="text-gray-700 text-sm font-semibold">
              <input
                type="checkbox"
                {...register("acceptTerms", { required: "You must accept the terms and conditions" })}
                className={`mr-2 bg-white ${errors.acceptTerms ? 'border-red-500' : 'border-gray-300'}`}
              />
              {t('register.accept')}
            </label>
          </div>
          {errors.acceptTerms && (
            <span className="text-xs text-red-500 mt-1 transition-opacity duration-300 ease-in">
              {errors.acceptTerms.message}
            </span>
          )}

          {/* Submit Button */}
          <button
            type="submit"
            className="mt-6 w-full py-2 text-xl bg-purple-600 text-white rounded-xl font-semibold hover:bg-purple-900 transition-colors duration-300"
          >
            {t('register.submit')}
          </button>

          {/* Redirect to Login */}
          <div className="mt-3 font-semibold text-gray-700">
            {t('register.have')}{" "}
            <button 
              type="button"
              onClick={openLogin} // Use openLogin to open login modal
              className="text-[#7E22CE] hover:underline font-bold"
            >
              {t('register.login')}
            </button>
          </div>
          {/* <button 
          onClick={handleClose} 
          className="bg-purple-600 text-white px-4 py-2 rounded-lg"
        >
          OK
        </button> */}
        </form>
    </div>
    <SuccessModal show={showModal} onClose={() => setShowModal(false)} />
    </div>
  );
}

export default ModelRegister;