import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import loginBg from '../images/loginBg.jpg';
import Applogo from './assets/images/appLogo.png';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import SuccessModal from './RegisteredSuccessfully';
import { useAuth } from './AuthContext';
import User from "./assets/images/User_login_input.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import Lock from "./assets/images/lock_register.png";
import Google from '../images/Google.png';
import { useTranslation } from 'react-i18next';

const Register = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const [showModal, setShowModal] = useState(false); 
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    console.log("Submitting data:", data);
    
    const formData = new FormData();
    formData.append('username', data.username);
    formData.append('password', data.password);
  
    console.log("FormData content:", Array.from(formData.entries()));
    
    const apiUrl = 'https://xn--11by0j.com/api/v1/register_or_login/';
    
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          // No 'Content-Type' header needed when using FormData
        },
        body: formData,
      });
  
      console.log("Response status:", response.status); // Log the response status
      console.log("Response headers:", response.headers); // Log response headers
  
      const responseData = await response.json(); // Parse response as JSON
  
      if (response.ok) {
        console.log('Success response data:', responseData);
        toast.success('Registration successful!');
        localStorage.setItem('user', JSON.stringify(responseData.user_id));
        setShowModal(true); // Show the modal
        setTimeout(() => {
          setShowModal(false); // Hide the modal after 2 seconds
          login();
          navigate('/'); // Redirect to homepage after successful registration
        }, 2000);
      } else {
        console.error('Response error data:', responseData);
        toast.error(`Registration failed: ${responseData.message || 'Please try again.'}`);
      }
    } catch (error) {
      console.error('Error during fetch:', error);
      toast.error('An error occurred. Please try again.');
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Watch for the password and confirm password fields to validate matching
  const password = watch("password");

  return (
    <div
      className="text-center text-lg-start loginBody flex justify-center items-center"
      style={{
        backgroundImage: `url(${loginBg})`,
        height: '100vh',
        width: '100%',
        backgroundSize: 'cover',
      }}
    >
      <div className="w-[90%] h-[90vh]">
        <a href="/" className='text-white no-underline h-full w-full'>
        <div className="w-full h-[9%] mb-2 ml-12 flex justify-start items-center gap-4">
          <img src={Applogo} alt="App Logo" className="h-full" />
          <h1 className="text-white text-3xl mt-1">{t('register.logo')}</h1>
        </div>
        </a>

        <div className="w-full h-[87%] flex justify-start">
          <div className="w-1/2 h-[100%] flex justify-center items-center">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="registration-form p-6 w-full max-w-lg bg-white rounded-3xl shadow-lg flex flex-col items-center justify-evenly sm:p-8 lg:p-10"
          >
            <h2 className="text-2xl font-bold text-purple-700 ">{t('register.title')}</h2>

            {/* Input Fields */}
            <div className="w-full flex flex-col">
              <div className={`flex items-center mt-7 border-2 rounded-lg px-3 py-2 transition-all duration-300 ease-in-out 
                ${errors.username ? 'border-red-500' : 'border-[#564787]'}`}>
                <img src={User} alt="" className="w-5 h-5"/>
                <input
                  type="text"
                  placeholder={t('register.name')}
                  {...register("username", {
                    required: "Username is required",
                  })}
                  className="w-full ml-4 text-gray-700 focus:outline-none bg-white"
                />
                {errors.username && (
                  <FontAwesomeIcon icon={faExclamationCircle} className="text-red-500 ml-2" />
                )}
              </div>
              {errors.username && (
                <span className="text-xs text-red-500 transition-opacity duration-300 mt-1 ease-in">
                  {errors.username.message}
                </span>
              )}

              <div className={`flex items-center mt-7 border-2 rounded-lg px-3 py-2 transition-all duration-300 ease-in-out 
                ${errors.password ? 'border-red-500' : 'border-[#564787]'}`}>
                <img src={Lock} alt="" className="w-5 h-5"/>
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder={t('register.password')}
                  {...register("password", {
                    required: "Password is required",
                    // pattern: {
                    //   value: /^.{6}$/,
                    //   message: "Password must be exactly 6 characters",
                    // },
                  })}
                  className="w-full ml-4 text-gray-700 focus:outline-none bg-white"
                />
                {errors.password && (
                  <FontAwesomeIcon icon={faExclamationCircle} className="text-red-500 ml-2" />
                )}
              </div>
              {errors.password && (
                <span className="text-xs text-red-500 transition-opacity duration-300 mt-1 ease-in">
                {errors.password.message}
                </span>
              )}

              <div className={`flex items-center border-2 mt-7 rounded-lg px-3 py-2 transition-all duration-300 ease-in-out 
                ${errors.confirmPassword ? 'border-red-500' : 'border-[#564787]'}`}>
                <img src={Lock} alt="" className="w-5 h-5"/>
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder={t('register.confirm')}
                  {...register("confirmPassword", {
                    required: "Confirm Password is required",
                    validate: value => value === password || "Passwords do not match",
                  })}
                  className="w-full ml-4 text-gray-700 focus:outline-none bg-white"
                />
                {errors.confirmPassword && (
                  <FontAwesomeIcon icon={faExclamationCircle} className="text-red-500 ml-2" />
                )}
              </div>
              {errors.confirmPassword && (
                <span className="text-xs text-red-500 transition-opacity mt-1 duration-300 ease-in">
                  {errors.confirmPassword.message}
                </span>
              )}
            </div>

            {/* Accept Terms Checkbox */}
            <div className="w-full mt-4">
              <label className="text-gray-700 text-sm font-semibold">
                <input
                  type="checkbox"
                  {...register("acceptTerms", { required: "You must accept the terms and conditions" })}
                  className={`mr-2 bg-white ${errors.acceptTerms ? 'border-red-500' : 'border-gray-300'}`}
                />
                {t('register.accept')}
              </label>
            </div>
            {errors.acceptTerms && (
              <span className="text-xs text-red-500 mt-1 transition-opacity duration-300 ease-in">
               {errors.acceptTerms.message}
              </span>
            )}

            {/* Submit Button */}
            <button
              type="submit"
              className="mt-6 w-full py-3 bg-purple-600 text-white rounded-xl font-semibold hover:bg-purple-700 transition-colors duration-300"
            >
              {t('register.submit')}
            </button>

            {/* Redirect to Login */}
            <div className="mt-4 text-lg font-semibold text-gray-700 ">
            {t('register.have')}{" "}
              <Link to="/login" className="text-purple-500 hover:underline font-bold">
              {t('register.login')}
              </Link>
            </div>
          </form>


          </div>

          <div className="w-1/2 h-[100%] flex flex-col gap-4 justify-center items-center">
            <h1 className="text-7xl text-white ">{t('register.namaste')}</h1>
            <div className='flex flex-col justify-center items-center'>
            <p className="text-3xl text-white">
            {t('register.title1')}
            </p>
            <p className='text-3xl text-white'>{t('register.title2')}</p>
            </div>
          </div>
        </div>
      </div>
      <SuccessModal show={showModal} onClose={() => setShowModal(false)} />
    </div>
  );
};

export default Register;
