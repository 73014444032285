import React from 'react'
import { useParams } from 'react-router-dom';

const Games_game = () => {
  const { gameUrl } = useParams(); // Get the game URL from the route
  const decodedUrl = decodeURIComponent(gameUrl); // Decode the URL

  return (
    <div>
      {/* <h1 className="text-center text-2xl mb-4">Game is Loading...</h1> */}
      <iframe
        src={decodedUrl}
        title="Game"
        style={{
          width: '100%',
          height: '100vh',
          border: 'none',
        }}
        allowFullScreen
      />
    </div>
  );
};

export default Games_game
