import React, { useState } from 'react';

const Transaction = () => {
  const [isMonthDropdownOpen, setIsMonthDropdownOpen] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [isStatusDropdownOpen, setIsStatusDropdownOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('');

  const toggleMonthDropdown = () => {
    setIsMonthDropdownOpen(!isMonthDropdownOpen);
  };

  const toggleStatusDropdown = () => {
    setIsStatusDropdownOpen(!isStatusDropdownOpen);
  };

  const selectMonth = (month) => {
    setSelectedMonth(month);
    setIsMonthDropdownOpen(false); // Close dropdown after selection
  };

  const selectStatus = (status) => {
    setSelectedStatus(status);
    setIsStatusDropdownOpen(false); // Close dropdown after selection
  };

  return (
    <div className='w-[90%] h-full relative'>
      <div className='w-full h-full absolute  top-12 left-10'>
        <h1 className='text-7xl border-b-4 pb-4'>Transaction History</h1>

        <div className='flex justify-end items-center gap-16 px-20 my-5'>
          {/* Month Dropdown */}
          <div className='relative bg-purple-100 rounded-xl'>
            <div onClick={toggleMonthDropdown} className='cursor-pointer text-xl font-medium px-4 py-2'>
              Month {selectedMonth && `: ${selectedMonth}`}
            </div>
            {isMonthDropdownOpen && (
              <div className='absolute bg-white border shadow-lg mt-1 w-full z-10'>
                {['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'].map((month) => (
                  <div 
                    key={month} 
                    onClick={() => selectMonth(month)} 
                    className='p-2 hover:bg-gray-200 cursor-pointer'
                  >
                    {month}
                  </div>
                ))}
              </div>
            )}
          </div>

          {/* Status Dropdown */}
          <div className='relative bg-purple-100 rounded-xl'>
            <div onClick={toggleStatusDropdown} className='cursor-pointer text-xl font-medium px-4 py-2'>
              Status {selectedStatus && `: ${selectedStatus}`}
            </div>
            {isStatusDropdownOpen && (
              <div className='absolute bg-white border shadow-lg mt-1 w-full z-10'>
                {['Completed', 'Failed'].map((status) => (
                  <div 
                    key={status} 
                    onClick={() => selectStatus(status)} 
                    className='p-2 hover:bg-gray-200 cursor-pointer'
                  >
                    {status}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        {/* <div className='w-full h-full'>
      <table className='w-full border-2 border-gray-300'>
        <thead>
          <tr className='border-b-2 text-2xl font-bold text-gray-500'>
            <th className='py-2'>Type</th>
            <th className='py-2'>Date</th>
            <th className='py-2'>Amount</th>
            <th className='py-2'>Received By</th>
            <th className='py-2'>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr className='border-b-2'>
            <td className='text-2xl my-3'>Send</td>
            <td className='flex flex-col'>
              <p className='font-bold mt-3'>25/3/2023</p>
              <p>10:10 AM</p>
            </td>
            <td className='text-2xl my-3'>{"\u20B9"}500</td>
            <td className='text-2xl my-3'>SuniyaVigyan</td>
            <td className='text-2xl my-3'><span className='border-3 border-green-400 px-3 py-2 rounded-full'>Completed</span></td>
          </tr>
    
          <tr className='border-b-2'>
            <td className='text-2xl my-3'>Receive</td>
            <td className='flex flex-col'>
              <p className='font-bold mt-3'>26/3/2023</p>
              <p>11:00 AM</p>
            </td>
            <td className='text-2xl my-3'>{"\u20B9"}1000</td>
            <td className='text-2xl my-3'>John Doe</td>
            <td className='text-2xl my-3'><span className='border-3 border-red-400 px-3 py-2 rounded-full'> failed</span></td>
          </tr>
          
        </tbody>
      </table>
    </div> */}

<div className='w-full h-full mt-8'>
  <table className='w-full '>
    <thead>
      <tr className='border-b-2 text-2xl font-bold text-gray-500'>
        <th className='py-2 text-center'>Type</th>
        <th className='py-2 text-center'>Date</th>
        <th className='py-2 text-center'>Amount</th>
        <th className='py-2 text-center'>Received By</th>
        <th className='py-2 text-center'>Status</th>
      </tr>
    </thead>
    <tbody>
      <tr className='border-b-2'>
        <td className='text-2xl my-3 text-center'>Send</td>
        <td className='flex flex-col items-center'>
          <p className='font-bold mt-3'>25/3/2023</p>
          <p>10:10 AM</p>
        </td>
        <td className='text-2xl my-3 text-center'>{"\u20B9"}500</td>
        <td className='text-2xl my-3 text-center'>SuniyaVigyan</td>
        <td className='text-2xl my-3 text-center'>
          <span className='border-3 border-green-400 px-3 py-2 text-green-400 rounded-full'>Completed</span>
        </td>
      </tr>
      {/* Repeat this <tr> block for more entries */}
      <tr className='border-b-2'>
        <td className='text-2xl my-3 text-center'>Receive</td>
        <td className='flex flex-col items-center'>
          <p className='font-bold mt-3'>26/3/2023</p>
          <p>11:00 AM</p>
        </td>
        <td className='text-2xl my-3 text-center'>{"\u20B9"}1000</td>
        <td className='text-2xl my-3 text-center'>John Doe</td>
        <td className='text-2xl my-3 text-center'>
          <span className='border-3 border-red-400 px-3 text-red-400 py-2 rounded-full'>Failed</span>
        </td>
      </tr>
      {/* Add more rows as needed */}
    </tbody>
  </table>
</div>

      </div>
    </div>
  );
};

export default Transaction;