import React from "react";
import Footer from "./Footer";
import location from "./location.svg";
import phone from "./phone.svg";
import gmail from "./mail (2).svg";
import youtube from "./contactYoutube.svg";
import facebook from "./contactFacebook.svg";
import instagram from "./contactInsta.svg"
import { useTranslation } from 'react-i18next';

const ContactUs = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="flex flex-col px-48 mt-10">
        <div className="flex justify-between items-center">
          <h1 className="contact-head text-6xl">{t('contact_us_page.title')}</h1>
          <h3 className="text-xl tracking-wide">
            SHUNIYA VIGYAN PRIVATE LIMITED
          </h3>
        </div>
        <div className="w-full h-[1px] bg-neutral-400 mt-3 mb-10"></div>
      </div>

      <h3 className="text-center text-2xl second-head tracking-wide">
      {t('contact_us_page.description1')}
         <br /> 
         {t('contact_us_page.description2')}
      </h3>

      <div className="three-container mt-20 flex justify-center items-center gap-28">
        <div className="first-container w-72 h-72 rounded-2xl bg-[#ededed] flex flex-col justify-center items-center gap-10">
          <img src={location} alt="location" />
          <h2 className="text-lg text-center second-head">
            HOUSE NO. 3209, 2ND FLOOR SECTOR-23 Gurgaon, Haryana 122017 India
          </h2>
        </div>

        <div className="first-container w-72 h-72 rounded-2xl bg-[#ededed] flex flex-col justify-center items-center gap-10">
          <img src={phone} alt="phone" className="-mt-14" />
          <a
            href="tel:+919234531332"
            className="text-xl tracking-wider no-underline text-black text-center second-head"
          >
            +919234531332
          </a>
        </div>

        <div className="first-container w-72 h-72 rounded-2xl bg-[#ededed] flex flex-col justify-center items-center gap-10">
          <img src={gmail} alt="mail" className="-mt-6" />
          <a
            href="mailto:ankit@shuniyavigyan.com"
            className="text-xl tracking-wide no-underline text-black text-center second-head"
          >
            Mail your queries to <br />
            ankit@shuniyavigyan.com
          </a>
        </div>
      </div>

      <div className="single-container flex justify-between items-center bg-[#ededed] mt-24 mx-[25.5rem] rounded-2xl px-10 py-8">
        <h2 className="text-2xl second-head">
          You can also reach out to us on Social Media
        </h2>
        <div className="icons flex gap-4">
            <a href="/"><img src={youtube} alt="youtube"/></a>
            <a href="/"><img src={facebook} alt="facebook"/></a>
            <a href="/"><img src={instagram} alt="instagram"/></a>
        </div>
      </div>

      <div className="last-section flex justify-center items-center">
        <h2 className="text-center text-3xl second-head tracking-wider mt-20 pb-20">
        Your needs are our priority. Contact us anytime. <br/>We're always here for you. 
        </h2>
      </div>
      <Footer />
    </div>
  );
};

export default ContactUs;
