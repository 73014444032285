import React, { useRef, useState, useEffect } from "react";
import { Box} from "@chakra-ui/react";
import { Editor, useMonaco } from "@monaco-editor/react";
import LanguageSelector from "./LanguageSelector";
import { CODE_SNIPPETS } from "../utils/constants";
import Output from "./Output";
import EditorFooter from "./EditorFooter";
import {
  Navbar,
  Nav,
  NavDropdown,
  Container,
} from "react-bootstrap";
import logo from "./logo.svg";
import keyboardsvg from "./Keyboard.svg";
import AskLali from "./AskLali.svg";
import Save from "./Save.svg";
import RunButton from "./RunButton.svg";
import pauseIcon from "./pausesvg.svg";
import Lang from "./Lang.svg";
import "react-simple-keyboard/build/css/index.css";
import publicnet from "./public.svg";
import privatenet from "./private.svg";
import saveicon from "./saveIcon.svg";
import sendbutton from "./sendButton.svg";
import microphone from "./microphone.svg";
import crossIcon from "./crossIcon.svg";
import backcode from "./backcode.svg";
import backfont from "./backfont.svg";
import "./CircularCheckbox.css";
import { debounce } from "lodash";
import DataFetcher from "./DataFetcher";
import fileAdd from "./fileAdd.svg";
import folderAdd from "./folderAdd.svg";
import threedots from "./threedots.svg";
import uploadfile from "./uploadfile.svg";
import uploadfolder from "./uploadfolder.svg";
import KeyboardDropdown from "./KeyboardDropdown";
import ResizeObserver from "resize-observer-polyfill";
import "./codeEditor.css";
import homebutton from "./Vector.svg";
import EditorButton from "./EditorButton.js";

const CodeEditor = () => {
  const monaco = useMonaco();
  const editorRef = useRef(null);
  const [value, setValue] = useState("");
  const [language, setLanguage] = useState("javascript");
  const [showKeyboard, setShowKeyboard] = useState(false);
  const keyboard = useRef();
  const [output, setOutput] = useState(null);
  const [isToggled, setIsToggled] = useState(false);
  const [editorHeight, setEditorHeight] = useState("83vh"); 
  const editorContainerRef = useRef(null);
  const [mode, setMode] = useState(true);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [selected, setSelected] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isSideBySide, setIsSideBySide] = useState(true);
  const [isDotsDropdownOpen, setIsDotsDropdownOpen] = useState(false);
  const [fontSize, setFontSize] = useState(14);
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [recording, setRecording] = useState(null);
  const [askLaliOutput, setAskLaliOutput] = useState("");
  const [audioUrl, setAudioUrl] = useState("");
  const editorInstanceRef = useRef(null);
  const [recordings, setRecordings] = useState([]);
  const [apiResponses, setApiResponses] = useState([]);
  const [outputReceived, setOutputReceived] = useState(false);
  const bottomRef = useRef(null);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionsPosition, setSuggestionsPosition] = useState({ top: 0, left: 0 });
  const [isTransliterate, setIsTransliterate] = useState(false);
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1);
  const [keydownDisposable, setKeydownDisposable] = useState(null);

  const handleEditorChange = (newValue) => {
    setValue(newValue);
    if (isTransliterate) {
        if (newValue.trim().length === 0 || /[\s\[\]{}()<>"'“”‘’«»]$/.test(newValue)) {
            setSuggestions([]);
            setShowSuggestions(false);
            return;
        }
        const match = newValue.match(/(?:[\[\]{}()<>"'“”‘’«»])([a-zA-Z\u0900-\u097F]+)(?:[\[\]{}()<>"'“”‘’«»])$/);
        if (match) {
            const lastWord = match[1];
            fetchTransliteration(lastWord);
        } else {
            setSuggestions([]);
            setShowSuggestions(false);
        }
        return;
      }
      
      const match = newValue.match(/(?:[\[\]{}()<>"'“”‘’«»]*)([a-zA-Z\u0900-\u097F]+)(?:[\[\]{}()<>"'“”‘’«»]*)$/);
      if (match) {
        const lastWord = match[1];
        fetchTransliteration(lastWord);
      } else {
        setSuggestions([]);
        setShowSuggestions(false);
      }
  };

  const handleTransliterateToggle = async () => {
    const newTransliterateState = !isTransliterate;
    setIsTransliterate(newTransliterateState);
    setSuggestions([]);
    setShowSuggestions(false);
    if (newTransliterateState && value.trim().length > 0) {
        const match = value.match(/(?:[\[\]{}()<>"'“”‘’«»])([a-zA-Z\u0900-\u097F]+)(?:[\[\]{}()<>"'“”‘’«»])$/);
      if (match) {
        const lastWord = match[1];
        await fetchTransliteration(lastWord);
      }
    }
  };

  const fetchTransliteration = async (word) => {
    try {
      const response = await fetch('http://कोड.com:5000/transliterate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ word }),
      });
  
      if (response.ok) {
        const data = await response.json();
        const hindiSuggestions = data.suggestions && data.suggestions.hi ? data.suggestions.hi : [];
        const allSuggestions = [...hindiSuggestions, word];
        setSuggestions(allSuggestions);
        const position = calculateSuggestionsPosition();
        setSuggestionsPosition(position);
        setShowSuggestions(true);
      } else {
        console.error('API Response Error:', response.statusText);
        setSuggestions([]);
        setShowSuggestions(false);
      }
    } catch (error) {
      console.error('Error fetching transliteration:', error);
      setSuggestions([]);
      setShowSuggestions(false);
    }
  };
  

  const calculateSuggestionsPosition = () => {
    const editor = editorRef.current;
    if (editor) {
      const model = editor.getModel();
      const position = editor.getPosition();
      const wordAtPosition = model.getWordAtPosition(position);
      if (wordAtPosition) {
        const { startColumn } = wordAtPosition;
        const startCoords = editor.getScrolledVisiblePosition({
          lineNumber: position.lineNumber,
          column: startColumn,
        });
        return {
          top: startCoords.top + startCoords.height,
          left: startCoords.left,
        };
      }
    }
    return { top: 0, left: 0 };
  };

  const handleSuggestionClick = (suggestion) => {
    const editor = editorRef.current;
    if (editor) {
      const model = editor.getModel();
      const position = editor.getPosition();
      const wordAtPosition = model.getWordAtPosition(position);
      if (wordAtPosition) {
        const { startColumn, endColumn } = wordAtPosition;
        editor.executeEdits('', [
          {
            range: {
              startLineNumber: position.lineNumber,
              startColumn: startColumn,
              endLineNumber: position.lineNumber,
              endColumn: endColumn,
            },
            text: suggestion,
          },
        ]);
        editor.setPosition({
          lineNumber: position.lineNumber,
          column: startColumn + suggestion.length,
        });
        setSuggestions([]);
        setShowSuggestions(false);
        setSelectedSuggestionIndex(-1);
      }
    }
  };

  const handleKeyDown = (event) => {
    if (showSuggestions && suggestions.length > 0) {
      if (event.key === 'ArrowDown') {
        setSelectedSuggestionIndex((prevIndex) =>
          prevIndex < suggestions.length - 1 ? prevIndex + 1 : 0
        );
        event.preventDefault();
      } else if (event.key === 'ArrowUp') {
        setSelectedSuggestionIndex((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : suggestions.length - 1
        );
        event.preventDefault();
      } else if (event.key === 'Enter' && selectedSuggestionIndex >= 0) {
        handleSuggestionClick(suggestions[selectedSuggestionIndex]);
        event.preventDefault();
      }
    }
  };

  useEffect(() => {
    const editor = editorRef.current;
    if (editor) {
      const disposable = editor.onKeyDown(handleKeyDown);
      setKeydownDisposable(disposable);
    }
    return () => {
      if (keydownDisposable) {
        keydownDisposable.dispose();
      }
    };
  }, [showSuggestions, suggestions, selectedSuggestionIndex]);

  useEffect(() => {
    setSelectedSuggestionIndex(-1);
  }, [suggestions]);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const closeDropdown = () => {
    setShowDropdown(false);
  };

  const startRecording = () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      setIsRecording(true);
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          const mediaRecorder = new MediaRecorder(stream);
          setRecording(mediaRecorder);
          let chunks = [];
          mediaRecorder.ondataavailable = (e) => chunks.push(e.data);
          mediaRecorder.onstop = () => {
            const blob = new Blob(chunks, { type: "audio/wav" });
            const url = URL.createObjectURL(blob);
            setRecordings((prevRecordings) => [
              ...prevRecordings,
              { blob, url },
            ]);
            setAudioUrl(url);
            setIsRecording(false); 
          };
          mediaRecorder.start();
        })
        .catch((err) => {
          console.error("Recording failed:", err);
          setIsRecording(false);
        });
    } else {
      console.error("MediaDevices not supported.");
    }
  };
  
  const stopRecording = () => {
    if (recording) {
      recording.stop();
    }
  };
  
  const sendRecordingToBackend = async (blob) => {
    const formData = new FormData();
    formData.append("file", blob, "recording.wav");
    formData.append("code", "cvds df df");
    formData.append("cursor po", "cddfdffd");
    try {
      const response = await fetch(
        "https://कोड.com/api/v1/lali_speech_to_text/",
        {
          method: "POST",
          body: formData,
        }
      );
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Network response was not ok: ${errorText}`);
      }
      const result = await response.json();
      console.log("Backend Response:", result);
      if (result.predicted_text && result.code) {
        setApiResponses((prevResponses) => [
          ...prevResponses,
          {
            id: Date.now(),
            predictedText: result.predicted_text,
            code: result.code,
          },
        ]);
        setOutputReceived(true);
      } else {
        console.error(
          "Response does not contain the expected fields"
        );
        setAskLaliOutput("Unexpected response format");
        setOutputReceived(true);
      }
    } catch (error) {
      console.error("Error sending recording to backend:", error);
      setAskLaliOutput("Error occurred while sending recording");
      setOutputReceived(true);
    }
  };
  
  const handleSend = () => {
    if (!isRecording && recordings.length > 0) {
      const latestRecording = recordings[recordings.length - 1];
      sendRecordingToBackend(latestRecording.blob);
    }
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (editorInstanceRef.current) {
      editorInstanceRef.current.updateOptions(`{ fontSize: ${fontSize}px }`);
    }
  }, [fontSize]);

  const handleEditorMount = (editor) => {
    editorRef.current = editor;
    editorInstanceRef.current = editor;
  };

  const themeColor = () => {
    setMode(!mode);
    if (monaco) {
      monaco.editor.setTheme(mode ? "customLightTheme" : "customDarkTheme");
    }
  };

  const handleToggle = () => {
    setIsToggled(!isToggled);
  };

  const handleSvgClick = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  const handleCloseClick = () => {
    setIsDropdownVisible(false);
  };

  const toggleDotsDropdown = () => {
    setIsDotsDropdownOpen(!isDotsDropdownOpen);
  };


  const dropdownStyle = {
    top: "100%",
    right: "20px", 
    zIndex: "1000",
    width: "506px",
    height: "585px",
  };

  const inputStyle = {
    width: "100%",
    height: "47px",
    padding: "8px 50px 8px 8px",
    borderRadius: "65px",
    boxSizing: "border-box",
    backgroundColor: "#222045",
    color: "white",
  };

  const inputIconsStyle = {
    position: "absolute",
    top: "50%",
    right: "10px",
    transform: "translateY(-50%)",
    display: "flex",
    gap: "5px",
  };

  const handleChange = (id) => {
    setSelected(id === selected ? null : id);
  };

  const layoutStyle = {
    display: "flex",
    flexDirection: isSideBySide ? "row" : "column",
    height: "100vh", 
  };

  const runCode = async () => {
    const sourceCode = editorRef.current.getValue();
    const codeBase64 = encodeToBase64(sourceCode);
    try {
      const response = await fetch("https://कोड.com/api/v1/web_ide/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          code_file_name: "a.py",
          code_language: language,
          code_input_b64: null,
          code_text_b64: codeBase64,
          user_id: "user123",
          input_flag: "ABSENT",
        }),
      });
      const result = await response.text();
      console.log(result);
      setOutput(result);
    } catch (error) {
      console.error("Error running code:", error);
      setOutput("An error occurred while running the code.");
    }
  };

  const encodeToBase64 = (str) => {
    const encoder = new TextEncoder();
    const data = encoder.encode(str);
    return btoa(String.fromCharCode(...data));
  };

  const containerTerminalSvgStyle = {
    display: "flex",
    flexDirection: isToggled ? "row" : "column",
    width: "100%",
    height: "100vh",
  };

  const editorStyle = {
    height: isToggled ? "100%" : "50%",
    width: isToggled ? "50%" : "100%",
  };

  const outputStyle = {
    height: isToggled ? "100%" : "50%",
    width: isToggled ? "50%" : "100%",
  };

  const editorContainerStyle = {
    width: isToggled ? "50%" : "100%",
    height: "100%",
    overflow: "auto",
    WebkitOverflowScrolling: "touch",
  };

useEffect(() => {
  if (monaco) {
    monaco.editor.defineTheme("customLightTheme", {
      base: "vs",
      inherit: true,
      rules: [
        { token: "comment", foreground: "008800", fontStyle: "italic" },
        { token: "keyword", foreground: "0000ff" },
        { token: "identifier", foreground: "000000" },
      ],
      colors: {
        "editor.background": "#FFFFFF",
        "editor.foreground": "#000000",
        "editor.lineHighlightBackground": "#EEEEEE",
      },
    });

    monaco.editor.defineTheme("customDarkTheme", {
      base: "vs-dark",
      inherit: true,
      rules: [
        { token: "comment", foreground: "ff0000", fontStyle: "italic" },
        { token: "keyword", foreground: "00ff00" },
        { token: "identifier", foreground: "00ffff" },
      ],
      colors: {
        "editor.background": "#1A183D",
        "editor.foreground": "#FFFFFF",
        "editor.lineHighlightBackground": "#2B2B2B",
      },
    });
    monaco.editor.setTheme(mode ? "customDarkTheme" : "customLightTheme");
  }

 
  const handleResize = debounce((entries) => {
    for (let entry of entries) { 
      console.log("Resized:", entry.contentRect);
    }
  }, 100); 
  const resizeObserver = new ResizeObserver(handleResize);
  if (editorContainerRef.current) {
    resizeObserver.observe(editorContainerRef.current);
  }
  return () => {
    resizeObserver.disconnect();
  };
}, [monaco, mode]);

const handleButtonClick = () => {
  setShowKeyboard(!showKeyboard);
};

const onChange = (value) => {
  setValue(value);
};

const onKeyPress = (button) => {
  if (button === "{shift}" || button === "{lock}") handleShift();
};

const handleShift = () => {
  keyboard.current.setOptions({
    layoutName:
      keyboard.current.options.layoutName === "default" ? "shift" : "default",
  });
};

const onMount = (editor) => {
  editorRef.current = editor;
};

const onSelect = (language) => {
  setLanguage(language);
  setValue(CODE_SNIPPETS[language]);
};

return (
  <>
     <Navbar expand="lg" className="custom-navbar fixed-top">
      <div className="navbar-container">
        <div className="navbaaaaarrr">
          <Navbar.Brand href="/">
            <img src={homebutton} alt="Brand Logo" className="home-logo" />
          </Navbar.Brand>
          <Navbar.Brand onClick={() => setSidebarVisible(!sidebarVisible)}>
            <img src={logo} alt="Brand Logo" className="brand-logo" />
          </Navbar.Brand>
          <Nav className="me-auto nav-links chota-nav">
            <NavDropdown title={<span className="nav-text" style={{ color: "white" }}>File</span>} id="basic-nav-dropdown">
              <NavDropdown.Item className="no-hover">Save</NavDropdown.Item>
              <div className="dropdown-divider"></div>
              <NavDropdown.Item className="no-hover">Download</NavDropdown.Item>
            </NavDropdown>
            <div className="language-selector">
              <LanguageSelector language={language} onSelect={onSelect} />
            </div>
            <NavDropdown title={<span className="nav-text" style={{ color: "white" }}>Terminal</span>} id="basic-nav-dropdown">
              <NavDropdown.Item className="no-hover">Terminal Box</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </div>
        <div className="button-section">
          <button onClick={runCode}>
            <img src={RunButton} alt="Play" className="run-button" />
          </button>
          <button>
            <a href="http://52.146.94.163:3000/" target="_blank" className="game-engine-link">Game Engine</a>
          </button>
          <KeyboardDropdown className="keyboard-dropdown" keyboardsvg={keyboardsvg} crossIcon={crossIcon} onTransliterateToggle={handleTransliterateToggle}/>
          <div className="ask-lali-section">
            <img src={AskLali} alt="AskLali" onClick={toggleDropdown} className="ask-lali-icon" />
            {showDropdown && (
              <div className="dropdown-menu-content" style={dropdownStyle}>
                <button className="close-button" onClick={closeDropdown}>
                  <img src={crossIcon} alt="Close" />
                </button>
                {!outputReceived && (
                  <div className="dropdown-scroll-content">
                    <img src={backcode} alt="Back Code" className="backcode-image" />
                    <img src={backfont} alt="Back Font" className="backfont-image" />
                  </div>
                )}
                <div className="api-response-content">
                {apiResponses.map((response) => (
                <div
                  key={response.id}
                  style={{
                    marginLeft: "10px",
                    marginBottom: "10px",
                    color: "white",
                  }}
                >
                  <div
                    style={{
                      textAlign: "right",
                      marginRight: "20px",
                    }}
                  >
                    {response.predictedText}
                  </div>

                  <pre style={{ margin: "10px" }}>{response.code}</pre>
                </div>
              ))}
                  <div ref={bottomRef}></div>
                </div>
                <div className="input-section">
                  <input type="text" placeholder="Start a conversation with Lali" style={inputStyle} />
                  <div style={inputIconsStyle}>
                    {!isRecording ? (
                      <img src={microphone} alt="microphone" className="icon" onClick={startRecording} />
                    ) : (
                      <img src={pauseIcon} alt="pause" className="icon" onClick={stopRecording} />
                    )}
                    <img src={sendbutton} alt="sendbutton" className="icon" onClick={handleSend} />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="save-dropdown-container">
            <img src={Save} alt="Save" onClick={handleSvgClick} className="save-icon" />
            {isDropdownVisible && (
              <div className="save-dropdown-content">
                <button onClick={handleCloseClick} className="close-button">
                  <img src={crossIcon} alt="Close" />
                </button>
                <div className="save-options">
                  <p>Enter your Filename</p>
                  <input type="text" className="save-input" />
                  <p>Access</p>
                  <div className="access-options">
                    <div className="access-option1">
                      <img src={publicnet} alt="Public" className="access-icon" />
                      <div>
                        <h5>Public</h5>
                        <p>Anyone with the link can access</p>
                      </div>
                      <div className="circular-checkbox-container">
                          <input
                            type="checkbox"
                            id="checkbox-1"
                            checked={selected === 1}
                            onChange={() => handleChange(1)}
                            className="circular-checkbox-input"
                          />
                          <label
                            htmlFor="checkbox-1"
                            className={`circular-checkbox-label ${
                              selected === 1 ? "checked" : ""
                            }`}
                          ></label>
                        </div>
                    </div>
                    <div className="access-option2">
                      <img src={privatenet} alt="Private" className="access-icon" />
                      <div>
                        <h5>Private</h5>
                        <p>Only you can access the file</p>
                      </div>
                      <div className="circular-checkbox-container">
                          <input
                            type="checkbox"
                            id="checkbox-2"
                            checked={selected === 2}
                            onChange={() => handleChange(2)}
                            className="circular-checkbox-input"
                          />
                          <label
                            htmlFor="checkbox-2"
                            className={`circular-checkbox-label ${
                              selected === 2 ? "checked" : ""
                            }`}
                          ></label>
                        </div>
                    </div>
                  </div>
                </div>
                <button className="save-button" onClick={() => alert("Save clicked")}>
                  <img src={saveicon} alt="Save" className="save-button-icon" />
                  Save
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </Navbar>
      <div className="main-content" style={{ marginTop: '80px' }}>
        <Box display="flex" flexDirection="column" height="89vh" overflow="hidden">
          <Box display="flex" width="100%" height="100%" overflow="hidden" position="relative" margin="0px">
            <Box width={sidebarVisible ? "15%" : "0%"} height="100%" backgroundColor="#0E0B33" position="absolute" left="0" display={sidebarVisible ? "flex" : "none"} flexDirection="column" justifyContent="flex-start" zIndex="0" overflow="auto" transition="width 0.3s">
              <nav className="sidebar-nav">
                <NavDropdown title={<span className="nav-dropdown-title">Explore</span>} id="basic-nav-dropdown">
                  <NavDropdown.Item className="no-hover">Save</NavDropdown.Item>
                  <div className="divider"></div>
                  <NavDropdown.Item className="no-hover">Download</NavDropdown.Item>
                </NavDropdown>
                <div className="icon-container">
                  <img src={fileAdd} alt="Icon 1" className="icon" />
                  <img src={folderAdd} alt="Icon 2" className="icon" />
                  <div className="dots-dropdown-container">
                    <img src={threedots} alt="threedots" className="icon" onClick={toggleDotsDropdown} />
                    {isDotsDropdownOpen && (
                      <div className="dots-dropdown">
                        <div className="dropdown-option">
                          <span>Upload File</span>
                          <img src={uploadfile} alt="Upload File" className="dropdown-icon" />
                        </div>
                        <div className="divider"></div>
                        <div className="dropdown-option">
                          <span>Upload Folder</span>
                          <img src={uploadfolder} alt="Upload Folder" className="dropdown-icon" />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </nav>
              <div>
                <DataFetcher />
              </div>
            </Box>
            <Box marginLeft={sidebarVisible ? "15%" : "0%"} width={sidebarVisible ? "85%" : "100%"} height="100vh" display="flex" flexDirection="column" transition="margin-left 0.3s">
              <Navbar expand="lg" className="navbar-second">
                <Container>
                  <div className="navbar-icons">
                    <img src={Lang} alt="Lang" className="navbar-icon" />
                  </div>
                  <div onClick={handleToggle} className="toggle-button">
                    {isToggled ? (
                      <svg width="25" height="25" viewBox="0 0 25 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.0196533" width="24" height="28" rx="3" fill="#0E0B33" />
                        <path d="M24.004 13.9609L24.0313 24.9962C24.0354 26.653 22.6956 27.9995 21.0387 28.0036L3.03464 28.048C1.37779 28.0521 0.0313341 26.7123 0.0272403 25.0555L-2.62135e-05 14.0202L24.004 13.9609Z" fill="#51408B" />
                      </svg>
                    ) : (
                      <svg width="25" height="25" viewBox="0 0 24 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="24" height="28" rx="3" fill="#0E0B33" />
                        <path d="M12 0H21C22.6569 0 24 1.34315 24 3V25C24 26.6569 22.6569 28 21 28H12V0Z" fill="#51408B" />
                      </svg>
                    )}
                    <EditorButton themeColor={themeColor} mode={mode} />
                  </div>
                </Container>
              </Navbar>
              <Box display="flex" flexDirection={isToggled ? "column" : "row"} width="100%" height="100vh" overflow="hidden">
                <Box width={isToggled ? "100%" : "50%"} height={isToggled ? "50%" : "100%"} display="flex" flexDirection="column">
                  <Editor
                    width="100%"
                    height="100%"
                    overflow="hidden"
                    fontSize={fontSize}
                    setFontSize={setFontSize}
                    language={language}
                    value={value}
                    onChange={handleEditorChange}
                    options={{
                      scrollBeyondLastLine: false,
                      minimap: { enabled: false },
                      fontSize: fontSize,
                      rulers: [],
                      overviewRulerLanes: 0,
                      renderLineHighlight: "none",
                    }}
                    onMount={(editor) => (editorRef.current = editor)}
                  />
                  {showSuggestions && suggestions.length > 0 && (
                    <div
                      className="suggestions"
                      style={{
                        top: `${suggestionsPosition.top}px`,
                        left: `${suggestionsPosition.left}px`,
                      }}
                    >
                      <style>
                        {`
                          .suggestions::-webkit-scrollbar {
                            width: 0px;
                            height: 0px;
                          }
                        `}
                      </style>
                      {suggestions.map((suggestion, index) => (
                        <div
                          key={index}
                          className={`suggestion-item ${index === selectedSuggestionIndex ? 'selected' : ''}`}
                          onClick={() => handleSuggestionClick(suggestion)}
                        >
                          {suggestion}
                        </div>
                      ))}
                    </div>
                  )}
                </Box>
                <Box width={isToggled ? "100%" : "50%"} height={isToggled ? "50%" : "100%"} display="flex" flexDirection="column">
                  <Output
                    className="output"
                    fontSize={fontSize}
                    editorHeight={editorHeight}
                    setEditorHeight={setEditorHeight}
                    outputres={output}
                    editorRef={editorRef}
                    language={language}
                    handleButtonClick={handleButtonClick}
                    themeColor={themeColor}
                    setFontSize={setFontSize}
                    mode={mode}
                  />
                  <EditorFooter langpass={language} />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </div>
  </>
);
};

export default CodeEditor;