import React, { useEffect, useState } from 'react';
import loginBg from '../images/loginBg.jpg';
import Google from '../images/Google.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from "react-router-dom";   
import Applogo from "./assets/images/appLogo.png"; 
import { useAuth } from './AuthContext';
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";  
import { useNavigate } from 'react-router-dom';  
import User from './assets/images/User_login_input.png';  
import CorrectInput from './assets/images/rightInput_login.png';  
import WrongInput from './assets/images/wrongInput_login.png';
import { useTranslation } from 'react-i18next';

import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

const Login = () => {
    const navigate = useNavigate();
    const { login } = useAuth();
    const { t } = useTranslation();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
  
    // State to manage password visibility
    const [showPassword, setShowPassword] = useState(false);

    const onSubmit = async (data) => {
        console.log("Submitting data:", data);
        
        const formData = new FormData();
        formData.append('username', data.username);
        formData.append('password', data.password);
      
        console.log("FormData content:", Array.from(formData.entries()));
        
        const apiUrl = 'https://xn--11by0j.com/api/v1/register_or_login/';
        
        try {
          const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
              // No 'Content-Type' header needed when using FormData
            },
            body: formData,
          });
      
          console.log("Response status:", response.status); // Log the response status
          console.log("Response headers:", response.headers); // Log response headers
      
          const responseData = await response.json(); // Parse response as JSON
      
          if (response.ok) {
            console.log('Success response data:', responseData);
            toast.success('Registration successful!');
            localStorage.setItem('user', JSON.stringify(responseData.user_id));
            login();
            navigate('/');
          } else {
            console.error('Response error data:', responseData);
            toast.error(`Registration failed: ${responseData.message || 'Please try again.'}`);
          }
        } catch (error) {
          console.error('Error during fetch:', error);
          toast.error('An error occurred. Please try again.');
        }
    };

  //   useEffect(() => {
  //     const user = localStorage.getItem('user');
  //     console.log(user)
  //     if (user) {
  //         // Assuming `login` is a method to set the user as logged in
  //         console.log(user)
  //         login();
  //         // Optionally, you can set user data to state or context
  //         // setUser(JSON.parse(user));
  //     }
  // }, []);
    
    // Function to toggle password visibility
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
    <>

    <div className='relative'>
    <div className="flex justify-start items-center gap-3 py-2 absolute mt-4 ml-12">
      <a href="/" className="no-underline flex items-center gap-2">
        <img src={Applogo} alt="App Logo" className="w-10 md:w-14" />
        <h1 className="text-3xl md:text-4xl text-white mt-1">{t("login.logo")}</h1>
      </a>
    </div>
        <div
  className="min-h-screen bg-cover bg-center flex items-center justify-center"
  style={{ backgroundImage: `url(${loginBg})` }}
>
  <div className="container px-4 md:px-8 lg:px-16">
    {/* Logo and Heading */}
    

    <div className="flex flex-col lg:flex-row justify-between items-center mt-12 lg:mt-16">
      {/* Welcome Section */}
      <div className="lg:w-1/2 mb-10 lg:mb-0 lg:pl-8">
        <h1 className="text-4xl md:text-6xl font-semibold text-white text-center">
        {t("login.namaste")}
        </h1>
        <div className='flex flex-col justify-center items-center mt-6'>
        <p className=" text-xl md:text-2xl text-white text-center">
        {t("login.title1")}
        </p>
        <p className=" text-xl md:text-2xl text-white text-center"> {t("login.title2")}</p>
        </div>
      </div>

      {/* Login Form */}
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="lg:w-1/2 w-full max-w-md bg-white p-8 md:p-12 rounded-3xl shadow-md mb-10"
      >
        <h2 className="text-2xl md:text-3xl font-bold text-[#7E22CE] text-center mb-8">
        {t("login.log")}
        </h2>

        {/* Email Input */}
        <div className="mb-6">
          <div className="flex items-center border-2 border-[#564787] rounded-lg px-3 py-2">
            <img src={User} alt="User Icon" className="w-5 h-5" />
            <input
              type="text"
              placeholder={t("login.user")}
              {...register('username', {
                required: 'Username is required',
                // pattern: {
                //   value: /^[^@]+@[^@]+\.[^@]+$/,
                //   message: 'Invalid email address',
                // },
              })}
              className="w-full ml-3 text-gray-700 focus:outline-none border-gray-300 bg-white"
            />
            {errors.username && (
                  <FontAwesomeIcon icon={faExclamationCircle} className="text-red-500 ml-2" />
              )}
          </div>
          {errors.username && (
            <span className="text-red-500 text-sm">{errors.username.message}</span>
          )}
        </div>

        {/* Password Input */}
        <div className="mb-6">
          <div className="flex items-center border-2 border-[#564787] rounded-lg px-3 py-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="18"
              viewBox="0 0 16 18"
              fill="none"
              className="w-5 h-5"
            >
              <path
                d="M14.0625 7.875H13.2188V5.34375C13.2188 2.39766 10.8211 0 7.875 0C4.92891 0 2.53125 2.39766 2.53125 5.34375V7.875H1.6875C0.755859 7.875 0 8.63086 0 9.5625V16.3125C0 17.2441 0.755859 18 1.6875 18H14.0625C14.9941 18 15.75 17.2441 15.75 16.3125V9.5625C15.75 8.63086 14.9941 7.875 14.0625 7.875ZM10.4062 7.875H5.34375V5.34375C5.34375 3.94805 6.4793 2.8125 7.875 2.8125C9.2707 2.8125 10.4062 3.94805 10.4062 5.34375V7.875Z"
                fill="#5D576B"
              />
            </svg>
            <input
              type={showPassword ? 'text' : 'password'}
              placeholder={t("login.password")}
              {...register('password', {
                required: 'Password is required',
                // pattern: {
                //   value: /^.{6}$/, // Ensure the password is exactly 6 characters long
                //   message: "Password must be exactly 6 characters",
                // },
              })}
              className="w-full ml-3 text-gray-700 focus:outline-none bg-white"
            />
            {/* <button
              type="button"
              onClick={togglePasswordVisibility}
              className="text-gray-700"
            >
              <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
            </button> */}
            {errors.password && (
              <FontAwesomeIcon icon={faExclamationCircle} className="text-red-500 ml-2" /> 
            )}
          </div>
          {errors.password && (
            <span className="text-red-500 text-sm">{errors.password.message}</span>
          )}
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          className="w-full bg-[#7E22CE] text-white py-3 rounded-lg font-semibold hover:bg-[#6B1FB5] transition-colors"
        >
          {t("login.submit")}
        </button>

        {/* Register Link */}
        <div className="text-center mt-6">
          <p className="text-gray-600">
          {t("login.have")}{' '}
            <Link to="/register" className="text-[#7E22CE] font-bold">
            {t("login.register")}
            </Link>
          </p>
        </div>
      </form>
    </div>
  </div>
</div>
    </div>
   

    </>
  );
}

export default Login;   
