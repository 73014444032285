import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import Modal from "./Modal";

const LiveClassAccess = () => {
    const [openModal, setOpenModal] = useState(false);

  return (
    <div className="flex flex-col justify-center items-center h-[80vh]">
      <div className="flex flex-col justify-center items-center border-2 border-purple-200 rounded-lg px-44 h-96 bg-purple-100 absolute z-40">
        <p className="text-2xl font-medium tracking-wide">
          Do you have Laptop/Desktop/Mobile with a Webcam and a good internet
          connection?
        </p>
        <div className="flex gap-16 mt-10">
          <button className="ring-2 ring-purple-400 rounded-full py-2 px-4 bg-purple-600 hover:bg-purple-700 hover:text-white hover:scale-105 transition">
            <Link
              to="/bookclass"
              className="text-decoration-none text-white text-xl font-medium"
            >
              Yes, I have
            </Link>
          </button>
          <button className="ring-2 ring-purple-400 rounded-full py-2 px-4 bg-purple-600 text-xl font-medium hover:bg-purple-700 text-white hover:scale-105 transition" onClick={() => {
            setOpenModal(true);
          }}>
            No, I don't have
          </button>
        </div>
      </div>
      {openModal && <Modal closeModal={setOpenModal}/>}
    </div>
  );
};

export default LiveClassAccess;
