import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import MonacoEditor from '@monaco-editor/react';
import Confetti from 'react-confetti';
import ConfettiExplosion from 'react-confetti-explosion';

import { ReactComponent as Cancel } from "./assets/icons/cancel.svg";
import { ReactComponent as Lock } from "./assets/icons/lock.svg";
import { ReactComponent as Hiddenlock } from "./assets/icons/hiddenLock.svg";
import { ReactComponent as Tick } from "./assets/icons/tick.svg";

const Prepare4 = () => {
  const { topicName } = useParams();
  const [activeIndex, setActiveIndex] = useState(0);
  const [showConfetti, setShowConfetti] = useState(false);
  const [showExplosion, setShowExplosion] = useState(false);
  const [showCongrats, setShowCongrats] = useState(false);
  const [problems, setProblems] = useState([]);
  const [compilerMessage, setCompilerMessage] = useState('');
  const [input, setInput] = useState('');
  const [expectedOutput, setExpectedOutput] = useState('');
  const [testResults, setTestResults] = useState([]);
  const [code, setCode] = useState('// Write your code here');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://xn--11by0j.com/api/v1/get-test-cases/18/');
        setProblems(response.data);
        if (response.data.length > 0) {
          setInput(response.data[0].input_data);
          setExpectedOutput(response.data[0].expected_output);
        }
      } catch (error) {
        console.error('Error fetching the data', error);
      }
    };
    fetchData();
  }, []);

  const handleClick = (index) => {
    setActiveIndex(index);
    setInput(problems[index]?.input_data || '');
    setExpectedOutput(problems[index]?.expected_output || '');
  };

  const handleCodeChange = (value) => {
    setCode(value);
  };

  const handleSubmit = async () => {
    try {
      const results = await Promise.all(problems.map(async (problem) => {
        const response = await axios.post('https://your-api-url/run-code', {
          code,
          input: problem.input_data
        });
        return {
          isPassing: response.data.output === problem.expected_output,
          problem
        };
      }));

      setTestResults(results);
      if (results.every(result => result.isPassing)) {
        setShowConfetti(true);
        setShowExplosion(true);
        setShowCongrats(true);
        setTimeout(() => setShowConfetti(false), 5000); // Stop confetti after 5 seconds
      }
    } catch (error) {
      console.error('Error submitting code', error);
    }
  };

  return (
    <div className={showCongrats ? 'relative' : ''}>
      <div className={`${showCongrats ? 'blur-sm' : ''}`}>
        {/* top bar */}
        <div className='h-[75px] w-full border-3 px-36'>  
          <p className='py-4 mx-30 font-semibold'>
            <span className='text-xl font-bold'>
              <Link to="/prepare"><span>Prepare</span></Link> &gt; <Link to="/prepare3/:topicName"> {topicName.charAt(0).toUpperCase() + topicName.slice(1)}</Link>
            </span> &gt; {problems[activeIndex]?.problem?.title_en || 'Loading...'}
          </p>  
        </div>

        {/* question description */}
        <div className='h-full w-full px-36 py-16 space-y-8'>
          <div className='w-full h-full rounded-2xl space-y-6 overflow-y-scroll'>
            <div className='w-40 border-b-4 border-[#ADADAD]'><h3>Question</h3></div>
            <p className='font-medium text-2xl'>{problems[activeIndex]?.problem?.title_en || 'Loading...'}</p>
            <div className='w-40 border-b-4 border-[#ADADAD]'><h3>Description</h3></div>
            <p className='font-medium text-xl'>{problems[activeIndex]?.problem?.description_en || 'Loading...'}</p>
          </div>

          {/* Monaco Editor */}
          <div className='w-full h-full bg-black p-4 rounded-lg'>
            <MonacoEditor
              height="400px"
              width="100%"
              theme="vs-dark"
              defaultLanguage="javascript"
              value={code}
              onChange={handleCodeChange}
              options={{
                minimap: { enabled: false },
                fontSize: 16,
                wordWrap: 'on'
              }}
            />
          </div>

          {/* Submit */}
          <div className='w-full h-[80px] flex justify-end'>
            <button onClick={handleSubmit} className='bg-purple-700 text-white font-bold text-2xl px-16 h-full rounded-xl'>
              Run Code
            </button>
          </div>

          {/* Test Case Results */}
          <div className='w-full h-full bg-[#F2F2F2] rounded-xl flex justify-start items-center'>
            <span className='py-3 text-red-600 text-3xl pl-8 pr-6 font-semibold'>
              {testResults.length > 0 ? `${testResults.filter(result => !result.isPassing).length}/${testResults.length} test cases failed` : 'No test cases'}
            </span>
            <Cancel />
          </div>

          {/* Test cases */}
          <div className='w-full h-full border-3 rounded'>
            <div className='px-8 h-full w-full mt-8'>
              <div className="flex justify-between space-x-4">
                {problems.map((_, index) => (
                  <div key={index} className={`w-1/4 h-full border-2 border-[#AEAEAE] ${activeIndex === index ? 'bg-white' : 'bg-purple-200'} flex justify-center items-center text-3xl font-bold rounded-xl hover:cursor-pointer`} onClick={() => handleClick(index)}>
                    {testResults[index]?.isPassing ? <Tick /> : <Cancel />}
                    <p className='my-4 px-2'>Test case {index + 1}</p>
                    {index !== 0 && <Lock />} 
                  </div>
                ))}
              </div>
            </div>

            {activeIndex !== 1 && activeIndex !== 2 ? (
              <div className='w-full h-full space-y-4 px-8 py-6'>
                <h3 className='text-2xl font-bold'>Compiler Message</h3>
                <div className='bg-[#E9E9E9] h-[90px] w-full'>{compilerMessage}</div>
                <h3 className='text-2xl font-bold'>Input</h3>
                <div className='bg-[#E9E9E9] h-[90px] w-full'>{input}</div>
                <h3 className='text-2xl font-bold'>Expected Output</h3>
                <div className='bg-[#E9E9E9] h-[90px] w-full'>{expectedOutput}</div>
              </div>
            ) : (
              <div className='h-[500px] w-full flex justify-center items-center'>
                <div className='flex justify-start items-center'>
                  <Hiddenlock />
                  <h2 className='mt-4 px-4 text-[#888888]'>Hidden Test Cases</h2>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Congratulations modal */}
      {showCongrats && (
        <div className='fixed inset-0 z-50 flex items-center justify-center'>
          {showConfetti && <Confetti />}
          {showExplosion && <ConfettiExplosion />}
          <div className='absolute inset-0 bg-black opacity-50'></div>
          <div className='relative z-10 h-3/5 w-2/5 border-2 border-red-400 flex flex-col justify-evenly items-center space-y-12 py-16 bg-white'>
            <h1 className='font-bold'>CONGRATULATIONS</h1>
            <p className='font-semibold text-xl'>You have completed this task</p>
            <Link to='/prepare3'>
              <button className='bg-green-600 text-white border-1 border-green-800 text-xl font-medium py-6 px-14 rounded-2xl'>
                NEXT CHALLENGE
              </button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}

export default Prepare4;
