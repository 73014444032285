import React, { useEffect, useState } from 'react';
import loginBg from '../images/loginBg.jpg';
// import { useForm } from "react-hook-form";
import Google from '../images/Google.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from "react-router-dom";   
import Applogo from "./assets/images/appLogo.png"; 
import { useAuth } from './AuthContext';
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";  
import { useNavigate } from 'react-router-dom';  
import User from './assets/images/User_login_input.png';  
import CorrectInput from './assets/images/rightInput_login.png';  
import WrongInput from './assets/images/wrongInput_login.png';
import { useTranslation } from 'react-i18next';
import Cancel from './assets/images/cancel_register.png'

import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

const ModelLogin = ({modelLogin,handleClose,openRegister }) => {
  
  const navigate = useNavigate();
  const { login } = useAuth();
  const { t } = useTranslation();
  const { register, handleSubmit, formState: { errors }, reset } = useForm();

  // State to manage password visibility
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (modelLogin) {
      reset(); // Clear input data
    }
  }, [modelLogin, reset]);

  if (!modelLogin) return null;

  const onSubmit = async (data) => {
      console.log("Submitting data:", data);
      
      const formData = new FormData();
      formData.append('username', data.username);
      formData.append('password', data.password);
    
      console.log("FormData content:", Array.from(formData.entries()));
      
      const apiUrl = 'https://xn--11by0j.com/api/v1/register_or_login/';
      
      try {
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            // No 'Content-Type' header needed when using FormData
          },
          body: formData,
        });
    
        console.log("Response status:", response.status); // Log the response status
        console.log("Response headers:", response.headers); // Log response headers
    
        const responseData = await response.json(); // Parse response as JSON
    
        if (response.ok) {
          console.log('Success response data:', responseData);
          toast.success('Registration successful!');
          localStorage.setItem('user', JSON.stringify(responseData.user_id));
          login();
          handleClose();
          navigate('/');
        } else {
          console.error('Response error data:', responseData);
          toast.error(`Registration failed: ${responseData.message || 'Please try again.'}`);
        }
      } catch (error) {
        console.error('Error during fetch:', error);
        toast.error('An error occurred. Please try again.');
      }
  };

  return (
    <div className='relative'>
      <div className="absolute top-72 inset-0 bg-opacity-50  flex justify-end items-center z-20">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-[400px] bg-white p-6 rounded-xl shadow-md"
      >
        <div className='relative'><img src={Cancel} alt="" className='absolute top-0 right-0 hover:cursor-pointer' onClick={handleClose}/></div>
        <h2 className="text-2xl md:text-3xl font-bold text-[#7E22CE] text-center my-6">
        {t("login.log")}
        </h2>
        <div className=' flex flex-col items-center text-black'><span>{t('login.title1')} </span> <span> {t('login.title2')}</span></div>


        {/* Email Input */}
        <div className="my-6">
          <div className="flex items-center border-2 border-[#564787] rounded-lg px-3 py-2">
            <img src={User} alt="User Icon" className="w-5 h-5" />
            <input
              type="text"
              placeholder={t("login.user")}
              {...register('username', {
                required: 'Username is required',
              })}
              className="w-full ml-3 text-gray-700 focus:outline-none border-gray-300 bg-white"
            />
            {errors.username && (
                  <FontAwesomeIcon icon={faExclamationCircle} className="text-red-500 ml-2" />
              )}
          </div>
          {errors.username && (
            <span className="text-red-500 text-sm">{errors.username.message}</span>
          )}
        </div>

        {/* Password Input */}
        <div className="mb-6">
          <div className="flex items-center border-2 border-[#564787] rounded-lg px-3 py-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="18"
              viewBox="0 0 16 18"
              fill="none"
              className="w-5 h-5"
            >
              <path
                d="M14.0625 7.875H13.2188V5.34375C13.2188 2.39766 10.8211 0 7.875 0C4.92891 0 2.53125 2.39766 2.53125 5.34375V7.875H1.6875C0.755859 7.875 0 8.63086 0 9.5625V16.3125C0 17.2441 0.755859 18 1.6875 18H14.0625C14.9941 18 15.75 17.2441 15.75 16.3125V9.5625C15.75 8.63086 14.9941 7.875 14.0625 7.875ZM10.4062 7.875H5.34375V5.34375C5.34375 3.94805 6.4793 2.8125 7.875 2.8125C9.2707 2.8125 10.4062 3.94805 10.4062 5.34375V7.875Z"
                fill="#5D576B"
              />
            </svg>
            <input
              type={showPassword ? 'text' : 'password'}
              placeholder={t("login.password")}
              {...register('password', {
                required: 'Password is required',
              })}
              className="w-full ml-3 text-gray-700 focus:outline-none bg-white"
            />
            
            {errors.password && (
              <FontAwesomeIcon icon={faExclamationCircle} className="text-red-500 ml-2" /> 
            )}
          </div>
          {errors.password && (
            <span className="text-red-500 text-sm">{errors.password.message}</span>
          )}
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          className="w-full bg-purple-600 text-white py-2 text-xl rounded-lg font-semibold hover:bg-purple-800 transition-colors"
        >
          {t("login.submit")}
        </button>

        {/* Register Link */}
        <div className="text-center mt-10">
          <p className="text-gray-600">
          {t("login.have")}{' '}
          <button 
            type="button"
            onClick={openRegister} // Use openRegister to open register modal
            className="text-[#7E22CE] font-bold hover:underline"
          >
            {t('login.register')}
          </button>
          </p>
        </div>
        {/* <button 
          onClick={handleClose} 
          className="bg-purple-600 text-white px-4 py-2 rounded-lg"
          >
          OK
        </button> */}
      </form>
    </div>
    </div>
  )
}

export default ModelLogin