import React from 'react';
import { useState, useEffect } from 'react';
import { Box } from "@chakra-ui/react"; 
import CodeEditorPhone from './codeEditorPhone';   
import CodeEditor from './CodeEditor';

const Editor = () => {

  const [isPhone, setIsPhone] = useState(window.innerWidth <= 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsPhone(window.innerWidth <= 1024);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Box minH="85vh"  color="white">       
    <Box className='min-h-[85vh] text-white px-0 py-0'>       
    {isPhone ? <CodeEditorPhone /> : <CodeEditor />}
    </Box>
    </Box>
  )
} ;
  
export default Editor;
