import React, { useState, useEffect, useRef } from 'react';
import './OpeningNarration.css';
import BackSvg from './codegame_assets/Bg.svg'; // Import the background image
import sound from './codegame_assets/sound.svg'; // Import the sound icon
import CrossSvg from './codegame_assets/cancelButton.svg'; // Import the cross icon
import youtube from './codegame_assets/Youtube.svg';
import gif from './codegame_assets/OWL_Happy.gif';
import narrationAudio from './assets/audio/chatur kauwaa hindi.wav'; // Import your audio file

const Narrator = ({ onNext }) => {
  const [showContainer, setShowContainer] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false); // State to control audio playback
  const audioRef = useRef(null); // Reference to the audio element

  const handleCloseClick = () => {
    setShowContainer(false);
  };

  const toggleAudio = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play().catch((error) => {
          console.error('Error playing audio:', error);
        });
      }
      setIsPlaying(!isPlaying); // Toggle playing state
    }
  };

  useEffect(() => {
    // Create a function to handle audio playback
    const playAudio = () => {
      if (audioRef.current) {
        audioRef.current.play().catch((error) => {
          console.error('Error playing audio:', error);
        });
      }
    };

    // Play audio automatically after 0.5 seconds if not already started
    const timer = setTimeout(() => {
      playAudio();
      setIsPlaying(true);
    }, 500);

    // Cleanup on component unmount
    return () => {
      clearTimeout(timer);
      if (audioRef.current) {
        audioRef.current.pause();
        setIsPlaying(false);
      }
    };
  }, []); // Empty dependency array ensures this runs only once on mount

  return (
    <div>
      {showContainer && (
        <div className="narrator-container z-50">
          <div className="background-wrapper">
            <img src={BackSvg} alt="Background" className="background-svg" />
            <div className="heading-textbox">कौआ की कहानी , कोड की ज़ुबानी</div>
            
            {/* Sound control icon */}
            <img 
              src={sound} 
              alt="sound" 
              className="icon-svg sound-icon" 
              onClick={toggleAudio} 
              style={{ cursor: 'pointer' }}
            />
            
            <img 
              src={CrossSvg} 
              alt="Close" 
              className="CloseSvg" 
              onClick={handleCloseClick} 
            />
            <div className="mid-Content">
              चतुर कौआ चंदू के साथ भारत के जंगलों में मज़ेदार सफर पर चलें! चंदू की चुनौतियों को हल करने और कोडिंग ट्रिक्स सीखने के लिए कोडिंग पहेलियाँ सुलझाएं। चंदू की मदद करने और कोडिंग हीरो बनने के लिए तैयार हैं? चलो, सफर शुरू करें!
            </div>
            <a href="https://www.youtube.com" rel="youtube_img">
              <div className="link-youtube">
                <img
                  src={youtube}
                  alt="youtube"
                  className="icon"
                  style={{ cursor: 'pointer' }}
                />
                इसे और अच्छे से समझने के लिए आप यह देखो               
              </div>
            </a>
            <img 
              src={gif} 
              alt="Animated GIF" 
              className="gif-icon" 
            />
            <button className="next-button" onClick={onNext}>आगे बढे</button>
          </div>

          {/* Audio element */}
          <audio ref={audioRef} src={narrationAudio} />
        </div>
      )}
    </div>
  );
};

export default Narrator;
