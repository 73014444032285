import React, { useState, useRef, useEffect } from 'react';
import './OpeningNarration.css';
import BackSvg from './codegame_assets/Bg.svg'; // Import the background image
import sound from './codegame_assets/sound.svg'; // Import the sound icon
import CrossSvg from './codegame_assets/cancelButton.svg'; // Import the cross icon
import youtube from './codegame_assets/Youtube.svg';
import gif from './codegame_assets/OWL_Happy.gif';
import Audiofile from './assets/audio/bhasha_chune_hindi.wav'; // Import your audio file

const Narrator = ({ onNext }) => {
  const [showContainer, setShowContainer] = useState(true);
  const [activeButton, setActiveButton] = useState(null);
  const audioRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (audioRef.current) {
        audioRef.current.play();
      }
    }, 500); // Play audio after 0.5 seconds

    return () => clearTimeout(timer); // Cleanup timer on component unmount
  }, []);

  const handleCloseClick = () => {
    setShowContainer(false);
  };

  const handleButtonClick = (buttonType) => {
    setActiveButton(buttonType);
  };

  const handleSoundClick = () => {
    if (audioRef.current) {
      if (audioRef.current.paused) {
        audioRef.current.play();
      } else {
        audioRef.current.pause();
      }
    }
  };

  return (
    <div>
      {showContainer && (
        <div className="narrator-container z-50">
          <div className="background-wrapper">
            <img src={BackSvg} alt="Background" className="background-svg" />
            <div className="heading-textbox">भाषा चुने</div>
            <img 
              src={sound} 
              alt="sound" 
              className="icon-svg sound-icon" 
              onClick={handleSoundClick} 
            />
            <img 
              src={CrossSvg} 
              alt="Close" 
              className="CloseSvg" 
              onClick={handleCloseClick} 
            />
            <div className="mid-Content">
              आप कोनसे भाषा में खेल खेलना चाहते हैं  
            </div>
            <button
              className={`english-button ${activeButton === 'english' ? 'clicked' : ''}`} 
              onClick={() => handleButtonClick('english')}
            >
              English
            </button>
            <button
              className={`hindi-button ${activeButton === 'hindi' ? 'clicked' : ''}`} 
              onClick={() => handleButtonClick('hindi')}
            >
              हिंदी
            </button>
            <button className="next-button2" onClick={onNext}>आगे बढे</button>
          </div>
          <audio ref={audioRef} src={Audiofile} preload="auto" />
        </div>
      )}
    </div>
  );
};

export default Narrator;
