import React from 'react';
import Footer from './Footer';

const RefundPolicy = () => {
  return (
    <div>
      <div className="flex flex-col px-48 mt-10">
        <div className="flex justify-between items-center">
          <h1 className="contact-head text-6xl">Refund Policy</h1>
        </div>
        <div className="w-full h-[1px] bg-neutral-400 mt-3 mb-10"></div>
      </div>

      <div className="whole px-48">
        <div className="first-info mb-80">
          <p className="text-2xl mt-3">
          At codewala.org, we strive to provide high-quality products and services that meet our customers' needs. However, due to the nature of our business, we do not offer refunds or exchanges on any of our products or services. By purchasing from Code.com, you acknowledge and agree to these terms.
          </p>
        </div>

      </div>

      <Footer />
    </div>
  )
}

export default RefundPolicy
