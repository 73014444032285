import React from 'react';
import {Box ,Text,Menu ,MenuButton,Button, MenuList,MenuItem} from "@chakra-ui/react";      
import {LANGUAGE_VERSIONS} from "../utils/constants";


const languages  = Object.entries(LANGUAGE_VERSIONS);      
const ACTIVE_COLOR  =  "blue.400" ; 

const LanguageSelector = ({language,onSelect}) => {
  return (
    <Box ml={0} mb={0} >
        {/* <Text mb={2} fontSize="lg" > Language: </Text>    */}
        <Menu className = 'z-50'>
            <MenuButton as={Button} > {language} </MenuButton>   
            <MenuList bg="#110c1b">
                {
                    languages.map(([lang ,version]) => (
                        <MenuItem key={lang} 
                            color={
                                lang === language ? ACTIVE_COLOR : ""
                            }
                            bg={
                                lang === language ? "gray.900" : "transparent"
                            }
                            _hover={{
                                color : ACTIVE_COLOR,
                                bg: "gray.900"
                            }}
                            onClick={() => onSelect(lang)}>{lang}  
                            &nbsp;
                            <Text as="span" color="gray" fontSize="sm">           
                                {version}
                            </Text>
                        </MenuItem>   
                    ))
   
                }

                    
            </MenuList>  
        </Menu>            
    </Box>
  )
} 
   
  
export default LanguageSelector
  
// import React from 'react';
// import { Box, Text, Menu, MenuButton, Button, MenuList, MenuItem, useDisclosure } from "@chakra-ui/react";      
// import { LANGUAGE_VERSIONS } from "../utils/constants";

// const languages = Object.entries(LANGUAGE_VERSIONS);      
// const ACTIVE_COLOR = "blue.400"; 

// const LanguageSelector = ({ language, onSelect }) => {
//   const { isOpen, onOpen, onClose } = useDisclosure();

//   return (
//     <Box ml={2} mb={4} zIndex="dropdown">
//       <Menu isOpen={isOpen}>
//         <MenuButton
//           as={Button}
//           colorScheme="purple"
//           onMouseEnter={onOpen}
//           onMouseLeave={onClose}
//         >
//           {language}
//         </MenuButton>   
//         <MenuList
//           bg="#110c1b"
//           onMouseEnter={onOpen}
//           onMouseLeave={onClose}
//         >
//           {
//             languages.map(([lang, version]) => (
//               <MenuItem 
//                 key={lang} 
//                 color={lang === language ? ACTIVE_COLOR : ""}
//                 bg={lang === language ? "gray.900" : "transparent"}
//                 _hover={{
//                   color: ACTIVE_COLOR,
//                   bg: "gray.900"
//                 }}
//                 onClick={() => onSelect(lang)}
//               >
//                 {lang}  
//                 &nbsp;
//                 <Text as="span" color="gray" fontSize="sm">           
//                   {version}
//                 </Text>
//               </MenuItem>   
//             ))
//           }
//         </MenuList>  
//       </Menu>            
//     </Box>
//   );
// }

// export default LanguageSelector;
