import React, { useState } from 'react';
import './OpeningNarration.css';
import BackSvg from './codegame_assets/Bg.svg'; // Import the background image
import sound from './codegame_assets/sound.svg';
import CrossSvg from './codegame_assets/cancelButton.svg'; // Import the cross icon
import youtube from './codegame_assets/Youtube.svg';
import gif from './codegame_assets/OWL_Happy.gif';
import continuebutton from './codegame_assets/continuebutton.svg';
const Narrator = ({onNext}) => {
  const [showContainer, setShowContainer] = useState(true);

  const handleCloseClick = () => {
    setShowContainer(false);
  };

  return (
    <div>
      {showContainer && (
        <div className="narrator-container z-50">
          <div className="background-wrapper">
            <img src={BackSvg} alt="Background" className="background-svg" />
            <div className="heading-textbox">Your 2nd challenge</div>
            <img 
              src={sound} 
              alt="sound" 
              className="icon-svg sound-icon" 
            />
            <img 
              src={CrossSvg} 
              alt="Close" 
              className="CloseSvg" 
              onClick={handleCloseClick} 
            />
            <div className="mid-Content">
                Print(“Welcome to my world”)
            </div>
            <div className='bottom-Content'>
                Write the above line in the editor so that crow can introduce himself to you
            </div>
            <a href="https://www.youtube.com" rel="youtube_img">
                <div className="link-youtube">
                    <img
                        src={youtube}
                        alt="youtube"
                        cursor="pointer"
                        className='icon'
                    />
                    Watch This Tutorial to Understand Better                
                </div>
            </a>
            <img 
                src={gif} 
                alt="Animated GIF" 
                className="gif-icon" 
            />
            <button className="continuebutton" onClick={onNext}>
              <img src={continuebutton} alt="Continue Button" />
            </button>
            </div>
        </div>
      )}
    </div>
  );
};

export default Narrator;
