// this is the code for gamePage in ShuniyaVigyan Project

import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import home from "./assets/images/home.png";
import course from "./assets/images/gotocourse.png";
import ruler from "./assets/images/measuring-tape.png";
import clever from "./assets/images/clever.gif";
import box from "./assets/images/box.png";
import run from "./assets/images/run.png";
import setting from "./assets/images/settings.png";
import input from "./assets/images/input.png";
import outputbox from "./assets/images/output.png";
import refresh from "./assets/images/refresh.png";
// import CodeMirror from "@uiw/react-codemirror";
import { Editor } from '@monaco-editor/react'; 
import { python } from "@codemirror/lang-python";
import * as Sk from "skulpt";
import RefreshModal from "./RefreshModal";
import SettingsPanel from "./SettingsPanel";
import GameModal from "./GameModal";
import Mic from './assets/images/mic.png';
import Setting from './assets/images/settings.png';
import Lali from "./Lali";
import { useSpring, animated } from "@react-spring/web";
import audio from "./assets/audio/owl.mp3";
import CrowLevel00 from "./Crow-Level_00.gif";
import Wood from './assets/images/side_wooden_bar_gamePage.png';
import CrowLevel01 from "./Crow-Level_01_1.gif";
import CrowLevel03 from './Crow-Level_01_3.gif';
import Output from './assets/images/output.png';
import Board from './assets/images/box.png';
import GotoCourses from './assets/images/gotocourse.png';
import Owl from './assets/images/OWL_Face_gamePage.gif'
import Star from './assets/images/Vector.png'
import Token from "./assets/images/Token.png"
import Plus from "./assets/images/plus_gamePage.png"
import Coin from "./assets/images/coins.png"
import Modal from "./Modal_gamePage.js"
import BackgroundMusic from './assets/images/birds-forest-music.mp3';

import OpeningNarration from './OpeningNarration'; // Import the specific component
import OpeningLanguage from './OpeningLanguage';
import OpeningExplain from './OpeningExplain';
import OpeningLevel0 from './OpeningLevel0';
import OpeningQuestion1 from './OpeningQuestion1';
import OpeningCongo from './OpeningCongo';
import OpeningQuestion2 from './OpeningQuestion2';
import OpeningQuestion3 from './OpeningQuestion3.js';
import gameData from './Game.json'
import background from "./assets/images/background_gamepage.jpg"
// import LaliModal from "./LaliModal";
import VoiceActivityDetection from "./VoiceActivityDetection";
import temp from "./assets/images/level_00.gif"
import temp1 from "./assets/images/Level_01_gamepage.gif"
import temp2 from "./assets/images/Level_02_gamepage.gif"
import temp3 from "./assets/images/Level_03_gamepage.gif"

import Audiofile from "./assets/audio/chal button dabao hindi.wav";
import Audiofile0 from "./assets/Level0/Crow-Narration/level0-1.wav";
import Audiofile4 from "./assets/Level0/Crow-Narration/level0-3.wav";
import Audiofile1 from './assets/audio/ChanduTheCrow.wav';
import Audiofile2 from './assets/Level0/Crow-Narration/exercise-1.wav';
import Audiofile3 from './assets/Level0/Crow-Narration/exercise-2.wav';
import Audiofile5 from './assets/Level0/Crow-Narration/exercise-3.wav';
import Audiofile6 from './assets/Level0/Crow-Narration/week days hindi.wav';
import Audiofile7 from './assets/Level0/Crow-Narration/exercise-4.wav';
import Audiofile8 from './assets/Level0/Crow-Narration/ApnaNaam.wav';


const gifs = [
 { gif: temp, position: 'top-40 right-40', size: 'w-[100%] h-[100%]' },
 { gif: temp, position: 'top-40 right-40', size: 'w-[100%] h-[100%]' },
 { gif: temp, position: 'top-40 right-40', size: 'w-[100%] h-[100%]' },
 { gif: temp, position: 'top-40 right-40', size: 'w-[100%] h-[100%]' },
 { gif: temp, position: 'top-40 right-40', size: 'w-[100%] h-[100%]' },
 { gif: temp3, position: '-bottom-12 right-20', size: 'w-[70%] h-[70%]' },
];

const startGifs = [
  {gif: temp1, position:'top-40 right-40', size: 'w-[100%] h-[100%]',audio : Audiofile0},
  {gif: temp1, position:'top-40 right-40', size: 'w-[100%] h-[100%]',audio : Audiofile},
  {gif: temp1, position:'top-40 right-40', size: 'w-[100%] h-[100%]',audio : Audiofile}, // have to add tthe niyam audio
  {gif: temp1, position:'top-40 right-40', size: 'w-[100%] h-[100%]',audio : Audiofile5}, // 
  {gif: temp1, position:'top-40 right-40', size: 'w-[100%] h-[100%]',audio : Audiofile7},
  {gif: temp1, position:'top-40 right-40', size: 'w-[100%] h-[100%]',audio : Audiofile},
]


const endGifs = [
  {gif: temp1, position:'top-40 right-40', size: 'w-[100%] h-[100%]',audio : Audiofile4},
  {gif: temp1, position:'top-40 right-40', size: 'w-[100%] h-[100%]',audio : Audiofile2},
  {gif: temp1, position:'top-40 right-40', size: 'w-[100%] h-[100%]',audio : Audiofile3},
  {gif: temp1, position:'top-40 right-40', size: 'w-[100%] h-[100%]',audio : Audiofile6},
  {gif: temp1, position:'top-40 right-40', size: 'w-[100%] h-[100%]',audio : Audiofile7},
  {gif: temp1, position:'top-40 right-40', size: 'w-[100%] h-[100%]',audio : Audiofile8},
]



const GamePage = () => {
  const [code, setCode] = useState("");
 //  const [placeholder, setPlaceholder] = useState("Write your Python code here...");
  const [output, setOutput] = useState("");
  const [openEntryModal, setOpenEntryModal] = useState(true);
  const [currentSublevel, setCurrentSublevel] = useState(0);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [isOutputChandu, setIsOutputChandu] = useState(false);
  const [videoTransition, setVideoTransition] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [crowGif, setCrowGif] = useState(CrowLevel00);
  const [showLali, setShowLali] = useState(false); // Manage Lali visibility
  const editorRef = useRef(null);
  const location = useLocation();
  const [recording, setRecording] = useState(false);
  const [audioSrc, setAudioSrc] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [hintValue, setHintValue] = useState(2);
  const [coinValue,setCoinValue] = useState(2);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const audioRef = useRef(null);
  const [volume, setVolume] = useState(1);
  const [openingModal, setOpeningModal] = useState(false);
  const [currentFile, setCurrentFile] = useState(1);
  const [showNarrator, setShowNarrator] = useState(true);
  const [isLevelCompleted, setIsLevelCompleted] = useState(false);
  const [variableCount, setVariableCount] = useState(0);
  const [showSuccessModal, setSuccessShowModal] = useState(false);
  const [showLevelModal,setShowLevelModal] = useState(false);
  const [currentLevelModal ,setCurrentLevelModal] = useState(1);

  const [suggestions, setSuggestions] = useState([]);
  const [isTransliterate, setIsTransliterate] = useState(false);
  const [value, setValue] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestionsPosition, setSuggestionsPosition] = useState({ top: 0, left: 0 });

  const [errorShowModal, setErrorShowModal] = useState(false);
  const [gif, setGif] = useState("");
  const [gifCount, setGifCount] = useState(0);
  const [position, setPosition] = useState('');
  const [size, setSize] = useState('');
  const [starCount, setStarCount] = useState(0);
  const [audioCount,setAudioCount] = useState(0);
  const [ startGifCount, setStartGifCount] = useState(0);
  const [ endGifCount, setEndGifCount] = useState(0);

useEffect(() => {
  const initialCode = gameData.game.level_0.sublevels[currentSublevel]?.code || '';
  setCode(initialCode);


  if (gifCount > 0) {
    setCoinValue(coinValue + 2);
    setStarCount(prevCount => prevCount + 1);
  }

  console.log('Current GIF:', gif);
  console.log('GIF Count:', gifCount);

}, [currentSublevel, gifCount]); // Updated dependency array
// Dependencies: Runs whenever currentSublevel changes


 const handleEditorChange = (newValue) => {
  setValue(newValue);

  if (isTransliterate) {
    // Check if the editor is empty or the last character is a space or special character
    if (newValue.trim().length === 0 || /[\s\[\]{}()<>"'“”‘’«»]$/.test(newValue)) {
      setSuggestions([]);
      setShowSuggestions(false);
      return;
    }

    // Regex to capture the last word for transliteration
    const match = newValue.match(/(?:[\[\]{}()<>"'“”‘’«»]*)([a-zA-Z\u0900-\u097F]+)(?:[\[\]{}()<>"'“”‘’«»]*)$/);

    if (match) {
      const lastWord = match[1];
      fetchTransliteration(lastWord); // Fetch suggestions for the last word
    } else {
      setSuggestions([]);
      setShowSuggestions(false);
    }
  } else {
    setSuggestions([]);
    setShowSuggestions(false);
  }
};

const fetchTransliteration = async (word) => {
  try {
    const response = await fetch('http://52.146.94.163:5000/transliterate', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ word }),
    });

    if (response.ok) {
      const data = await response.json();
      console.log(data)
      const hindiSuggestions = data.suggestions && data.suggestions.hi ? data.suggestions.hi : [];
      const allSuggestions = [...hindiSuggestions, word];
      setSuggestions(allSuggestions);

      const position = calculateSuggestionsPosition();
      setSuggestionsPosition(position);

      setShowSuggestions(true);
    } else {
      console.error('API Response Error:', response.statusText);
      setSuggestions([]);
      setShowSuggestions(false);
    }
  } catch (error) {
    console.error('Error fetching transliteration:', error);
    setSuggestions([]);
    setShowSuggestions(false);
  }
};


const calculateSuggestionsPosition = () => {
  const editor = editorRef.current;
  if (editor) {
      const model = editor.getModel();
      const position = editor.getPosition();
      const wordAtPosition = model.getWordAtPosition(position);

      if (wordAtPosition) {
          const { startColumn } = wordAtPosition;
          const startCoords = editor.getScrolledVisiblePosition({
              lineNumber: position.lineNumber,
              column: startColumn,
          });

          return {
              top: startCoords.top + startCoords.height,
              left: startCoords.left,
          };
      }
  }

  return { top: 0, left: 0 };
};

const handleSuggestionClick = (suggestion) => {
  const editor = editorRef.current;
  if (editor) {
    const model = editor.getModel();
    const position = editor.getPosition();
    const wordAtPosition = model.getWordAtPosition(position);

    if (wordAtPosition) {
      const { startColumn, endColumn } = wordAtPosition;
      const newValue =
        value.substring(0, startColumn - 1) +
        suggestion +
        value.substring(endColumn - 1);

      setValue(newValue);

      editor.executeEdits('', [
        {
          range: {
            startLineNumber: position.lineNumber,
            startColumn: startColumn,
            endLineNumber: position.lineNumber,
            endColumn: endColumn,
          },
          text: suggestion,
        },
      ]);

      editor.setPosition({
        lineNumber: position.lineNumber,
        column: startColumn + suggestion.length,
      });

      setSuggestions([]);
      setShowSuggestions(false);
    }
  }
};


 const goToNextSublevel = () => {
   // Move to the next sublevel
   if (currentSublevel < gameData.game.level_0.sublevels.length) {
     setCurrentSublevel(currentSublevel + 1);
     setOutput('')
     setSuccessShowModal(false);
     setShowLevelModal(true);
    //  setGif(temp1)
    
   } else {
     // Handle the case when the user has completed all sublevels in level 0
     console.log('All sublevels completed!');
   }
 };


//   these methods are used to set the next modal shown in the window

 const closeLevelModal = () => {
   setShowLevelModal(false);
   console.log(showLevelModal);
 };
 
 const handleNextLevel = () => {
   if (currentLevelModal < 5) {
     setCurrentLevelModal(currentLevelModal + 1);
   }
   setShowLevelModal(false);
 };
 
 const renderLevelModal = () => {
   switch (currentLevelModal) {
     case 1:
       return <OpeningQuestion2 onClose={closeLevelModal} onNext={handleNextWithActionsLevel} />;
     case 2:
       return <OpeningQuestion2 onClose={closeLevelModal} onNext={handleNextWithActionsLevel} />;
     case 3:
       return <OpeningQuestion2 onClose={closeLevelModal} onNext={handleNextWithActionsLevel} />;
     case 4:
       return <OpeningQuestion2 onClose={closeLevelModal} onNext={handleNextWithActionsLevel} />;
     case 5:
       return <OpeningQuestion3 onClose={closeLevelModal} onNext={closeLevelModal} />;
     default:
       return null;
   }
 };

 const handleNextWithActionsLevel = () =>{
  handleNextLevel();
  renderStarting();
 }


  useEffect(() => {
    setShowNarrator(true);
  }, [currentFile]);

  
  const successfullClick = () => {
   if (variableCount < 3) {
     if (variableCount + 1 < 3) {
       // Run code and handle modals based on the output
       runCode(code);
     }
       setVariableCount(prevCount => prevCount + 1);
   } else {
     // Run code and handle modals based on the output
     runCode(code);
   }
 };
 

  const closeSuccessModal = () => {
   setSuccessShowModal(false);
 };

  const closeNarrator = () => {
    setShowNarrator(false);
    console.log(showNarrator)
  };

  const handleNext = () => {
    if (currentFile < 5) {
      setCurrentFile(currentFile + 1);
    }
  };

  const renderNarrator = () => {
    switch (currentFile) {
      case 1:
       return <OpeningNarration onClose={closeNarrator} onNext={handleNext} />;
      case 2:
       return <OpeningLanguage onClose={closeNarrator} onNext={handleNext} />;
      case 3:
        return <OpeningExplain onClose={closeNarrator} onNext={handleNext} />;
      case 4:
        return <OpeningLevel0 onClose={closeNarrator} onNext={handleNext} />;
      case 5:
        return <OpeningQuestion1 onClose={closeNarrator} onNext={handleNextWithActions} />;
    }
  };

  const handleNextWithActions = () => {
    renderStarting(); // Call renderStarting
    closeNarrator();  // Call closeNarrator
  };

  const renderStarting = () => {
    if (startGifCount < startGifs.length) {
      const { gif, position, size, audio } = startGifs[startGifCount];
  
      // Set the GIF and its properties
      setGif(gif);
      setPosition(position);
      setSize(size);
      setStartGifCount(startGifCount + 1);

      if(currentSublevel=== 2){
        setIsModalOpen(true);
      }
  
      // Play the associated audio
      if (audioRef.current) {
        audioRef.current.pause(); // Pause any currently playing audio
      }
  
      audioRef.current = new Audio(audio); // Create a new Audio object
  
      // Define the handleAudioEnded function
      const handleAudioEnded = () => {
        setGif(temp); // Set GIF to temp when audio ends
      };
  
      audioRef.current.addEventListener('ended', handleAudioEnded); // Add event listener for audio end
  
      audioRef.current.play().catch(error => {
        console.error("Audio playback failed:", error);
      });
  
      // Cleanup function to remove event listener and reset audio
      return () => {
        if (audioRef.current) {
          audioRef.current.pause();
          audioRef.current.currentTime = 0; // Reset audio to start
          audioRef.current.removeEventListener('ended', handleAudioEnded); // Clean up the event listener
        }
      };
    }
  };

  useEffect(() => {
    if (location.state && location.state.openEntryModal) {
      setOpenEntryModal(true);
    }
  }, [location.state]);




  // this component is used to make the autoplay the sound in the background
  useEffect(() => {
   const handleUserInteraction = () => {
     if (audioRef.current) {
       audioRef.current.play().catch(error => {
         console.log('Autoplay was prevented:', error);
       });
     }
     window.removeEventListener('click', handleUserInteraction);
   };

   window.addEventListener('click', handleUserInteraction);

   return () => {
     window.removeEventListener('click', handleUserInteraction);
   };
 }, []);

 useEffect(() => {
   if (audioRef.current) {
     audioRef.current.volume = volume;
   }
 }, [volume]);

 useEffect(() => {
   return () => {
     if (audioRef.current) {
       audioRef.current.pause();
       audioRef.current.currentTime = 0;
     }
   };
 }, []);


  const encodeToBase64 = (str) => {
   const encoder = new TextEncoder();
   const data = encoder.encode(str);
   return btoa(String.fromCharCode(...data));
 };

//  const playAudio = (audioIndex) => {
//   const audio = new Audio(audioFiles[audioIndex]);
//   audio.play().catch((error) => {
//     console.error("Error playing audio:", error);
//   });
// };

const runCode = async (code) => {
  const codeBase64 = encodeToBase64(code);
  try {
    const response = await fetch("https://कोड.com/api/v1/web_ide/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        code_file_name: "a.py",
        code_language: "python",
        code_input_b64: null,
        code_text_b64: codeBase64,
        user_id: "user123",
        input_flag: "ABSENT",
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.text();
    setOutput(result);

    // Check if the output is okay before proceeding with GIF and audio
    if (!result.toLowerCase().includes("error")) {
      // Play GIF and audio if there's no error in the output
      if (endGifCount < endGifs.length) {
        const { gif, position, size, audio } = endGifs[endGifCount];
        setGif(gif);
        setPosition(position);
        setSize(size);
        setEndGifCount(prevCount => prevCount + 1);

        if (audioRef.current) {
          audioRef.current.pause(); // Pause any currently playing audio
        }

        audioRef.current = new Audio(audio); // Create a new Audio object

        // Define the handleAudioEnded function within the runCode function
        const handleAudioEnded = () => {
          if(gifCount < gifs.length){
            const { gif, position,size} = gifs[gifCount];
            setGif(gif);
            setPosition(position);
            setSize(size);
            setGifCount(prevCount => prevCount + 1);
          }
          
           // Set GIF to temp when audio ends

          setTimeout(() => {
            setSuccessShowModal(true); // Open the success modal
          }, 1500);
        };

        audioRef.current.addEventListener('ended', handleAudioEnded); // Add event listener for audio end

        audioRef.current.play().catch(error => {
          console.error("Audio playback failed:", error);
        });

        // Cleanup function to remove event listener and reset audio
        return () => {
          if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0; // Reset audio to start
            audioRef.current.removeEventListener('ended', handleAudioEnded); // Clean up the event listener
          }
        };
      }
    } else {
      // Handle error case if needed
      setErrorShowModal(true);
    }
  } catch (error) {
    console.error("Error running code:", error);
    setOutput("An error occurred while running the code.");
    setErrorShowModal(true);
  }
};


 const handleModalClose = () => {
   setIsLevelCompleted(false);  // Close the modal
 };
 

  const handleHintClick = () => {
   setHintValue(prevValue => prevValue - 1);
   setIsModalOpen(true);
 };

 const handleCoinClick = () => {
   setCoinValue(prevValue => prevValue + 1)
 }

  const handleAudioRecorded = (url) => {
    setAudioSrc(url);
  };

  const handleRecordingStatusChange = (isRecording) => {
    setRecording(isRecording);
  };


  const handleCancelRefresh = () => {
    setShowConfirmation(false);
  };
 
 

  const closeModal = () => {
    setShowModal(false);
    setCode(""); // Clear the editor
    setOutput(""); // Clear the output box
  };

  const toggleLali = () => {
    setShowLali(!showLali);
  };

  return (

  <div>
    <div className="w-full h-[100vh] flex">
       {showNarrator && (
       <div >
         {renderNarrator()}
       </div>
     )}

   <div>
       {showLevelModal && renderLevelModal()}
     </div>

     <audio
       ref={audioRef}
       src={BackgroundMusic}
       autoPlay
       loop
       style={{ display: 'none' }} // Hide the audio element
     />

     
      {/* <VoiceActivityDetection /> */}
        <div className="w-[60%] h-full flex justify-center items-center overflow-hidden">
          <div className="flex items-center justify-center h-full w-screen relative" style={{
             backgroundImage: `url(${background})`,
             backgroundPosition: 'bottom left', // Position the image to the bottom left
             backgroundSize: 'cover', // Ensure the image covers the container
             backgroundRepeat: 'no-repeat', // Avoid repeating the image
             height: '140%', // Adjust the height of the container
             width: '140%', // Adjust the width of the container
           }}>

           <div className='h-[8%] w-full absolute top-[18%] flex justify-evenly items-center'>
             <div className='h-[100%] w-[20%] flex flex-col justify-end items-center relative'>
               <div className='h-[100%] w-[100%] flex items-center relative overflow-x-hidden'>

                 <div className='h-[70%] w-[30%] rounded-full z-40'>
                   <img src={Owl} alt="" style={{ transform: 'rotate(30deg)' }}/>
                 </div>
                 <div className='h-[65%] w-[80%] bg-[#9E5333] border-4 border-[#C08C1C] rounded-2xl  z-10 absolute right-0 flex gap-3 justify-evenly items-center'>
                   <img src={Token} alt="" style={{display:"none"}}/>
                   <div className='text-xl font-semibold text-white ml-14'>{starCount}</div>
                   <img src={Star} alt="" className='right-0'/>
                   
                 </div>
               </div>
              
               <div className='absolute ml-10 pt-7 px-4 top-14 bg-[#9E5333] rounded-xl text-white font-medium border-4 border-[#C08C1C]'>आपके स्टार्स</div>
             </div>

             <div className='h-[100%] w-[20%] flex flex-col justify-end items-center relative'>
               <div className='h-[100%] w-[100%] flex items-center relative overflow-x-hidden'>

                 <div className='h-[70%] w-[30%] rounded-full z-40'>
                   <img src={Owl} alt="" style={{ transform: 'rotate(30deg)' }}/>
                 </div>
                 <div className='h-[65%] w-[80%] bg-[#9E5333] border-4 border-[#C08C1C] rounded-2xl  z-10 absolute right-0 flex justify-evenly items-center'>
                   <img src={Plus} alt="" onClick={handleHintClick} className='cursor-pointer'/>
                   <div className='text-xl font-semibold text-white'>{hintValue}</div>
                   <img src={Token} alt="" className='right-0'/>
                   
                 </div>
               </div>
               <div className='absolute ml-10 pt-7 px-4 top-14 bg-[#9E5333] rounded-xl text-white font-medium border-4 border-[#C08C1C]'>जानकारी</div>
             </div>

             <div className='h-[100%] w-[20%] flex flex-col justify-end items-center relative'>
               <div className='h-[100%] w-[100%] flex items-center relative overflow-x-hidden'>

                 <div className='h-[70%] w-[30%] rounded-full z-40'>
                   <img src={Owl} alt="" style={{ transform: 'rotate(30deg)' }}/>
                 </div>
                 <div className='h-[65%] w-[80%] bg-[#9E5333] border-4 border-[#C08C1C] rounded-2xl  z-10 absolute right-0 flex justify-evenly items-center'>
                   <img src={Plus} alt="" onClick={handleCoinClick}/>
                   <div className='text-xl font-semibold text-white'>{coinValue}</div>
                   <img src={Coin} alt="" className='right-0'/>
                   
                 </div>
               </div>
               <div className='absolute ml-10 pt-7 px-4 top-14 bg-[#9E5333] rounded-xl text-white font-medium border-4 border-[#C08C1C]'>आपके सिक्के</div>
             </div>
             
           </div>

           <div className={`absolute flex justify-center items-center ${position} ${size}`}>
             <img src={gif} alt="" />
           </div>

          </div>
          
        </div>
      

      <div className="w-[40%] h-full">
        <div className="w-[100%] h-full">
          <div className="w-full h-[50%] border-b-orange-500 border-2 bg-[#FFF6ED]">
          <div className="w-[100%] h-[10%] bg-[#de8731] text-2xl font bold px-2"> Input</div>
          <form className="h-full w-full relative">
          <Editor
            value={code}  // Make sure 'code' state is being used here
            height="60vh"
            defaultLanguage="plaintext"
            className="w-full big-cursor text-2xl custom-editor font-bold relative"
            onChange={(value) => handleEditorChange(value)} // Ensure editor changes update the 'code' state
            onMount={(editor) => {
              editorRef.current = editor; // Store Monaco editor instance in ref
              editor.setValue(code);  // Set the initial value to the editor
            }}
          />

          {showSuggestions && (
            <div
              className="suggestions-container z-50"
              style={{ top: suggestionsPosition.top, left: suggestionsPosition.left }}
            >
              {suggestions.map((suggestion, index) => (
                <div key={index} onClick={() => handleSuggestionClick(suggestion)}>
                  {suggestion}
                </div>
              ))}
            </div>
          )}
        </form>

         </div>
          <div id="output" className="w-full h-[40%] relative bg-[#FFF6ED]">
              <div className="w-[100%] h-[10%] bg-[#de8731] text-2xl font bold px-2"> Output</div>
             <div className="p-4 z-10">
               {output.split("\n").map((line, index) => (
               <div key={index}>{line}</div>
             ))}
             </div>
            
          </div>

          <div className="w-[100%] h-[10%]  flex justify-between items-center" style={{backgroundImage: `url(${Wood})`}}>
             <div className="w-[60%] h-full flex items-center mx-4">
                <div className="h-[90%] w-[20%]">
                    <div className="w-[60%] h-[60%] bg-white rounded-full flex justify-center items-center">
                      <Link to="/">
                        <img src={home} alt="" className="h-10 w-10" />
                      </Link>
                    </div>       
                    <p className="text-xl text-white px-2 mt-1 font-semibold">होम</p>
                </div>
                <div className="h-[90%] w-[20%]">
                  <div className="w-[60%] h-[60%] bg-white rounded-full flex justify-center items-center">
                    <Link to="/courses">
                      <img src={GotoCourses} alt="" className="h-10 w-10" />
                    </Link>
                  </div>
                  <p className="text-xl text-white px-2 mt-1 font-semibold">कोर्स</p>
                </div>
                <div className="h-[90%] w-[20%] bg-red">
                  <div className="w-[60%] h-[60%] bg-white rounded-full flex justify-center items-center mb-2 cursor-pointer" onClick={() => {successfullClick()}}>
                    <img src={run} alt="" className="h-10 w-10" />
                  </div>
                  <p className="text-xl text-white px-3 font-semibold">चल</p>
                </div>
                <div className="h-[90%] w-[20%] bg-red">
                  <div className="w-[60%] h-[60%] bg-white rounded-full flex justify-center items-center">
                    <img src={home} alt="" className="h-10 w-10" />
                  </div>
                  <p className="text-xl text-white px-2 mt-1 font-semibold">लेवल</p>
                </div>
             </div>

             <div className="w-[40%] h-full flex justify-end items-center">
                <div className="h-[90%] w-[30%] ">
                    <div className="w-[60%] h-[60%] bg-white rounded-full flex justify-center items-center">
                      <img src={Mic} alt="" className="h-10 w-10" />
                    </div>       
                    <p className="text-xl text-white px-2 mt-1 font-semibold">लाली</p>
                </div>
                <div className="h-[90%] w-[30%] ">
                  <div className="w-[60%] h-[60%] bg-white rounded-full flex justify-center items-center ml-3"  onClick={() => setIsSidebarOpen(prev => !prev)}>
                      <img src={Setting} alt="" className="h-10 w-10" />
                  </div>
                  <p className="text-xl text-white px-2 mt-1 font-semibold">सेटिंग्स</p>
                </div>
              
             </div>
          </div>
        </div>
      </div>

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
      {showSuccessModal && (
       <OpeningCongo onClose={closeSuccessModal} onNext={goToNextSublevel}/>
     )}
     {
      errorShowModal && (
        <OpeningCongo onClose = {closeSuccessModal} onNext={goToNextSublevel}/>
      )
     }
      {isSettingsOpen && (
        <SettingsPanel
          volume={volume}
          isMuted={isMuted}
          onMuteChange={setIsMuted}
        />
      )}
      {/* this is used for side wooden bar */}
      {isSidebarOpen && (
       <div className="fixed rounded-full bottom-10 right-20 h-[13%] w-[30%] text-black p-4 flex justify-center items-center z-50" style={{ backgroundImage: `url(${Board})`,backgroundSize: 'cover',backgroundPosition: 'center', backgroundRepeat: 'no-repeat',   }}>
         <input
           type="range"
           min="0"
           max="1"
           step="0.01"
           value={volume}
           onChange={(e) => setVolume(parseFloat(e.target.value))}
           className="w-2/3"
         />
         <span className="ml-4">Volume: {(volume * 100).toFixed(0)}%</span>
       </div>
     )}
      {showLali && (
        <>
          <VoiceActivityDetection
            onAudioRecorded={handleAudioRecorded}
            onRecordingStatusChange={handleRecordingStatusChange}
          />
         
        </>
      )}
    </div>
    </div>
  );
};

export default GamePage;


// have to add the audio ater 1 sublevel of 0 level