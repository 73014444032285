import React, { useState, useEffect } from "react";

const SettingsPanel = ({ isOpen, onClose, volume, onVolumeChange }) => {
  const [localVolume, setLocalVolume] = useState(volume);

  useEffect(() => {
    setLocalVolume(volume);
  }, [volume]);

  const handleVolumeChange = (event) => {
    const newVolume = event.target.value;
    setLocalVolume(newVolume);
    onVolumeChange(newVolume);
  };

  return (
    <div
      className={`fixed bottom-24 right-0 bg-white p-6 rounded-t-lg shadow-lg transition-transform duration-300 ease-in-out ${
        isOpen ? "transform translate-x-0" : "transform translate-x-full"
      }`}
      style={{ width: "20rem" }}
    >
      <h2 className="text-2xl mb-4">Settings</h2>
      <div className="mb-4">
        <label htmlFor="volume" className="block text-lg">
          Volume
        </label>
        <input
          id="volume"
          type="range"
          min="0"
          max="100"
          value={localVolume}
          onChange={handleVolumeChange}
          className="w-full"
        />
      </div>
      <button
        onClick={onClose}
        className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg"
      >
        Close
      </button>
    </div>
  );
};

export default SettingsPanel;
