import React from "react";
import Footer from "./Footer";

const TermsAndCondition = () => {
  return (
    <div>
      <div className="flex flex-col px-48 mt-10">
        <div className="flex justify-between items-center">
          <h1 className="contact-head text-6xl">Terms & Conditions</h1>
        </div>
        <div className="w-full h-[1px] bg-neutral-400 mt-3 mb-10"></div>
      </div>

      <div className="whole px-48">
        <div className="first-info">
          <p className="text-xl mt-2">
            Please read these Terms and Conditions carefully before using the
            कोड.com, codewala.org website and कोड mobile app operated by
            Shuniyavigyan Pvt limited.
            <br />
            Your access to and use of the Service is conditioned on your
            acceptance of and compliance with these Terms. These Terms apply to
            all visitors, users, and others who access or use the Service.
          </p>
        </div>

        <div className="second-info mt-5">
          <h2 className="big-head text-3xl font-semibold">Accounts</h2>
          <p className="text-xl mt-3">
            When you create an account with us, you must provide us with
            accurate, complete, and up-to-date information. Failure to do so
            constitutes a breach of the Terms, which may result in immediate
            termination of your account on our Service.
            <br />
            <br />
            You are responsible for safeguarding the password that you use to
            access the Service and for any activities or actions under your
            password, whether your password is with our Service or a third-party
            service.
            <br />
            <br />
            You agree not to disclose your password to any third party. You must
            notify us immediately upon becoming aware of any breach of security
            or unauthorized use of your account.
          </p>
        </div>

        <div className="third-info mt-5">
          <h2 className="big-head text-3xl font-semibold">Content</h2>
          <p className="text-xl mt-3">
            Our Service allows you to post, link, store, share, and otherwise
            make available certain information, text, graphics, videos, or other
            material ("Content"). You are responsible for the Content that you
            post on or through the Service, including its legality, reliability,
            and appropriateness.
            <br />
            <br />
            By posting Content on or through the Service, you represent and
            warrant that: (i) the Content is yours (you own it) or you have the
            right to use it and grant us the rights and license as provided in
            these Terms, and (ii) the posting of your Content on or through the
            Service does not violate the privacy rights, publicity rights,
            copyrights, contract rights, or any other rights of any person.
            <br />
            We reserve the right to terminate the account of anyone found to be
            infringing on a copyright.
          </p>
        </div>

        <div className="fourth-info mt-5">
          <h2 className="big-head text-3xl font-semibold">
            Intellectual Property
          </h2>
          <p className="text-xl mt-3">
            The Service and its original content (excluding Content provided by
            users), features, and functionality are and will remain the
            exclusive property of [insert website/app name] and its licensors.
            <br />
            The Service is protected by copyright, trademark, and other laws of
            both the United States and foreign countries.
          </p>
        </div>

        <div className="fifth-info mt-5">
          <h2 className="big-head text-3xl font-semibold">Termination</h2>
          <p className="text-xl mt-3">
            We may terminate or suspend your account immediately, without prior
            notice or liability, for any reason whatsoever, including without
            limitation if you breach the Terms.
            <br />
            Upon termination, your right to use the Service will immediately
            cease. If you wish to terminate your account, you may simply
            discontinue using the Service.
          </p>
        </div>

        <div className="sixth-info mt-5 pb-44">
          <h2 className="big-head text-3xl font-semibold">Changes</h2>
          <p className="text-xl mt-3">
            We reserve the right, at our sole discretion, to modify or replace
            these Terms at any time. If a revision is material we will try to
            provide at least 30 days' notice prior to any new terms taking
            effect.
            <br />
            <br />
            By continuing to access or use our Service after those revisions
            become effective, you agree to be bound by the revised terms. If you
            do not agree to the new terms, please stop using the Service.
          </p>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default TermsAndCondition;
