import React from "react";
import Footer from "./Footer";

const PrivacyPolicy = () => {
  return (
    <div>
      <div className="flex flex-col px-48 mt-10">
        <div className="flex justify-between items-center">
          <h1 className="contact-head text-6xl">Privacy Policy</h1>
        </div>
        <div className="w-full h-[1px] bg-neutral-400 mt-3 mb-10"></div>
      </div>

      <div className="whole px-48">
        <div className="first-info">
          <h2 className="big-head text-3xl font-semibold">Last updated: April 8th ,2024</h2>
          <p className="text-xl mt-3">
            ShuniyaVigyan operates the कोड.com , codewala.org website and कोड
            mobile app. This page informs you of our policies regarding the
            collection, use, and disclosure of personal data when you use our
            website and the choices you have associated with that data. We use
            your data to provide and improve the website and services. By using
            the website, you agree to the collection and use of information in
            accordance with this policy.
          </p>
        </div>

        <div className="second-info mt-5">
          <h2 className="big-head text-3xl font-semibold">Information Collection and Use</h2>
          <p className="text-xl mt-3">
            We collect several different types of information for various
            purposes to provide and improve our service to you.
          </p>
        </div>

        <div className="third-info mt-5">
          <h2 className="big-head text-3xl font-semibold">Types of Data Collected</h2>
          <ul className="list-disc text-xl mt-3">
            <li>Personal Data</li>
            <li>Usage Data</li>
            <li>Cookies and Usage Data</li>
          </ul>
        </div>

        <div className="fourth-info mt-5">
          <h2 className="big-head text-3xl font-semibold">Use of Data</h2>
          <ul className="list-disc text-xl mt-3">
            <li>To Provide and maintain the service</li>
            <li>To notify you about changes to our service</li>
            <li>
              To allow you to participate in interactive features of our service
            </li>
            <li>To provide customer care and support</li>
            <li>
              To provide analysis or valuable information so that we can improve
              the service
            </li>
            <li>To monitor the usage of the service</li>
            <li>To detect, prevent and address technical issues</li>
          </ul>
        </div>

        <div className="fifth-info mt-5">
          <h2 className="big-head text-3xl font-semibold">Transfer of Data</h2>
          <p className="text-xl mt-3">
            Your information, including Personal Data, may be transferred to -
            and maintained on - computers located outside of your state,
            province, country, or other governmental jurisdiction where the data
            protection laws may differ from those of your jurisdiction.
          </p>
        </div>

        <div className="sixth-info mt-5">
          <h2 className="big-head text-3xl font-semibold">Disclosure of Data</h2>
          <ul className="text-xl mt-3 list-disc">
            <li>Comply with a legal obligation</li>
            <li>Protect and defend the rights or property of code.com</li>
            <li>
              Prevent or investigate possible wrongdoing in connection with the
              service
            </li>
            <li>
              Protect the personal safety of users of the service or the public
            </li>
            <li>Protect against legal liability</li>
          </ul>
        </div>

        <div className="seventh-info mt-5 mb-20">
          <h2 className="big-head text-3xl font-semibold">Security of Data</h2>
          <p className="text-xl mt-3">
            The security of your data is important to us, but remember that no
            method of transmission over the Internet or method of electronic
            storage is 100% secure. While we strive to use commercially
            acceptable means to protect your Personal Data, we cannot guarantee
            its absolute security. Links to Other Sites Our service may contain
            links to other sites that are not operated by us. If you click on a
            third-party link, you will be directed to that third party's site.
            We strongly advise you to review the Privacy Policy of every site
            you visit.
          </p>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
