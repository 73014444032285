import React from "react";
import Footer from "./Footer";
import aboutLogo from "./aboutuslogo.svg";
import aboutImg from "./aboutusImg.svg";
import { useTranslation } from 'react-i18next';

const AboutUs = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="flex flex-col px-48 mt-10">
        <div className="flex justify-between items-center">
          <h1 className="contact-head text-6xl">{t('about_us_page.title')}</h1>
        </div>
        <div className="flex flex-col justify-center items-center">
          <img src={aboutLogo} alt="about-logo" />
          <img src={aboutImg} alt="about-image" />
        </div>
      </div>

      <div className="whole px-48">
        <div className="first-info mb-72">
          <p className="text-2xl mt-20 text-justify">
          {t('about_us_page.des1')}
            <br />
            <br />
            {t('about_us_page.des2')}
            <br />
            <br />
            {t('about_us_page.des3')}
          </p>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default AboutUs;
