import React, { useState } from 'react';
import Editor from '@monaco-editor/react';

const styles = {
    wrapper: {
        position: 'relative',
    },
    dropdown: {
        position: 'absolute',
        top: '160%',
        right: 0,
        backgroundColor: '#0E0B33',
        borderRadius: '8px',
        boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
        width: '120px',
        zIndex: 2000,
    },
    closeIcon: {
        position: 'absolute',
        top: '5px',
        right: '5px',
        cursor: 'pointer',
    },
    optionsList: {
        listStyleType: 'none',
        padding: 0,
        margin: 0,
    },
    optionItem: (isSelected) => ({
        padding: '10px',
        cursor: 'pointer',
        backgroundColor: isSelected ? '#53517C' : '#0E0B33',
        color: isSelected ? 'white' : 'white',
    }),
    keyboard: {
        position: 'fixed',
        bottom: '0',
        right: '0',
        backgroundColor: '#0E0B33',
        border: '1px solid #CCC',
        padding: '15px',
        borderRadius: '8px 8px 0 0',
        boxShadow: '0 -2px 10px rgba(0,0,0,0.1)',
        zIndex: 3000,
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        width: '600px',
    },
    keyRow: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    },
    key: {
        padding: '10px 15px',
        display: 'inline-block',
        cursor: 'pointer',
        borderRadius: '4px',
        border: '1px solid #DDD',
        backgroundColor: '#FFF',
        color: 'black',
        fontSize: '16px',
        textAlign: 'center',
        width: 'auto',
        height: '40px',
        boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
    },
    closeKeyboardIcon: {
        position: 'absolute',
        top: '5px',
        left: '5px',
        cursor: 'pointer',
        zIndex: 3100,
    },
};

const KeyboardDropdown = ({ keyboardsvg, crossIcon, onTransliterateToggle }) => {
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);
    const [keyboardLayout, setKeyboardLayout] = useState('');
    const [isShiftActive, setIsShiftActive] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');

    const layouts = {
        english: {
            default: ['` 1 2 3 4 5 6 7 8 9 0', 'q w e r t y u i o p |', 'a s d f g h j k l : ;', 'z x c v b n m , . / ?', '{ = {shift} enter backspace " }'],
            shift: ['~ ! @ # $ % ^ & * + -', 'Q W E R T Y U I O P ?', 'A S D F G H J K L /', 'Z X C V B N M < >', '( , {shift} enter backspace . )'],
        },
        hindi: {
            default: ['\u0912 \u090E \u0945 \u094D\u0930 \u0930\u094D \u091C\u094D\u091E \u0924\u094D\u0930 \u0915\u094D\u0937 \u0936\u094D\u0930 \u0903 \u090B', '\u0914 \u0910 \u0906 \u0908 \u090A \u092B \u0919 \u0918 \u0927 \u091D \u0922', '\u0913 \u090F \u0905 \u0907 \u0909 \u0930 \u0916 \u0925 \u091B \u0920', '\u090E \u0901 \u0923 \u0928 \u0937 \u0938 \u0936 \u0964 \u092F \u091E', '( {shift} enter backspace )'],
            shift: ['\u0950 \u0964 \u0965 \u0970 \u0901 \u0902 \u0903 \u0904 \u0910 \u0911', '\u094C \u0948 \u093E \u0940 \u0942 \u092C \u0939 \u0917 \u0926 \u091C \u0921 \u093C', '\u094B \u0947 \u094D \u093F \u0941 \u092A \u0930 \u0915 \u0924 \u091A \u091F', '\u0902 \u0903 \u092E \u0928 \u0935 \u0932 \u0938 , . \u092F', '{shift} enter backspace'],
        },
    };

    const toggleKeyDropdown = () => {
        setIsDropdownVisible(prev => !prev);
    };

    const closeKeyDropdown = () => {
        setIsDropdownVisible(false);
    };

    const handleOptionClick = (option) => {
        if (selectedOption === option) {
            setIsDropdownVisible(false);
            setIsKeyboardVisible(false);
            setSelectedOption('');
            setKeyboardLayout('');
            if (option === 'transliterate') {
                onTransliterateToggle();
            }
        } else {
            if (option === 'english') {
                setKeyboardLayout('english');
                setIsKeyboardVisible(true);
                setSelectedOption('english');
                setIsDropdownVisible(false);
            } else if (option === 'hindi') {
                setKeyboardLayout('hindi');
                setIsKeyboardVisible(true);
                setSelectedOption('hindi');
                setIsDropdownVisible(false);
            } else if (option === 'transliterate') {
                onTransliterateToggle();
                setIsKeyboardVisible(false);
                setSelectedOption('transliterate');
                setIsDropdownVisible(false);
            }
        }
    };

    const closeKeyboard = () => {
        setIsKeyboardVisible(false);
        setKeyboardLayout('');
    };

    const handleKeyClick = (key) => {
        if (key === '{shift}') {
            setIsShiftActive(prev => !prev);
        } else {
            // Handle key press logic here
        }
    };

    const renderKeyRow = (keys) => (
        <div style={styles.keyRow}>
            {keys.split(' ').map(key => (
                <div key={key} style={styles.key} onClick={() => handleKeyClick(key)}>
                    {key}
                </div>
            ))}
        </div>
    );

    return (
        <div style={styles.wrapper}>
            <img
                src={keyboardsvg}
                alt="Keyboard"
                onClick={toggleKeyDropdown}
                style={{ width: 'auto', height: 'auto',cursor: 'pointer', marginBottom:"10px" }}
            />
            {isDropdownVisible && (
                <div style={styles.dropdown}>
                    <img
                        src={crossIcon}
                        alt="Close"
                        onClick={closeKeyDropdown}
                        style={styles.closeIcon}
                    />
                    <ul style={styles.optionsList}>
                        <li
                            style={styles.optionItem(selectedOption === 'english')}
                            onClick={() => handleOptionClick('english')}
                        >
                            English
                        </li>
                        <div style={{ height: '0.1px', backgroundColor: 'grey', marginLeft: '7px', marginRight: '7px' }}></div>
                        <li
                            style={styles.optionItem(selectedOption === 'transliterate')}
                            onClick={() => handleOptionClick('transliterate')}
                        >
                            Transliterate
                        </li>
                        <div style={{ height: '0.1px', backgroundColor: 'grey', marginLeft: '7px', marginRight: '7px' }}></div>
                        <li
                            style={styles.optionItem(selectedOption === 'hindi')}
                            onClick={() => handleOptionClick('hindi')}
                        >
                            Hindi
                        </li>
                    </ul>
                </div>
            )}

            {isKeyboardVisible && (
                <div style={styles.keyboard}>
                    <img
                        src={crossIcon}
                        alt="Close Keyboard"
                        onClick={closeKeyboard}
                        style={styles.closeKeyboardIcon}
                    />
                    {layouts[keyboardLayout][isShiftActive ? 'shift' : 'default'].map(row => renderKeyRow(row))}
                </div>
            )}
        </div>
    );
};

export default KeyboardDropdown;
