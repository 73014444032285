import React, { useState, useEffect, useRef } from 'react';
import './OpeningNarration.css';
import BackSvg from './codegame_assets/Bg.svg'; 
import sound from './codegame_assets/sound.svg';
import CrossSvg from './codegame_assets/cancelButton.svg'; 
import gif from './codegame_assets/OWL_Happy.gif';
import congobutton from './codegame_assets/congobutton.svg';
import congosvg from './codegame_assets/congosvg.svg';
import Confetti from 'react-confetti';
import Audiofile from './assets/audio/shabbhas.wav';

const Narrator = ({ onNext }) => {
  const [showContainer, setShowContainer] = useState(true);
  const [showConfetti, setShowConfetti] = useState(false);
  const audioRef = useRef(new Audio(Audiofile)); // Create audio reference

  useEffect(() => {
    if (showContainer) {
      // Show effects automatically when component mounts
      setShowConfetti(true);

      // Hide effects after 5 seconds
      const timer = setTimeout(() => {
        setShowConfetti(false);
      }, 5000); // 5000 milliseconds = 5 seconds

      // Play audio after 0.2 seconds
      const audioTimer = setTimeout(() => {
        audioRef.current.play().catch((error) => {
          console.error("Audio playback failed:", error);
        });
      }, 200); // 200 milliseconds = 0.2 seconds

      // Clean up timers on component unmount
      return () => {
        clearTimeout(timer);
        clearTimeout(audioTimer);
        audioRef.current.pause(); // Pause audio if component unmounts
      };
    }
  }, [showContainer]);

  const handleCloseClick = () => {
    setShowContainer(false);
  };

  return (
    <div className="narrator-container-wrapper">
      {showContainer && (
        <div className="narrator-container z-50">
          {showConfetti && <Confetti width={window.innerWidth} height={window.innerHeight} />}
          
          <div className="background-wrapper">
            <img src={BackSvg} alt="Background" className="background-svg" />
            <img 
              src={sound} 
              alt="sound" 
              className="icon-svg sound-icon" 
            />
            <img 
              src={CrossSvg} 
              alt="Close" 
              className="CloseSvg" 
              onClick={handleCloseClick} 
            />
            <div className="well-done">
              शाबाश बहुत खूब
            </div>
            <button 
              onClick={() => {
                onNext();
              }}
            >
              <img src={congobutton} alt="Congratulate Button" className='congobutton'/>
              <img src={congosvg} alt="Congratulate SVG" className="congosvg" />
            </button>
            <img 
              src={gif} 
              alt="Animated GIF" 
              className="gif-icon" 
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Narrator;
