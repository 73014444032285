import axios from 'axios';

const API = axios.create({
  baseURL: 'https://xn--11by0j.com/api/v1/',
  withCredentials: true, // Ensure cookies are sent with the request
});

export default API;

  


// export const excuteCode  = async (language, sourceCode) => {     
//     const response = await API.post("/execute" , {
//         "language": language,
//         "version": LANGUAGE_VERSIONS[language],
//         "files": [
//             {
//                 "content": sourceCode,
//             }
//         ],
//     },
//      {  cancelToken: source.token, }
    
//     );

//     return response.data;
// } 



  
  
// const Api = () => { 
//     useEffect(() => {
//             return () => {
//             // Cancel the request if the component unmounts
//             source.cancel('Operation canceled by the user.');   
//             };
//     }, []);

//     return(
//         <div>

//         </div>
//     )
// } 
  
  
