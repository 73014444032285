import React from 'react';
import { Box } from '@chakra-ui/react';
  
  
const EditorFooter = ({langpass}) => {
  return (
    <Box bg='#4631C5' w='auto%' h='23px' className='rounded-1 px-4 editorFooter'  color='white' right='0px' left='0px'>  
        {langpass}
    </Box>
  )
}
  
export default EditorFooter
  