import React, { useState } from 'react';
import { Box, Text, Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import outputsvg from './outputsvg.svg';
import threeDots from './threedots.svg';

const Output = ({ editorRef, language, handleButtonClick, themeColor, outputres, mode, editorHeight, setEditorHeight, fontSize, setFontSize }) => {
    const [isError, setIsError] = useState(false);
    const [dropdownVisible, setDropdownVisible] = useState(false); // State for dropdown visibility

    const textColor = isError
        ? 'red'
        : mode === true
        ? 'white'
        : 'black';

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible); // Toggle dropdown visibility
    };

    const increaseFontSize = () => {
        setFontSize(prevSize => prevSize + 2); // Increase font size
    };

    const decreaseFontSize = () => {
        setFontSize(prevSize => Math.max(8, prevSize - 2)); // Decrease font size with a minimum limit
    };

    return (
        <>
            <Box 
                bg='#1A183D'
                w='100%'
                position='relative' // Allows positioning child elements absolutely
            > 
              <div style={{ 
                  height: editorHeight, 
                  overflow: 'hidden', // Removes the scrollbar
                  position: 'relative' // Positioning context for the navbar
              }}>   
                 <Box 
                  position='absolute' 
                  top='0' 
                  left='0' 
                  right='0' 
                  marginTop="8px"
                  marginRight='8px'
                  height='30px' // Adjust height as needed
                  bg='#222045' // Navbar background color
                  color='white'
                  p={2} // Padding inside navbar
                  zIndex='1' // Ensure it sits above other content
                  display='flex' // Flex container to align items side by side
                  alignItems='center' // Vertically center items
                  borderRadius='10px 10px 0px 0px' // Top-left and top-right corners rounded
                  justifyContent='space-between' // Space between title and three-dots SVG
                >
                  <Box display='flex' alignItems='center'>
                    <img
                      src={outputsvg} 
                      alt='Navbar Icon' 
                      style={{ width: '25px', height: '25px', marginRight: '8px' }} 
                    />
                    <Text fontSize='lg' style={{ marginRight: '8px', marginTop: '13px' }}>Output</Text>
                  </Box>
                  <Menu isOpen={dropdownVisible} onClose={() => setDropdownVisible(false)}>
                    <MenuButton onClick={toggleDropdown}>
                      <img
                        src={threeDots} 
                        alt='Three Dots' 
                        style={{ width: '20px', height: '20px', cursor: 'pointer' }} 
                      />
                    </MenuButton>
                    <MenuList
                     style={{ 
                      width: 'auto', // Remove the fixed width to fit content
                      padding: '0', // Remove padding to prevent extra space
                      margin: '0' ,// Remove margin to prevent extra space
                      minWidth: '153px', // Set a minimum width if needed
                      boxSizing: 'border-box', 
                      height:'83px',
                      backgroundColor:'#1A183D',
                    }}
                    >
                      <div style={{ display: 'flex',justifyContent:'center', alignItems: 'center',fontSize: '16px', marginBottom: '10px', marginTop:'6px' }}>Font Size</div>
                      <div style={{ height: '0.1px', backgroundColor: 'grey', margin: '3px 7px' }}></div>
                      <Box style={{ display: 'flex',justifyContent:'center', alignItems: 'center'}}>
                      
                        <Box style={{  
                          display: 'flex', 
                          alignItems: 'center', 
                          borderRadius: '4px',  
                          cursor: 'pointer' 
                        }}>
                          <div 
                            onClick={decreaseFontSize} 
                            style={{ 
                              cursor: 'pointer', 
                              marginRight: '8px', 
                              fontSize: '20px',
                              width: '30px', // Increased width for better visibility
                              textAlign: 'center' 
                            }}
                          >
                            −
                          </div>
                          <div style={{ marginRight: '8px', marginLeft:'8px' }}>{fontSize}px</div>
                          <div 
                            onClick={increaseFontSize} 
                            style={{ 
                              cursor: 'pointer', 
                              fontSize: '20px', 
                              marginLeft: '8px',
                              width: '30px', // Increased width for better visibility
                              textAlign: 'center' 
                            }}
                          >
                            +
                          </div>
                        </Box>
                      </Box>
                    </MenuList>
                  </Menu>
                </Box>

                <Box 
                  height='35px'
                  marginTop="0px" // Adjust height as needed for spacing
                  bg='#1A183D' // Spacer background color
                  p={0} // No padding needed
                >
                </Box>

                <Box 
                  className='d-flex justify-content-between'  
                  height={`calc(${editorHeight} - 66px)`} // Adjust height for the content area
                  pt='50px' // Padding top to avoid overlap with the navbar
                  p={2} 
                  borderRadius='0px 0px 10px 10px'
                  marginTop="8px"
                  marginRight='8px'
                  border='1px solid' 
                  borderColor={isError ? 'red' : '#222045'} 
                  color={textColor}
                  background={mode !== false ? '#222045' : 'white'}
                  style={{
                    overflowY: 'auto', 
                    overflowX: 'hidden',
                    fontSize: `${fontSize}px`
                  }}
                > 
                <pre style={{ margin: 0, whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                  {outputres ? outputres : 'Click "Run" to see the output here'}                     
                </pre>                             
                </Box> 
              </div> 
            </Box>
          </> 
    )     
}      

export default Output;
