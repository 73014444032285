import React from 'react';
import { Link } from 'react-router-dom';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

const Modal = ({closeModal}) => {
  return (
    <div className='modalBg bg-neutral-700 bg-opacity-60 top-[2.8rem] min-h-[90vh] w-full relative z-50 flex justify-center items-center'>
      <div className='modal-container bg-white w-[30rem] py-10 px-20 rounded-lg'>
      <button onClick={() => closeModal(false)} className='ml-[22rem]'><CloseRoundedIcon/></button>
        <div className='title'>
            <h2 className='text-xl text-center mt-4'>"We're sorry, but booking a live class requires a laptop, desktop, or mobile device with a webcam and a good internet connection. Please arrange these, and then try booking your live class again. Thank you!"</h2>
        </div>
        <div className='footer flex justify-center items-center mt-10 bg-red-500 rounded-full py-3 mx-4'>
            <button><Link to="/" className='text-decoration-none text-white text-xl'>Go back to Homepage</Link></button>
        </div>
      </div>
    </div>
  )
}

export default Modal
