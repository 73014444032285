import React, { useState, useEffect } from 'react';
import { ReactComponent as Bookmark } from "./assets/icons/bookmark.svg";
import { ReactComponent as Lock } from "./assets/icons/lock.svg";
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';

const Prepare3 = () => {
  const { topicName } = useParams();
  const [isClicked, setIsClicked] = useState(false);
  const [problems, setProblems] = useState([]);

  const handleClick = () => {
    setIsClicked(!isClicked); // Toggle the clicked state
  };

  // Fetch data from API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://xn--11by0j.com/api/v1/problems_by_skill/8/1/");
        console.log(response.data);
        setProblems(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <div className='h-[75px] w-full border-3 px-36'>
        <p className='py-4 mx-30 font-semibold'>
          <Link to="/prepare"><span className='text-xl font-bold'>Prepare</span></Link> &gt; {topicName.charAt(0).toUpperCase() + topicName.slice(1)}
        </p>
      </div>

      <div className='flex'>
        <div className='w-1/5 border-r-4 h-full'>
          <div className='w-full h-1/5'>
            <div className='border-b-4 h-full ml-[140px] py-8 mt-4'>
              <h5 className='pb-2'>STATUS</h5>
              <div className='flex space-x-4'>
                <div className='h-5 w-5 bg-black rounded'></div>
                <p className='font-bold'>Solved</p>
              </div>
              <div className='flex space-x-4'>
                <div className='h-5 w-5 bg-gray-300 rounded'></div>
                <p className='font-bold'>Unsolved</p>
              </div>
            </div>
          </div>

          <div className='w-full h-1/5'>
            <div className='border-b-4 h-full ml-[140px] py-6'>
              <h5>SKILLS</h5>
              <div className='flex space-x-4 pt-2'>
                <div className='h-5 w-5 bg-black rounded'></div>
                <p className='font-bold'>Recursion(Basic)</p>
              </div>
              <div className='flex space-x-4'>
                <div className='h-5 w-5 bg-gray-300 rounded'></div>
                <p className='font-bold'>Recursion(Inter)</p>
              </div>
              <div className='flex space-x-4'>
                <div className='h-5 w-5 bg-gray-300 rounded'></div>
                <p className='font-bold'>Recursion(Adv)</p>
              </div>
            </div>
          </div>

          <div className='w-full h-1/5'>
            <div className='border-b-4 h-full ml-[140px] py-6'>
              <h5>DIFFICULTY LEVEL</h5>
              <div className='flex space-x-4 pt-2'>
                <div className='h-5 w-5 bg-black rounded'></div>
                <p className='font-bold'>Easy</p>
              </div>
              <div className='flex space-x-4'>
                <div className='h-5 w-5 bg-gray-300 rounded'></div>
                <p className='font-bold'>Medium</p>
              </div>
              <div className='flex space-x-4'>
                <div className='h-5 w-5 bg-gray-300 rounded'></div>
                <p className='font-bold'>Hard</p>
              </div>
            </div>
          </div>

          <div className='w-full h-2/5'>
            <div className='h-full ml-[140px] py-6'>
              <h5>GRADE LEVEL PROBLEMS</h5>
              <div className='flex space-x-4 pt-2'>
                <div className='h-5 w-5 bg-black rounded'></div>
                <p className='font-bold'>Class 6</p>
              </div>
              <div className='flex space-x-4'>
                <div className='h-5 w-5 bg-gray-300 rounded'></div>
                <p className='font-bold'>Class 7</p>
              </div>
              <div className='flex space-x-4'>
                <div className='h-5 w-5 bg-gray-300 rounded'></div>
                <p className='font-bold'>Class 8</p>
              </div>
              <div className='flex space-x-4'>
                <div className='h-5 w-5 bg-gray-300 rounded'></div>
                <p className='font-bold'>Class 9</p>
              </div>
              <div className='flex space-x-4'>
                <div className='h-5 w-5 bg-gray-300 rounded'></div>
                <p className='font-bold'>Class 10</p>
              </div>
              <div className='flex space-x-4'>
                <div className='h-5 w-5 bg-gray-300 rounded'></div>
                <p className='font-bold'>Class 11</p>
              </div>
              <div className='flex space-x-4'>
                <div className='h-5 w-5 bg-gray-300 rounded'></div>
                <p className='font-bold'>Class 12</p>
              </div>
            </div>
          </div>
        </div>

        <div className='w-4/5 h-full flex flex-col items-center space-y-8 my-10'>
          {problems.map((problem) => (
            <div key={problem.problem_detail.id} className='w-4/5 h-40 border-2 border-[#826DC7] rounded-2xl hover:bg-[#4B368F29] duration-300 hover:shadow-xl hover:cursor-pointer'>
              <div className='flex justify-end mt-3 mr-4'>
                <Bookmark onClick={handleClick} style={{ fill: isClicked ? 'black' : 'initial' }} />
              </div>
              <div className='flex'>
                <div className='w-full ml-10 space-y-3'>
                  <h2>{problem.problem_detail.title_en}</h2>
                  <p className='font-medium'>{`${problem.problem_detail.difficulty}, Max Score: ${problem.problem_detail.max_points}, Success Rate: ${(problem.user_score / problem.problem_detail.max_points * 100).toFixed(2)}%`}</p>
                </div>
                <div className='w-full flex justify-end items-center mr-16 space-x-8'>
                  {problem.solved ? (
                    <Link to={`/prepare4/${topicName}/${problem.problem_detail.title_en}`}>
                      <button className='px-5 py-3 bg-black text-white font-medium text-xl rounded-xl hover:bg-slate-200 duration-300'>
                      Solve Challenge
                    </button>

                    </Link>
                  ) : (
                    <>
                      <Lock />
                      <button className='px-5 py-3 text-white bg-[#848484] font-medium text-xl rounded-xl hover:bg-slate-200 duration-300'>
                        Solve Challenge
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Prepare3;
