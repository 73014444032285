import React, { useEffect, useState } from 'react';
import { Box, SimpleGrid, Text } from '@chakra-ui/react';
import API from './Api'; // Ensure the correct relative path
import Card from './CourseCard'; // Ensure the correct relative path
import CourseModal from "./CourseModal";
import Header from './Header';

const CoursesPage = () => {
  const [courses, setCourses] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await API.get('get_course_list/');
        setCourses(response.data.courses);
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    };

    fetchCourses();
  }, []);

  // Filter courses by level
  const level0Courses = courses.filter(course => course.difficulty === 'Beginner');

  return (
    <>
      <Box p={70} className='border-2 border-neutral-200 relative'>
      <Text fontSize='2xl' mb={4} className='text-3xl uppercase font-bold text-center -mt-10'>Our Courses</Text>
      <p fontSize='2xl' mb={4} className='mt-14 text-2xl font-semibold'>Courses for Kids Level 0</p>
      <div className='flex lg:w-[49vw] gap-20 justify-start items-center mt-10'>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={10} mb={8} className='w-[50%]'>
          {level0Courses.map(course => (
            <Card
              key={course.id}
              title={course.title}
              description={course.description}
              image={course.thumbnail_url}
              level={course.difficulty === 'Beginner' ? 0 : 1}
              duration={course.duration}
              setOpenModal={setOpenModal}
            />
          ))}
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={10} mb={8} style = {{display:'none'}}>
          {level0Courses.map(course => (
            <Card
              key={course.id}
              title={course.title}
              description={course.description}
              image={course.thumbnail_url}
              level={course.difficulty === 'Beginner' ? 0 : 1}
              duration={course.duration}
              setOpenModal={setOpenModal}
              
            />
          ))}
        </SimpleGrid>
      </div>

      {openModal && <CourseModal setOpenModal={setOpenModal} />}
    </Box>
    </>
  );
};

export default CoursesPage;
