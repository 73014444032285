import React, { useState, useEffect, useRef } from 'react';
import './OpeningNarration.css';
import BackSvg from './codegame_assets/Bg.svg'; 
import CrossSvg from './codegame_assets/cancelButton.svg';
import SvgInBox1 from './codegame_assets/box1_innersvg.svg';
import hint from './codegame_assets/hint.svg';
import SvgInBox2 from './codegame_assets/box2_innersvg.svg';
import SvgInBox3 from './codegame_assets/box3_innersvg.svg';
import gameStart from './codegame_assets/gameStartButton.svg';
import Audiofile from './assets/audio/chal button dabane se code hindi.wav'; // Ensure this path is correct
import Audiofile1 from './assets/audio/hint button hindi.wav';
import Audiofile2 from './assets/audio/yeh_lali_hai.wav';

const Narrator = ({ onNext }) => {
  const [showContainer, setShowContainer] = useState(true);
  const [showBox1, setShowBox1] = useState(false);
  const [showBox2, setShowBox2] = useState(false);
  const [showBox3, setShowBox3] = useState(false);
  const audioRef = useRef(null);
  const audioQueue = [Audiofile, Audiofile1, Audiofile2]; // Queue of audio files
  const [currentAudioIndex, setCurrentAudioIndex] = useState(0); // Track current audio index

  useEffect(() => {
    const timer = setTimeout(() => {
      if (audioRef.current) {
        audioRef.current.play().catch((error) => {
          console.error("Audio playback failed:", error);
        });
      }
    }, 500); // Play first audio after 0.5 seconds

    return () => clearTimeout(timer); // Cleanup timer on component unmount
  }, []);

  const handleCloseClick = () => {
    setShowContainer(false);
  };

  const handleBox1Click = () => {
    setShowBox1(true);
  };

  const handleBox2Click = () => {
    setShowBox2(true);
  };

  const handleBox3Click = () => {
    setShowBox3(true);
  };

  const handleAudioEnded = () => {
    // Move to the next audio in the queue
    const nextAudioIndex = currentAudioIndex + 1;
    if (nextAudioIndex < audioQueue.length) {
      setCurrentAudioIndex(nextAudioIndex);
      setTimeout(() => {
        if (audioRef.current) {
          audioRef.current.src = audioQueue[nextAudioIndex];
          audioRef.current.play().catch((error) => {
            console.error("Audio playback failed:", error);
          });
        }
      }, 500); // Delay before playing the next audio
    }
  };

  return (
    <div>
      {showContainer && (
        <div className="narrator-container z-50">
          <div className="background-wrapper">
            <img src={BackSvg} alt="Background" className="background-svg" />
            
            {/* Heading Text */}
            <div className="heading-textbox">यह भी जानो </div>
            
            {/* Close Button */}
            <img 
              src={CrossSvg} 
              alt="Close" 
              className="CloseSvg" 
              onClick={handleCloseClick} 
            />
            
            {/* Game Start Image */}
            <img 
              src={gameStart} 
              alt="Game Start" 
              className="game-start" 
              onClick={onNext}
            />
            
            {/* Box1 */}
            <div className="Box1" onClick={handleBox1Click}>
              <img src={SvgInBox1} alt="Inner SVG in Box1" className="box1svg" />
              <div className='Box1text'>
                चल बटन दबाने से ,कोड कम करने लगेगा। चल बटन दबाओ और देखो किस तरह प्रोग्राम काम करता है
              </div>
            </div>
            
            {/* Let's Play Text */}
            <div className="Lets-play">चलो खेल <br /><br /> <span>शुरू करते हैं</span></div>
            
            {/* Box2 */}
            <div className="Box2" onClick={handleBox2Click}>
              <img src={hint} alt="Hint SVG" className="hint" />
              <img src={SvgInBox2} alt="Inner SVG in Box2" className="box2svg" />
              <div className='Box1text'>
                जानकारी बटन आपको किसी चीज़ के बारे में महत्वपूर्ण जानकारी या तथ्य देगा। इसे टैप करें और अधिक जानें!
              </div>
            </div>
            
            {/* Box3 */}
            <div className="Box3" onClick={handleBox3Click}>
              <img src={SvgInBox3} alt="Inner SVG in Box3" className="box3svg" />
              <div className='Box1text'>
                यह AI लाली है  यह तुम से बात करेगी । पूछो और जानो।
              </div>
            </div>
            
            {/* Box1 Content */}
            {showBox1 && (
              <div className="box-content">
                <h3>Box 1 Content</h3>
                <p>This is the content for Box 1.</p>
              </div>
            )}
            
            {/* Box2 Content */}
            {showBox2 && (
              <div className="box-content">
                <h3>Box 2 Content</h3>
                <p>This is the content for Box 2.</p>
              </div>
            )}
            
            {/* Box3 Content */}
            {showBox3 && (
              <div className="box-content">
                <h3>Box 3 Content</h3>
                <p>This is the content for Box 3.</p>
              </div>
            )}
          </div>
          {/* Audio Element */}
          <audio 
            ref={audioRef} 
            src={audioQueue[currentAudioIndex]} 
            preload="auto" 
            onEnded={handleAudioEnded} 
          />
        </div>
      )}
    </div>
  );
};

export default Narrator;