import React, { useState, useEffect, useRef } from 'react';
import './OpeningNarration.css';
import BackSvg from './codegame_assets/Bg.svg'; // Import the background image
import sound from './codegame_assets/sound.svg';
import CrossSvg from './codegame_assets/cancelButton.svg'; // Import the cross icon
import youtube from './codegame_assets/Youtube.svg';
import gif from './codegame_assets/OWL_Happy.gif';
import ok from './codegame_assets/ok.svg';
import Audiofile from './assets/audio/aapki pehli chunauti.wav';
import Audiofile1 from './assets/audio/chal button dabane se code hindi.wav';

const Narrator = ({ onNext }) => {
  const [showContainer, setShowContainer] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false); // State to track audio playback
  const audioRef = useRef(new Audio(Audiofile)); // Create audio reference for the first file
  const audioRef1 = useRef(new Audio(Audiofile1)); // Create audio reference for the second file

  useEffect(() => {
    const playAudio = async () => {
      try {
        await audioRef.current.play();
        setIsPlaying(true); // Set playing state to true

        audioRef.current.onended = () => {
          setTimeout(() => {
            audioRef1.current.play().catch((error) => {
              console.error("Audio playback failed:", error);
            });
            setIsPlaying(true); // Set playing state to true for the second audio
          }, 500); // 0.5 second delay
        };
      } catch (error) {
        console.error("Audio playback failed:", error);
      }
    };

    playAudio();

    return () => {
      audioRef.current.pause(); // Pause audio if component unmounts
      audioRef1.current.pause(); // Pause second audio if component unmounts
    };
  }, []);

  const handleCloseClick = () => {
    setShowContainer(false);
    audioRef.current.pause(); // Pause audio when closing the container
    audioRef1.current.pause(); // Pause second audio when closing the container
  };

  const handleSoundClick = () => {
    if (isPlaying) {
      audioRef.current.pause(); // Pause audio if it's playing
      audioRef1.current.pause(); // Pause second audio if it's playing
    } else {
      audioRef.current.play().catch((error) => {
        console.error("Audio playback failed:", error);
      }); // Play audio if it's paused
      setIsPlaying(true);
    }
    setIsPlaying(!isPlaying); // Toggle playing state
  };

  return (
    <div>
      {showContainer && (
        <div className="narrator-container z-50">
          <div className="background-wrapper">
            <img src={BackSvg} alt="Background" className="background-svg" />
            <div className="heading-textbox">आपकी पहेली चुनौती</div>
            <img 
              src={sound} 
              alt="sound" 
              className="icon-svg sound-icon" 
              onClick={handleSoundClick} // Add click handler for sound icon
            />
            <img 
              src={CrossSvg} 
              alt="Close" 
              className="CloseSvg" 
              onClick={handleCloseClick} 
            />
            <div className="mid-Content">
              चल बटन दबाओ और जादू देखो
            </div>
            <a href="https://www.youtube.com" rel="youtube_img">
              <div className="link-youtube">
                <img
                  src={youtube}
                  alt="youtube"
                  cursor="pointer"
                  className='icon'
                />
                इसे और अच्छे से समझने के लिए आप यह देखो             
              </div>
            </a>
            <img 
              src={gif} 
              alt="Animated GIF" 
              className="gif-icon" 
            />
            <button className="ok" onClick={onNext}>
              <img src={ok} alt="OK Button" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Narrator;
