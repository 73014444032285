import React, { useState, useEffect } from "react";
import Footer from "./Footer";
import vector from "./download_page_assets/Downloads-vector.svg";
import threeD from "./download_page_assets/3D-image.svg";
import windowslogo from "./download_page_assets/windows-logo.svg";
import maclogo from "./download_page_assets/MAC-OS-logo.svg";
import linuxlogo from "./download_page_assets/Linux.svg";
import downloadIcon from "./download_page_assets/Download-logo.svg";
import "./download.css";
import photo1 from "./download_page_assets/Img-1.png";
import photo2 from "./download_page_assets/Img-2.png";
import { useTranslation } from 'react-i18next';

const Download = () => {
  const [downloadCount, setDownloadCount] = useState(null);
  const { t } = useTranslation();
  useEffect(() => {
    // Fetch download count from the API
    const fetchDownloadCount = async () => {
      try {
        const response = await fetch("https://xn--11by0j.com/api/v1/user_registered_count/");
        const data = await response.json();
        setDownloadCount(data.user_registered_count); // Assuming the API returns { count: number }
      } catch (error) {
        console.error("Error fetching download count:", error);
      }
    };

    fetchDownloadCount();
  }, []);

  return (
    <>
      <div className="hero-downloads">
        {/* Hero Section */}
        <section className="hero">
          <div className="hero-sub-content">
            <div className="hero-content">
              <h1>{t('downloads.title')}</h1>
            </div>

            {/* Downloads Info */}
            <div className="downloads-info">
              <div className="avatars">
                <img src={photo1} alt="Avatar 1" />
                <img src={photo1} alt="Avatar 2" />
                <img src={photo1} alt="Avatar 3" />
                <img src={photo2} alt="Avatar 4" />
              </div>
              <p>{downloadCount !== null ? `${downloadCount}+` : "Loading..."} {t('downloads.download')}</p>
            </div>
          </div>

          {/* Stacked Game Preview Images */}
          <div className="hero-images">
            <img className="image-1" src={vector} alt="Game Preview 1" />
            <img className="image-2" src={threeD} alt="Game Preview 2" />
          </div>
        </section>

        {/* Downloads Section */}
        <section className="downloads">
          {/* Windows Download */}
          <div className="download-option">
            <div className="download-option-content">
              <img src={windowslogo} alt="Windows" />
              <div className="download-sub-content">
                <p>{t('downloads.window_des')}</p>
                <p className="device">{t('downloads.windows')} 10.11</p>
              </div>
            </div>
            <a href="https://v4go4czkdrl5yvhdsa.blob.core.windows.net/ov-data/test/godot.windows.editor.x86_64.exe" download="godot.linuxbsd.editor.x86_64.exe">
              <button>
              {t('downloads.d')}
                <img
                  src={downloadIcon}
                  alt="Download"
                  style={{ marginLeft: "8px", verticalAlign: "middle" }}
                />
              </button>
            </a>
          </div>

          {/* Mac Download */}
          <div className="download-option">
            <div className="download-option-content">
              <img src={maclogo} alt="Mac" />
              <div className="download-sub-content">
                <p>{t('downloads.mac_des')}</p>
                <p className="device">{t('downloads.mac')}</p>
              </div>
            </div>
            <a href="https://v4go4czkdrl5yvhdsa.blob.core.windows.net/ov-data/test/godot.dmg" download="godot.linuxbsd.editor.x86_64.dmg" className="no-underline">
              <button>
              {t('downloads.d')}
                <img
                  src={downloadIcon}
                  alt="Download"
                  style={{ marginLeft: "8px", verticalAlign: "middle" }}
                />
              </button>
            </a>
          </div>

          {/* Linux Download */}
          <div className="download-option">
            <div className="download-option-content">
              <img src={linuxlogo} alt="Linux" />
              <div className="download-sub-content">
                <p>{t('downloads.linux_des')}</p>
                <p className="device">{t('downloads.linux')}</p>
              </div>
            </div>
            <a href="https://v4go4czkdrl5yvhdsa.blob.core.windows.net/ov-data/test/godot.linuxbsd.editor.x86_64" download="godot.linuxbsd.editor.x86_64.tar.gz">
              <button>
              {t('downloads.d')}
                <img
                  src={downloadIcon}
                  alt="Download"
                  style={{ marginLeft: "8px", verticalAlign: "middle" }}
                />
              </button>
            </a>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default Download;
