

export const LANGUAGE_VERSIONS = {
        javascript : "18.15.0",
        python : "3.10.0",
        java : "15.0.2",
        php : "8.2.3"
}; 
   
   
   
export const CODE_SNIPPETS = {
        javascript : `\n function greet(name) {\n\tconsole.log("Hello ," + name + "!"); \n}\n\ngreet("Alex"); \n`,   
        python : `\ndef greet(name): \n\t print("hello ," + name + "!")\n\ngreet("Alex")\n`,
        java : `\n public class HelloWorld {\n\t public static void main(String[] args){\n\t\t System.out.println("hello World"); \n\t}\n }\n `,
        php : "<?php \n\n $name = 'Alex';\n echo $name;\n\n?>",

};  
 