// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import translation files
import translationEn from './Translation/en/translation.json';
import translationHn from './Translation/hn/translation.json';

// The translations
const resources = {
  en: {
    translation: translationEn,
  },
  hn: {
    translation: translationHn,
  },
};

i18n
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    resources,
    lng: 'hn', // Default language
    fallbackLng: 'hn', // Fallback language if the selected language is not available
    interpolation: {
      escapeValue: false, // React already handles escaping
    },
  });

export default i18n;
