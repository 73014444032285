import React ,{useState} from 'react';
import {Box ,Text ,Button,Image,Stack} from "@chakra-ui/react";  
import Compress from './Compress.svg';   
import Enlarge from './Enlarge.svg';   
import light from './darktheme.svg';   
import dark from './theme.svg';  

const EditorButton = ({editorRef,language,editorHeight,setEditorHeight,handleButtonClick,themeColor,mode}) => {    
    const [isLoading,setisloading] = useState(false);   
    const [isvertical ,setVertical] = useState(true);

    const hideShowNav = (key) =>{                   
        const navbar = document.querySelector('#root > nav');        
        if(key===true ){
          navbar.classList.add('collapse');
          if(editorHeight === '520px')
          {
            setEditorHeight('604px');
          }
  
        }
        else{
          navbar.classList.remove('collapse');
          if(editorHeight === '604px')
            {
              setEditorHeight('520px');  
            }
        }
  
      }
  
  
    const setAlign  = () => {         
    setVertical(!isvertical);
    }
  
  
    const updown = (isvertical) =>{     

    const row = document.querySelector('.css-0');
    const cols = document.querySelectorAll('.css-11ze7cv'); 

    if(isvertical === true){
        row.classList.add('row');
        cols.forEach(col => {
        col.classList.add('w-100');
        });
    }
    else{
        row.classList.remove('row');
        cols.forEach(col => {
        col.classList.remove('w-100');
        });
    }

    }
     
   
  
  return (
    
  <Stack direction='row' className='justify-content-end' mb={4}>
{/* <div className='mx-1 pt-2' onClick={themeColor} style={{marginTop:'7px', cursor: 'pointer'}}>
  {
    (mode !== false) ? (
      <img src={light} alt='light'/>       
    ) :
    (
      <img src={dark} alt='dark'/>       
    )

  }
      
  </div>

  <div  className='mx-1 pt-2' color='white' bg="#4631C5" onClick={() => hideShowNav(false)} style={{marginTop:'10px', cursor: 'pointer'}} >   
      <Image src={Compress}  alt='Compress'/> 
  </div>  
  <div  className='mx-1 pt-2' color='white' bg="#4631C5" onClick={() => hideShowNav(true)} style={{marginTop:'10px', cursor: "pointer"}} >   
      <Image src={Enlarge}  alt='Enlarge'/>
  </div>  */}
  </Stack> 

  )
}

export default EditorButton


