import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { RouterProvider } from "react-router-dom";
import {appRouter} from './App';
import { AuthProvider } from './components/AuthContext';
import './i18n';



const root = ReactDOM.createRoot(document.getElementById('root'));        
root.render(

  
    <AuthProvider >
      <RouterProvider router={appRouter} />
    </AuthProvider>

);  
    
    
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))           
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals();           
                