

import React, { useEffect, useState } from 'react';
import axios from 'axios';

// SVG Icons
import FolderIcon from './folder.svg';  
import PythonIcon from './pythonsvg.svg';  
import ThreeDotsIcon from './threedots.svg';
import rename from './rename.svg';
import deletesvg from './delete.svg';

// Utility function to convert flat directory list into a tree structure
const buildTree = (directories) => {
  const tree = {};
  directories.forEach(path => {
    const parts = path.split('/');
    let currentLevel = tree;
    parts.forEach(part => {
      if (!currentLevel[part]) {
        currentLevel[part] = {};
      }
      currentLevel = currentLevel[part];
    });
  });
  return tree;
};

// Recursive function to render the tree with toggle functionality
const renderTree = (node, expandedNodes, toggleNode, path, dropdownStates, toggleDropdown, handleDelete) => {
  return (
    <ul style={styles.tree}>
      {Object.keys(node).map(key => {
        const isDirectory = Object.keys(node[key]).length > 0;
        const isPythonFile = key.endsWith('.py');
        const fullPath = path ? `${path}/${key}` : key;
        const isDropdownOpen = dropdownStates[fullPath] || false;

        const handleDropdownClick = (event) => {
          event.stopPropagation();
          toggleDropdown(fullPath);
        };

        const handleFolderClick = (event) => {
          if (isDirectory) {
            event.stopPropagation();
            toggleNode(fullPath);
          }
        };

        const handleDeleteClick = async (event) => {
          event.stopPropagation();
          const confirmed = window.confirm(`Are you sure you want to delete ${fullPath}?`);
          if (confirmed) {
            await handleDelete(fullPath);
          }
        };

        return (
          <li key={fullPath} style={styles.treeItem}>
            <div style={styles.treeItemHeader}>
              <div style={styles.iconContainer}>
                {isDirectory && <img src={FolderIcon} alt="Folder" style={styles.icon} />}
                {isPythonFile && <img src={PythonIcon} alt="Python" style={styles.icon} />}
              </div>
              <span
                style={styles.fileName}
                onClick={handleFolderClick}
              >
                {key}
              </span>
              <div style={styles.dropdownContainer}>
                <img
                  src={ThreeDotsIcon}
                  alt="Options"
                  style={styles.threeDotsIcon}
                  onClick={handleDropdownClick}
                />
                {isDropdownOpen && (
                  <div style={styles.dropdownMenu}>
                    <div style={{ ...styles.dropdownOption, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <span>Rename</span>
                      <img src={rename} alt="Rename" />
                    </div>

                    <div style={{ height: '0.1px', backgroundColor: 'grey', margin: '3px 7px' }}></div>
                    
                    <div
                      style={{ ...styles.dropdownOption, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                      onClick={handleDeleteClick}
                    >
                      <span>Delete</span>
                      <img src={deletesvg} alt="Delete" />
                    </div>

                  </div>
                )}
              </div>
            </div>
            {expandedNodes[fullPath] && isDirectory && (
              <div style={styles.treeChild}>
                {renderTree(node[key], expandedNodes, toggleNode, fullPath, dropdownStates, toggleDropdown, handleDelete)}
              </div>
            )}
          </li>
        );
      })}
    </ul>
  );
};

const styles = {
  tree: {
    listStyleType: 'none',
    paddingLeft: '0px',
  },
  treeItem: {
    margin: '5px 0',
    backgroundColor: '#0E0B33',
    borderRadius: '4px',
    color: '#ffffff',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    paddingLeft: '0px',
    position: 'relative', // Ensure dropdown is positioned correctly
  },
  treeItemHeader: {
    cursor: 'pointer',
    padding: '5px',
    paddingLeft: '0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  treeChild: {
    paddingLeft: '20px',
    borderLeft: '0.5px solid #ffffff', // Vertical line for child elements
    marginLeft: '10px', // Adjust margin to avoid overlap
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '8px', // Space between icon and text
  },
  fileName: {
    fontSize: '16px', // Adjust the font size here
    marginRight: '8px',
  },
  threeDotsIcon: {
    width: '16px',
    height: '16px',
    cursor: 'pointer',
  },
  dropdownContainer: {
    position: 'relative', // Ensure dropdown is positioned correctly
  },
  dropdownMenu: {
    position: 'absolute',
    top: '100%',
    right: '0',
    backgroundColor: '#1A183D',
    borderRadius: '8px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
    zIndex: '1',
    width: '120px',
    marginTop: '5px',
  },
  dropdownOption: {
    padding: '8px',
    color: '#ffffff',
    cursor: 'pointer',
  },
};

const DataFetcher = () => {
  const [treeData, setTreeData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedNodes, setExpandedNodes] = useState({});
  const [dropdownStates, setDropdownStates] = useState({});

  useEffect(() => {
    const fetchTreeData = async () => {
      try {
        const response = await axios.get('https://कोड.com/api/v1/editor/list_user_directories/?user_id=55');
        console.log('API Response:', response.data);
        const directories = response.data.directories || [];
        const tree = buildTree(directories);
        setTreeData(tree);
      } catch (error) {
        console.error('Error fetching tree data:', error);
        setError(`Error fetching tree data: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchTreeData();
  }, []);

  const toggleNode = (node) => {
    setExpandedNodes(prevExpandedNodes => ({
      ...prevExpandedNodes,
      [node]: !prevExpandedNodes[node]
    }));
  };

  const toggleDropdown = (node) => {
    setDropdownStates({
      [node]: !dropdownStates[node]
    });
  };

  const handleDelete = async (path) => {  
    try {
      const response = await axios.post('https://कोड.com/api/v1/editor/manage_files/', {
        user_id: 57,  // Make sure to use dynamic user_id as per your application context
        path: path,
        action: 'delete',
      });
    
      console.log('API Response:', response); // Debugging: log API response
    
      if (response.status === 200) {
        alert(`${path} has been successfully deleted.`);
        // Refetch or update the tree data after deletion
        const updatedTreeData = { ...treeData };
        const parts = path.split('/');
        let currentLevel = updatedTreeData;
        for (let i = 0; i < parts.length - 1; i++) {
          currentLevel = currentLevel[parts[i]];
        }
        delete currentLevel[parts[parts.length - 1]];
        setTreeData(updatedTreeData);
      } else {
        alert(`Failed to delete ${path}. Status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error deleting file or folder:', error.response || error);
      alert(`Error deleting ${path}: ${error.response ? error.response.data.message : error.message}`);
    }
  };
  
  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div style={{ paddingLeft: '10px', color: 'white' }}>
      {Object.keys(treeData).length > 0 ? (
        renderTree(treeData, expandedNodes, toggleNode, '', dropdownStates, toggleDropdown, handleDelete)
      ) : (
        <p>No data available.</p>
      )}
    </div>
  );
};

export default DataFetcher;
