import React from 'react';
import { Box, Image, Text, Badge } from '@chakra-ui/react';
import story from "./story.jpg";
import Play from '../images/Play.png';
import { Link } from 'react-router-dom';

const CourseCard = ({ title, description, image, level, duration, setOpenModal }) => {
  const maxTitleLength = 20;
  const truncatedTitle = title.substring(26,53)
  return (
    <div className='flex justify-center items-center p-4'>
    <div className="course-card px-2 pt-2 rounded-3xl border-2 border-neutral-300 h-full w-full max-w-md md:max-w-lg lg:max-w-xl xl:max-w-2xl">
      <div className="course-card-content relative">
        <img
          src={story}
          alt={title}
          className='w-full h-56 md:h-72 lg:h-80 object-cover rounded-3xl ring-3 ring-white cursor-pointer hover:scale-105 transition duration-500'
        />
        <div className='flex justify-center items-center'>
          <Link to="/gamepage">
            <button
              className='absolute text-2xl md:text-3xl lg:text-4xl bg-[#6973f6] right-10 md:right-20 lg:right-36 bottom-24 md:bottom-40 lg:bottom-52 flex z-50 text-white font-semibold px-4 py-2 md:px-6 md:py-3 border-5 rounded-3xl hover:bg-[#4e4ef6] hover:scale-105 transition duration-500'
              onClick={() => setOpenModal(true)}
            >
              <img src={Play} alt='Play' className='mr-2 h-[2rem] md:h-[2.5rem] lg:h-[3rem]' />
              Play
            </button>
          </Link>
        </div>
        <div className='font-bold text-xl md:text-2xl lg:text-3xl mt-6 md:mt-8 lg:mt-10 ml-4'>{truncatedTitle}</div>
        <p className='text-md md:text-lg lg:text-xl py-2 md:py-3'>{description}</p>
        <div className="course-card-meta flex justify-between items-center mt-4 px-4">
          <div className="course-card-level">
            <span className='text-sm md:text-base lg:text-lg bg-orange-200 text-orange-800 px-2 py-1 rounded-full'>
              Level {level}
            </span>
          </div>
          <div className="course-card-duration text-sm md:text-base lg:text-lg">
            {duration}
          </div>
        </div>
      </div>
    </div>
  </div>
  
  );
};

export default CourseCard;