import React, { useState, useEffect } from "react";
import { ReactComponent as Python } from "./assets/icons/python.svg";
import { ReactComponent as Backtracking } from "./assets/icons/backtracking.svg";
import { ReactComponent as Bit } from "./assets/icons/bit-manipulation.svg";
import { ReactComponent as Recursion } from "./assets/icons/recursion.svg";
import { ReactComponent as SQL } from "./assets/icons/sql.svg";
import { ReactComponent as Star} from "./assets/icons/star.svg"
import axios from 'axios';
import { Link } from 'react-router-dom';
import Header from "./Header";

const Prepare = () => {
  const [skills, setSkills] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showCards, setShowCards] = useState(false);

  useEffect(() => {
    const fetchSkills = async () => {
      try {
        const response = await axios.get('https://xn--11by0j.com/api/v1/get_user_scores/2/', {
          mode: 'no-cors'
        });
        setSkills(response.data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchSkills();
  }, []);


  const handleTopicClick = () => {
    setShowCards(true);
  };

  const renderStars = (userScore, maxPoints) => {
    const maxStars = 5;
    const scorePerStar = maxPoints / maxStars;
    const filledStars = Math.floor(userScore / scorePerStar);
  
    return (
      <div className="w-3/5 flex justify-between">
        {[...Array(maxStars)].map((_, index) => (
          <Star key={index} className={index < filledStars ? "fill-current text-purple-800" : "fill-current text-gray-300"} />
        ))}
      </div>
    );
  };
 
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error fetching skills: {error.message}</p>;

  
  return (
    <>
      <section className="">
        {/* top section of the page */}
        <div className="h-full border-b-4">
          <div className=" flex flex-col justify-evenly px-36 ">
            <h5 className=" pt-2">Prepare</h5>
            <h2 className=" pb-2">Learn Programming Languages</h2>
          </div>
        </div>

        {/* course preparation section of the page */}
        <div className="px-36 mt-10 max-h-full ">
        <div className="  border-2 border-[#BDB2DF] rounded-2xl flex flex-col justify-evenly py-7 space-y-4">
          <h4 className="ml-[30px]">Start Your Preparation</h4>
          <h4 className="text-gray-300 ml-[30px] py-4">Add your Skill</h4>

          {showCards && (
         
         <div className="ml-[30px] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
           {skills.map(skill => (
             <div key={skill.id} className="w-[360px] h-full border-3 rounded-xl ">
               <div className="w-full flex justify-between mx-7 my-3">
                 <div className="pr-16 font-bold flex justify-start items-center text-xl w-1/2">{skill.name.charAt(0).toUpperCase()+skill.name.slice(1)}</div>
                 <div className="w-1/2">{renderStars(skill.user_score, skill.skill_max_points)}</div>
               </div>
               <div className="max-w-full mx-7 mt-2 space-y-3">
                 <p>{skill.skill_max_points - skill.user_score} more points to get your first star!</p>
                 <div className="w-full h-[12px] border-1 rounded-full bg-blue-200">
                 <div 
                    className='h-full bg-gradient-to-r from-[#8446D2] to-[#212173] rounded-full' 
                    style={{ width: `${((skill.user_score/skill.skill_max_points)*100)}%` }}
                  ></div>

                 </div>
               </div>
               <Link to={`/prepare3/${skill.name}`}>
                  <button className="bg-purple-700 rounded-xl text-white py-3 px-4 my-4 mx-7 font-bold hover:bg-purple-500 duration-300">
                    Continue Preparation
                  </button>
                </Link>

             </div>
           ))}
         </div>
        )}

          <h4 className="ml-[30px]  py-6">Select By Topics</h4>
          <div className="flex space-x-20 ml-[30px]">
            <div onClick={handleTopicClick} className="h-[148px] w-[182px] rounded-xl border-1 border-purple-400 bg-purple-100 hover:bg-purple-100 duration-300 flex flex-col justify-evenly items-center">
              <Recursion className="text-[#4B368F]" />
              <p className="text-[#1B2845] text-xl font-bold">Recursion</p>
            </div>
            <div onClick={handleTopicClick} className="h-[148px] w-[182px] rounded-xl border-1 border-purple-400 bg-purple-50 hover:bg-purple-100 duration-300 flex flex-col justify-evenly items-center">
              <Backtracking className="text-[#4B368F]" />
              <p className="text-[#1B2845] text-xl font-bold">Backtracking</p>
            </div>
            <div onClick={handleTopicClick} className="h-[148px] w-[182px] rounded-xl border-1 border-purple-400 bg-purple-50 hover:bg-purple-100 duration-300 flex flex-col justify-evenly items-center">
              <Python className="text-[#4B368F]" />
              <p className="text-[#1B2845] text-xl font-bold">Python</p>
            </div>
            <div onClick={handleTopicClick} className="h-[148px] w-[182px] rounded-xl border-1 border-purple-400 bg-purple-50 hover:bg-purple-100 duration-300 flex flex-col justify-evenly items-center">
              <SQL className="text-[#4B368F]" />
              <p className="text-[#1B2845] text-xl font-bold">SQL</p>
            </div>
            <div onClick={handleTopicClick} className="h-[148px] w-[182px] rounded-xl border-1 border-purple-400 bg-purple-50 hover:bg-purple-100 duration-300 flex flex-col justify-evenly items-center">
              <Bit className="text-[#4B368F]" />
              <p className="text-[#1B2845] text-xl font-bold">
                <span className="ml-14">Bit</span> <br /> Manipulation
              </p>
            </div>
          </div>
        </div>
        </div>

        
        
      </section>



    </>
  );
};

export default Prepare;
