import React, { useState } from 'react'
import { Link } from "react-router-dom"
import ProfileLogo from "./assets/images/profile_transaction.png"
import TransactionLogo from "./assets/images/transaction.png"
import Transaction from './Transaction.js'

const Profile = () => {
  const [showTransaction,setShowTreansaction] = useState(false);

  const toggleTransaction = () =>{
    setShowTreansaction(!showTransaction)
  }

  return (
    <div>
       <div className='w-full h-full'>
      <div className='flex'>
        <div className='w-[18%] h-[100vh] border-r-2 border-gray-300 flex flex-col justify-start items-start'>

        <div className='text-3xl font-bold p-4 border-b-2 border-gray-300 w-full'>Account Settings</div>
        <div className='w-full'>
            
          <div className='flex justify-start gap-3 pt-3 pl-6 border-b-2 border-gray-300 hover:bg-purple-400 duration-500 cursor-pointer'>
              <img src={ProfileLogo} alt="" className='w-5 h-5'/>
              <p className='text-xl font-medium '>Edit Profile</p>
            </div>
            
          </div>
          <div className='w-full'>
            <div className='flex justify-start gap-3 pt-3 pl-6 border-b-2 border-gray-300 hover:bg-purple-400 duration-500 cursor-pointer' onClick={toggleTransaction}>
              <img src={TransactionLogo} alt="" className='w-5 h-5'/>
              <p className='text-xl font-medium'>Show Transaction History</p>
            </div>
          </div>

        </div>

        <div className='w-[82%]'>
          {showTransaction && <Transaction />
          }
        </div>
          
      </div>
    </div>
      
    </div>
  )
}

export default Profile
