// import React, { useEffect, useState } from 'react';
// import AudioBufferToWav from 'audiobuffer-to-wav'; // Import the audiobuffer-to-wav package

// const VoiceActivityDetection = ({ onAudioRecorded, onRecordingStatusChange }) => {
//   const [recording, setRecording] = useState(false);

//   useEffect(() => {
//     const loadScript = (src) => {
//       return new Promise((resolve, reject) => {
//         const script = document.createElement('script');
//         script.src = src;
//         script.onload = resolve;
//         script.onerror = reject;
//         document.body.appendChild(script);
//       });
//     };

//     const initializeVAD = async () => {
//       try {
//         await loadScript('https://cdn.jsdelivr.net/npm/onnxruntime-web@1.14.0/dist/ort.js');
//         await loadScript('https://cdn.jsdelivr.net/npm/@ricky0123/vad-web@0.0.7/dist/bundle.min.js');

//         if (window.ort && window.vad) {
//           const myvad = await window.vad.MicVAD.new({
//             onnx: window.ort,
//             workletURL: '/vad.worklet.bundle.min.js',
//             modelsURL: '/silero_vad.onnx',
//             onSpeechStart: () => {
//               setRecording(true);
//               onRecordingStatusChange(true);
//             },
//             onSpeechEnd: async (audio) => {
//               setRecording(false);
//               onRecordingStatusChange(false);
              
//               // Create AudioBuffer and convert to WAV
//               const audioBuffer = await createAudioBuffer(audio);
//               await convertAndSendAudio(audioBuffer);
//             },
//           });
//           myvad.start();
//         } else {
//           console.error("ORT or VAD is not loaded properly");
//         }
//       } catch (error) {
//         console.error("Error initializing VAD:", error);
//       }
//     };

//     initializeVAD();

//     return () => {
//       document.querySelectorAll('script[src*="onnxruntime-web"], script[src*="vad-web"]').forEach(script => {
//         document.body.removeChild(script);
//       });
//     };
//   }, [onAudioRecorded, onRecordingStatusChange]);

//   const createAudioBuffer = (pcmData) => {
//     return new Promise((resolve, reject) => {
//       try {
//         const sampleRate = 44100; // Adjust this to match your actual sample rate
//         const channelData = [pcmData]; // Assuming mono channel data; adjust if stereo

//         const audioContext = new (window.AudioContext || window.webkitAudioContext)();
//         const buffer = audioContext.createBuffer(1, pcmData.length, sampleRate); // Create a mono buffer
//         buffer.copyToChannel(new Float32Array(channelData[0]), 0); // Copy PCM data to buffer
        
//         resolve(buffer);
//       } catch (error) {
//         reject(error);
//       }
//     });
//   };

//   const convertAndSendAudio = async (audioBuffer) => {
//     try {
//       console.log('AudioBuffer object:', audioBuffer);
//       // Convert the audio buffer to WAV format
//       const wavBlob = await convertAudioToWav(audioBuffer);
//       const audioUrl = URL.createObjectURL(wavBlob); // Create a URL for the WAV blob
//       onAudioRecorded(audioUrl); // Call the provided function with the audio URL

//       // Send the audio file to the backend API
//       await sendAudioToAPI(wavBlob);
//     } catch (error) {
//       console.error('Error converting or sending audio:', error);
//     }
//   };

//   const convertAudioToWav = async (audioBuffer) => {
//     return new Promise((resolve, reject) => {
//       try {
//         const wavArray = AudioBufferToWav(audioBuffer);
//         const wavBlob = new Blob([wavArray], { type: 'audio/wav' });
//         resolve(wavBlob);
//       } catch (error) {
//         reject(error);
//       }
//     });
//   };

//   const isOnline = () => {
//     return navigator.onLine; // Check if the browser is online
//   };

//   const sendAudioToAPI = async (audioBlob) => {
//     const formData = new FormData();
//     formData.append('file', audioBlob, 'audio.wav'); // Append the audio file

//     if (!isOnline()) {
//       console.error('You are currently offline. Please check your internet connection.');
//       return; // Exit if offline
//     }

//     try {
//       const response = await fetch('https://xn--11by0j.com/api/v1/lali_speech_to_text/', {
//         method: 'POST',
//         body: formData,
//       });

//       if (!response.ok) {
//         const errorText = await response.text(); // Get error details
//         throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
//       }

//       const data = await response.json();
//       console.log('Response from API:', data);
//     } catch (error) {
//       console.error('Error sending audio to API:', error.message);
//     }
//   };

//   return null;
// };

// export default VoiceActivityDetection;

















// // able to load the sound from VAD component


// // import React, { useEffect } from 'react';

// // const VoiceActivityDetection = () => {
// //   useEffect(() => {
// //     const loadScript = (src) => {
// //       return new Promise((resolve, reject) => {
// //         const script = document.createElement('script');
// //         script.src = src;
// //         script.onload = resolve;
// //         script.onerror = reject;
// //         document.body.appendChild(script);
// //       });
// //     };

// //     const initializeVAD = async () => {
// //       try {
// //         await loadScript('https://cdn.jsdelivr.net/npm/onnxruntime-web@1.14.0/dist/ort.js');
// //         await loadScript('https://cdn.jsdelivr.net/npm/@ricky0123/vad-web@0.0.7/dist/bundle.min.js');

// //         if (window.ort && window.vad) {
// //           const myvad = await window.vad.MicVAD.new({
// //             onnx: window.ort,
// //             workletURL: '/vad.worklet.bundle.min.js', // Ensure this file is in the public directory
// //             modelsURL: '/silero_vad.onnx', // Ensure this file is in the public directory
// //             onSpeechStart: () => {
// //               console.log("Speech start detected");
// //             },
// //             onSpeechEnd: (audio) => {
// //               try {
// //                 // Create an AudioContext
// //                 const audioContext = new (window.AudioContext || window.webkitAudioContext)();
                
// //                 // Convert Float32Array to AudioBuffer
// //                 const buffer = audioContext.createBuffer(1, audio.length, 16000); // Assuming 16000 sample rate
// //                 buffer.copyToChannel(audio, 0);

// //                 // Here you can process the AudioBuffer, for example, store or play it
// //                 console.log("AudioBuffer created:", buffer);

// //                 // Example: play the audio buffer
// //                 // const source = audioContext.createBufferSource();
// //                 // source.buffer = buffer;
// //                 // source.connect(audioContext.destination);
// //                 // source.start();

// //                 // Example: Save AudioBuffer as WAV file (this requires additional logic)
// //                 // You might want to use a library like 'audiobuffer-to-wav' for this
// //                 // import audioBufferToWav from 'audiobuffer-to-wav';
// //                 // const wav = audioBufferToWav(buffer);
// //                 // Save wav or send to server...

// //               } catch (error) {
// //                 console.error("Error processing audio:", error);
// //               }
// //             },
// //           });
// //           myvad.start();
// //         } else {
// //           console.error("ORT or VAD is not loaded properly");
// //         }
// //       } catch (error) {
// //         console.error("Error initializing VAD:", error);
// //       }
// //     };

// //     initializeVAD();

// //     // Clean up the scripts when the component unmounts
// //     return () => {
// //       document.querySelectorAll('script[src*="onnxruntime-web"], script[src*="vad-web"]').forEach(script => {
// //         document.body.removeChild(script);
// //       });
// //     };
// //   }, []);

// //   return <div>Voice Activity Detection Initialized</div>;
// // };

// // export default VoiceActivityDetection;

















// // import React, { useEffect } from 'react';
// // import audioBufferToWav from 'audiobuffer-to-wav';

// // const VoiceActivityDetection = () => {
// //   useEffect(() => {
// //     const loadScript = (src) => {
// //       return new Promise((resolve, reject) => {
// //         const script = document.createElement('script');
// //         script.src = src;
// //         script.onload = resolve;
// //         script.onerror = reject;
// //         document.body.appendChild(script);
// //       });
// //     };

// //     const initializeVAD = async () => {
// //       try {
// //         await loadScript('https://cdn.jsdelivr.net/npm/onnxruntime-web@1.14.0/dist/ort.js');
// //         await loadScript('https://cdn.jsdelivr.net/npm/@ricky0123/vad-web@0.0.7/dist/bundle.min.js');

// //         if (window.ort && window.vad) {
// //           const myvad = await window.vad.MicVAD.new({
// //             onnx: window.ort,
// //             workletURL: '/vad.worklet.bundle.min.js', // Ensure this file is in the public directory
// //             modelsURL: '/silero_vad.onnx', // Ensure this file is in the public directory
// //             onSpeechStart: () => {
// //               console.log("Speech start detected");
// //             },
// //             onSpeechEnd: async (audio) => {
// //               console.log("Speech end detected");

// //               try {
// //                 // Create an AudioContext
// //                 const audioContext = new (window.AudioContext || window.webkitAudioContext)();

// //                 // Convert Float32Array to AudioBuffer
// //                 const buffer = audioContext.createBuffer(1, audio.length, 16000); // Assuming 16000 sample rate
// //                 buffer.copyToChannel(audio, 0);

// //                 // Convert AudioBuffer to WAV
// //                 const wav = audioBufferToWav(buffer);
// //                 const blob = new Blob([wav], { type: 'audio/wav' });
// //                 const url = URL.createObjectURL(blob);

// //                 console.log("WAV file created:", url);

// //                 // Create and simulate a click on a download link
// //                 // const link = document.createElement('a');
// //                 // link.href = url;
// //                 // link.download = 'recorded_audio.wav';
// //                 // link.style.display = 'none'; // Hide the link
// //                 // document.body.appendChild(link);
// //                 // link.click();
// //                 // document.body.removeChild(link);

// //                 // // Clean up the URL object
// //                 // URL.revokeObjectURL(url);

// //               } catch (error) {
// //                 console.error("Error processing audio:", error);
// //               }
// //             },
// //           });
// //           myvad.start();
// //         } else {
// //           console.error("ORT or VAD is not loaded properly");
// //         }
// //       } catch (error) {
// //         console.error("Error initializing VAD:", error);
// //       }
// //     };

// //     initializeVAD();

// //     // Clean up the scripts when the component unmounts
// //     return () => {
// //       document.querySelectorAll('script[src*="onnxruntime-web"], script[src*="vad-web"]').forEach(script => {
// //         document.body.removeChild(script);
// //       });
// //     };
// //   }, []);

// //   return <div>Voice Activity Detection Initialized</div>;
// // };

// // export default VoiceActivityDetection;











// // still working correctly










// // import React, { useEffect, useState } from 'react';
// // import audioBufferToWav from 'audiobuffer-to-wav';

// // const VoiceActivityDetection = () => {
// //   const [isRecording, setIsRecording] = useState(true);
// //   const [accumulatedAudio, setAccumulatedAudio] = useState([]);

// //   useEffect(() => {
// //     const loadScript = (src) => {
// //       return new Promise((resolve, reject) => {
// //         const script = document.createElement('script');
// //         script.src = src;
// //         script.onload = resolve;
// //         script.onerror = reject;
// //         document.body.appendChild(script);
// //       });
// //     };

// //     const initializeVAD = async () => {
// //       try {
// //         await loadScript('https://cdn.jsdelivr.net/npm/onnxruntime-web@1.14.0/dist/ort.js');
// //         await loadScript('https://cdn.jsdelivr.net/npm/@ricky0123/vad-web@0.0.7/dist/bundle.min.js');

// //         if (window.ort && window.vad) {
// //           const myvad = await window.vad.MicVAD.new({
// //             onnx: window.ort,
// //             workletURL: '/vad.worklet.bundle.min.js', // Ensure this file is in the public directory
// //             modelsURL: '/silero_vad.onnx', // Ensure this file is in the public directory
// //             onSpeechStart: () => {
// //               console.log("Speech start detected");
// //             },
// //             onSpeechEnd: (audio) => {
// //               console.log("Speech end detected");

// //               // Accumulate the audio data
// //               setAccumulatedAudio(prevAudio => [...prevAudio, audio]);
// //             },
// //           });

// //           // Start VAD
// //           myvad.start();

// //           // Stop the VAD and process the accumulated audio after some time
// //           // Use your own logic or set a timeout
// //           setTimeout(() => {
// //             setIsRecording(false);
// //             // Stop VAD if it has a method to do so or handle cleanup

// //             // Process accumulated audio data
// //             processAudio(accumulatedAudio);
// //           }, 30000); // Adjust timeout as needed
// //         } else {
// //           console.error("ORT or VAD is not loaded properly");
// //         }
// //       } catch (error) {
// //         console.error("Error initializing VAD:", error);
// //       }
// //     };

// //     const processAudio = async (audioChunks) => {
// //       try {
// //         // Flatten the accumulated audio data
// //         const totalLength = audioChunks.reduce((acc, chunk) => acc + chunk.length, 0);
// //         const audioData = new Float32Array(totalLength);
// //         let offset = 0;
// //         for (const chunk of audioChunks) {
// //           audioData.set(chunk, offset);
// //           offset += chunk.length;
// //         }

// //         // Create an AudioContext
// //         const audioContext = new (window.AudioContext || window.webkitAudioContext)();

// //         // Convert Float32Array to AudioBuffer
// //         const buffer = audioContext.createBuffer(1, audioData.length, 16000); // Assuming 16000 sample rate
// //         buffer.copyToChannel(audioData, 0);

// //         // Convert AudioBuffer to WAV
// //         const wav = audioBufferToWav(buffer);
// //         const blob = new Blob([wav], { type: 'audio/wav' });
// //         const url = URL.createObjectURL(blob);

// //         console.log("WAV file created:", url);

// //         // Create and simulate a click on a download link
// //         const link = document.createElement('a');
// //         link.href = url;
// //         link.download = 'recorded_audio.wav';
// //         link.style.display = 'none'; // Hide the link
// //         document.body.appendChild(link);
// //         link.click();
// //         document.body.removeChild(link);

// //         // Clean up the URL object
// //         URL.revokeObjectURL(url);

// //       } catch (error) {
// //         console.error("Error processing audio:", error);
// //       }
// //     };

// //     initializeVAD();

// //     // Clean up the scripts when the component unmounts
// //     return () => {
// //       document.querySelectorAll('script[src*="onnxruntime-web"], script[src*="vad-web"]').forEach(script => {
// //         document.body.removeChild(script);
// //       });
// //     };
// //   }, [accumulatedAudio]);

// //   return <div>Voice Activity Detection Initialized</div>;
// // };

// // export default VoiceActivityDetection;


import React, { useEffect, useState } from 'react';
import audioBufferToWav from 'audiobuffer-to-wav';

const VoiceActivityDetection = () => {
  const [isRecording, setIsRecording] = useState(true);
  const [accumulatedAudio, setAccumulatedAudio] = useState([]);
  const [apiResponse, setApiResponse] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const loadScript = (src) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.onload = resolve;
        script.onerror = reject;
        document.body.appendChild(script);
      });
    };

    const initializeVAD = async () => {
      try {
        await loadScript('https://cdn.jsdelivr.net/npm/onnxruntime-web@1.14.0/dist/ort.js');
        await loadScript('https://cdn.jsdelivr.net/npm/@ricky0123/vad-web@0.0.7/dist/bundle.min.js');

        if (window.ort && window.vad) {
          const myvad = await window.vad.MicVAD.new({
            onnx: window.ort,
            workletURL: '/vad.worklet.bundle.min.js', // Ensure this file is in the public directory
            modelsURL: '/silero_vad.onnx', // Ensure this file is in the public directory
            onSpeechStart: () => {
              console.log("Speech start detected");
            },
            onSpeechEnd: (audio) => {
              console.log("Speech end detected");

              if (audio && audio.length > 0) {
                // Accumulate the audio data
                setAccumulatedAudio(prevAudio => [...prevAudio, audio]);
              } else {
                console.warn("Received empty audio chunk");
              }
            },
          });

          // Start VAD
          myvad.start();

          // Use an interval to manage recording time
          const recordingDuration = 30000; // Duration in milliseconds
          const intervalId = setInterval(() => {
            setIsRecording(false);
            clearInterval(intervalId);

            // Process accumulated audio data
            if (accumulatedAudio.length > 0) {
              processAudio(accumulatedAudio);
            } else {
              console.warn("No audio data to process");
              setError("No audio data to process");
            }

          }, recordingDuration);

        } else {
          console.error("ORT or VAD is not loaded properly");
        }
      } catch (error) {
        console.error("Error initializing VAD:", error);
        setError("Error initializing VAD: " + error.message);
      }
    };

    const processAudio = async (audioChunks) => {
      try {
        if (audioChunks.length === 0) {
          throw new Error("No audio chunks to process");
        }

        // Flatten the accumulated audio data
        const totalLength = audioChunks.reduce((acc, chunk) => acc + chunk.length, 0);
        if (totalLength === 0) {
          throw new Error("Total audio length is 0");
        }
        
        const audioData = new Float32Array(totalLength);
        let offset = 0;
        for (const chunk of audioChunks) {
          audioData.set(chunk, offset);
          offset += chunk.length;
        }

        // Create an AudioContext
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();

        // Convert Float32Array to AudioBuffer
        const buffer = audioContext.createBuffer(1, audioData.length, 16000); // Assuming 16000 sample rate
        buffer.copyToChannel(audioData, 0);

        // Convert AudioBuffer to WAV
        const wav = audioBufferToWav(buffer);
        const blob = new Blob([wav], { type: 'audio/wav' });

        // Send WAV file to API
        const formData = new FormData();
        formData.append('file', blob, 'recorded_audio.wav');

        const response = await fetch('https://कोड.com/api/v1/lali_speech_to_text/', {
          method: 'POST',
          body: formData,
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        console.log("API response:", data);
        setApiResponse(data.result || ''); // Assuming the API returns a field named 'result'

      } catch (error) {
        console.error("Error processing audio:", error);
        setError("Error processing audio: " + error.message);
      }
    };

    initializeVAD();

    // Clean up the scripts when the component unmounts
    return () => {
      document.querySelectorAll('script[src*="onnxruntime-web"], script[src*="vad-web"]').forEach(script => {
        document.body.removeChild(script);
      });
    };
  }, [accumulatedAudio]);

  return (
    <div>
      <div>Voice Activity Detection Initialized</div>
      {apiResponse && <div>API Response: {apiResponse}</div>}
      {error && <div>Error: {error}</div>}
    </div>
  );
};

export default VoiceActivityDetection;

