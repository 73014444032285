import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import sound from "./assets/images/volume.png";
import crow from "./assets/images/crow.png";
import { Link } from "react-router-dom";

const CourseModal = ({ setOpenModal, title }) => {
  const navigate = useNavigate();

  const handleStartCoding = () => {
    setOpenModal(false);
    navigate("/gamepage");
  };

  const handleReadAloud = () => {
    const texts = [
      "A thirsty crow flew everywhere in search of water. Suddenly, his gaze landed on a pitcher on the ground with little water in it. The water level was too low, and the pitcher had a narrow neck, because of which the crow could not drink the water.",
      "Help the crow to drink water by coding.",
    ];

    // Stop any ongoing speech
    window.speechSynthesis.cancel();

    texts.forEach((text, index) => {
      const utterance = new SpeechSynthesisUtterance(text);
      // Adjust rate to ensure prompt start
      utterance.rate = 1; // Adjust the rate if necessary

      // Speak each utterance with a slight delay
      setTimeout(() => {
        window.speechSynthesis.speak(utterance);
      }, index * 0); // Adjust the delay if necessary
    });
  };

  useEffect(() => {
    const stopSpeechOnUnload = () => {
      window.speechSynthesis.cancel();
    };

    // Cancel speech synthesis on component unmount and before the page unloads
    window.addEventListener("beforeunload", stopSpeechOnUnload);

    return () => {
      window.removeEventListener("beforeunload", stopSpeechOnUnload);
      window.speechSynthesis.cancel();
    };
  }, []);

  return (
    <div className="fixed inset-0 bg-neutral-700 bg-opacity-60 z-50 flex flex-col justify-center items-center">
      <div className="bg-white w-[50rem] h-[30rem] rounded-lg flex flex-col">
        <div className="h-20 w-full rounded-tl-lg rounded-tr-lg wood-bg flex justify-center items-center">
          <h2 className="text-[1.7rem] modal-text font-bold">
            Learn Coding With Games : #0
          </h2>
        </div>
        <div className="flex px-4 justify-center items-center gap-7 mt-">
          <img src={crow} alt="crow" className="w-96 mt-6" />
          <div>
            <button
              onClick={handleReadAloud}
              className="h-12 w-12 rounded-full p-1 bg-sky-800 absolute -mt-8 right-[36rem] -rotate-[20deg]"
            >
              <img src={sound} alt="volume" className="w-12" />
            </button>
            <h2 className="text-xl text-justify mt-4 relative">
              "A thirsty crow flew everywhere in search of water. Suddenly, his
              gaze landed on a pitcher on the ground with little water in it.
              The water level was too low, and the pitcher had a narrow neck,
              because of which the crow could not drink the water".
            </h2>
          </div>
        </div>
        <div className="flex justify-center items-center gap-6 mt-10">
          <span className="uppercase -mt-1 text-center text-2xl font-medium tracking-wider">
            {" "}
            Help the crow to drink water by coding
          </span>
          <button className="w-20 h-20 bg-green-600 rounded-full -mt-4 flex justify-center items-center hover:scale-105 transition">
            <Link
              to="/gamepage"
              className="uppercase text-2xl text-white play mt-2 tracking-widest font-medium text-decoration-none"
            >
              Ok
            </Link>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CourseModal;